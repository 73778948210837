<div >
  <h4 class="gen-title">{{"userdetails" | langVal}}</h4>

  @for(rowFields of rows;track rowFields){
    <div class="row">
      @for(field of rowFields;track field){
        <div [className]="'col-'+(field?.xs || clsDefaults.xs)+' col-md-'+(field?.md || clsDefaults.md)">
          <label>{{(field?.lang || field.name) | langVal}}</label>
          <app-gen-input [tabindex]="field?.tabIndex" [className]="(field.name=='email'?' email':'')"  [actualObj]="configcliniq" [errors]="errors" [fieldName]="field.name" [title_ph_lang]="(field?.lang || field.name)" (changeEvent)="validateAndSave()"></app-gen-input>
        </div>
      }
    </div>
  }

</div>
