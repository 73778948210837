import { Component, OnInit } from '@angular/core';
import { CcParamsGen } from '@app/cc-params-gen/cc-params-gen';

@Component({
  selector: 'app-cc-params-security',
  templateUrl: './cc-params-security.component.html',
  styleUrl: './cc-params-security.component.css'
})

export class CcParamsSecurityComponent extends CcParamsGen{
  saveObjName: string = "security";	//which part to save while updating
}
