<div class="site-menu">
  <div class="cont" style="justify-content: end;">
    <div title="My-cliniq">
      <img [src]="environment.serverUrl+'/images/newdesign/main-logo.png'" class="LOGO">
    </div>
  </div>
</div>
<app-spin-or-content [isSpin]="!showCalendar">
  <app-calendar *ngIf="showCalendar" isGuest />
</app-spin-or-content>
