import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Contact, Contactway } from '@app/definitions/types';
import { PersonUpdateCommons } from '@app/person-update-commons/person-update-commons.component';

@Component({
	selector: 'app-update-contact',
	templateUrl: '../person-update-commons/person-update-commons.component.html'
})
export class UpdateContactComponent extends PersonUpdateCommons{
	tableName:string="contacts";

	ngOnInit() {
    if(!this.contact){
      this.contact=new Contact;
      this.addContactway(true);
    }
    this.loadValidationFields();
	}

}
