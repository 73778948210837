import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {
	constructor() { }

	transform(time: any) {
		const split = time.split(":");
		return split[0] + ":" + split[1];
	}

}
