import { Component, OnInit } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { StoreService } from '@app/services/store.service';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrl: './activity.component.css'
})
export class ActivityComponent implements OnInit {
  constructor(public apiService: ApiService,public store:StoreService) { }
  userNums:any[]=[];
  period:string="daily";
  isClosed:boolean=true;

  async ngOnInit(){
    this.userNums=await this.apiService.post("get_user_type_to_num");

  }
}
