<div class="container-fluid"  style="background-color:inherit;"  >
  <div style="line-height: 1.6;">
    <div  class="LOGO" title="My-cliniq" style="text-align:left">
      <img src="https://www.my-cliniq.com/wp-content/uploads/2020/07/my-cliniq-logo.png" alt="My-cliniq" >
    </div>
    <div class="center-small-div">
      <div>
        <h2 [innerHTML]="(success?'payment_successful':'payment_failed') | langVal"></h2>

        <br>
        <h3 [innerHTML]="(success?'payment_successful1':'payment_failed1') | langVal"></h3>
        <br>
        <h3 [innerHTML]="(success?'payment_successful2':'') | langVal"></h3>
      </div>

    </div>
    <div class="footer"></div>
  </div>
</div>

