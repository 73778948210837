import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class PermsService {
	perms: any = {};
	owner_has_users: boolean = false;


	constructor() { }

	load(perms: string[], owner_has_users: boolean = false) {
		this.owner_has_users = owner_has_users;
		perms.forEach(perm => {
			this.perms[perm] = true;
		})
	}

}

