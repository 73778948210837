@if(isStatic){
  <div class="static">
    {{displayStr}}
  </div>
}
@else{
  <div class="date-picker" (click)="clickTop($event)"   [class.noInput]="noInput"  >

    @if(isButtonView && !noInput){
      <button #btnEl class="cal-btn cal-icon" (click)="openPopup($event)" >
        <app-icon icon="date" />
      </button>
    }
    <div [class.input-group]="addonObject" [style.display]="(isButtonView || noInput)?'none':''">
      <!-- @if(addonObject?.before){
        <span class="input-group-addon" [preIcon]="addonObject.before.icon">{{addonObject?.before?.text}}</span>
      }  -->

      <input #inpEl class="form-control" [ngClass]="className"  [disabled]="disabled" (keydown)="onKeyDown($event)"  inputmode="none"
        [tabindex]="tabindex" [(ngModel)]="displayStr" (click)="openPopup($event)" (change)="onChange()"
        [placeholder]="placeholder || ( (title_ph_lang || fieldName) | langVal)" [title]="(title_ph_lang || fieldName) | langVal" />

      <!-- @if(addonObject?.after){
        <span class="input-group-addon" [preIcon]="addonObject.after.icon">{{addonObject?.after?.text}}</span>
      } -->

    </div>

    @if(isPopupOpen || noInput){
      <div class="popup" [style.transform]="'translate(0px,'+popupProps.bottom+'px)'"
        [style.width]="popupWidth+'px'" [style.height]="popupHeight+'px'"
        [style.right]="(popupProps.right?popupProps.right+'px':'')"
        [style.left]="(popupProps.left?popupProps.left+'px':'')">
        <div class="cal-container">
          <div class="cal-row header">
            <div (click)="move(false)" class="cell" icon="arrow_left"></div>
            <div (click)="headerMidClick()">{{headerDisp}}</div>
            <div (click)="move(true)" class="cell" icon="arrow_right"></div>
          </div>
          @if(displayLevel==displayTypes.MONTH){
            <div class="cal-row main" style="font-weight: bold;">
              @for(day of weekDays;track day){
                <div>{{day | langVal}}</div>
              }
            </div>
          }
          <div class="cal-row main " [class.qrt]="displayLevel!=displayTypes.MONTH">
            @for(cell of dispArr;track cell){
              <div [class.cell]="cell.val!==undefined" (click)="cellClick(cell)"
                [class.act]="cell.isAct" [class.today]="cell.isToday" [class.disabled]="cell.disabled">{{cell.val}}</div>
            }
          </div>
        </div>
      </div>
    }
  </div>
  @if(errors[fieldName]){
    <p class="error">{{errors[fieldName]}}</p>
  }
}
