import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '@app/services/api.service';
import { PermsService } from '@app/services/perms.service';
import { StoreService } from '@app/services/store.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-guest-calendar2',
  templateUrl: './guest-calendar2.component.html',
  styleUrl: './guest-calendar2.component.css'
})
export class GuestCalendar2Component {
  showCalendar:boolean=false;
  fdsewrfsdfds:string="";
  hgfe5ty6hjtgft454tr:string="";
  environment = environment;
  
  constructor(protected store: StoreService,public permsService: PermsService,private apiService: ApiService,protected route: ActivatedRoute){
    this.route.queryParams.subscribe(params=>{
      Object.keys(params).forEach(key=>{
        this[key]=params[key];
      });
      if(this.fdsewrfsdfds && this.hgfe5ty6hjtgft454tr){
        this.load();
      }
    });
  }

  async load(){
    const res:any=await this.apiService.post("guest_get_cal_data",this,"fdsewrfsdfds,hgfe5ty6hjtgft454tr");
    if(res){
      this.store.ccForGuestCal=res.cc;
      this.store.data.siteData=res.siteData;
      this.permsService.load(res.perms,res.owner_has_users);
      this.store.getAllFusLoad(res.fuses,res?.meeting_requests);
      this.showCalendar=true;
    }
    // this.store.ccForGuestCal={
    //   no_saturday:"yes",
    //   calendar_start_time:"",
    //   calendar_end_time:"",
    //   only_therapist_id:"",
    //   break_length:"",
    //   locations_only:"",
    //   user_id:"",
    //   show_allocation:"",
    // };
    // this.store.data.siteData={
    //   holidays:[],
    //   patients:[],
    //   contacts:[],
    //   locations:[],
    //   sub_users:[],
    //   sub_user_groups:[],
    //   sub_user_group_members:[],
    //   time_allocations:[],
    //   meetingtypes:[],
    //   object_to_tags:[],
    // }

    // this.permsService.owner_has_users=true;
    // this.permsService.perms={};
  }
}
