import { Component, OnInit } from '@angular/core';
import { ModalService } from "@app/services/modal.service";
import { LangService } from "@app/services/lang.service";
import { StoreService } from '@app/services/store.service';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrl: './footer.component.css'
})
export class FooterComponent {	//outputs the footer for the site

	constructor(public lang: LangService, public modalService: ModalService,public store:StoreService) { }

	openHelp() {	//open the error modal (in help mode, not error mode)
		this.modalService.openMulti("error");
	}

}
