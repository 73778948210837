<div>
  <h4 class="gen-title">{{"self_coordination" | langVal}}</h4>

  <hr>
  @if(!permsService?.owner_has_users){
    <div class="row">
      <div class="col-4  col-md-2">
        <button class="btn btn-primary" style="margin-top: 25px;" titleLang="time_allocation" tabindex="-1"
                routerLink="/time-allocation/">{{"time_allocation" |
          langVal}}</button>
      </div>
      <div class="col-4 col-md-2">
        <label>{{"meeting_request_with_calendar" | langVal}}</label>
        <app-gen-button-group [actualObj]="configcliniq" fieldName="meeting_request_with_calendar"
                              optionsListName="yes_no_opts" (changeEvent)="validateAndSave()" />
      </div>
      <div class="col-4 col-md-2 ">
        <label>{{"guest_sched_hours_to_add" | langVal}}</label>
        <app-gen-input [tabindex]="64" [actualObj]="configcliniq" [errors]="errors" fieldName="guest_sched_hours_to_add" (keyupEvent)="validateAndSave()" />
      </div>
    </div>
  }
  @if(apiLink){
    <div class="row">
      <div class="col-12">  <!--@if(!permsService?.owner_has_users){}-->
        <button class="btn btn-primary" (click)="copyApiLink()" titleLang='copy_link'>
          {{"copy_external_link" | langVal}}</button>
      </div>
    </div>

<!--  <div class="row">-->
<!--    <div class="col-12">  &lt;!&ndash;@if(!permsService?.owner_has_users){}&ndash;&gt;-->
<!--      <button class="btn btn-primary" (click)="copyApiGuestLink()" titleLang='copy_guest_payment_link'>-->
<!--        {{"copy_guest_payment_link" | langVal}}</button>-->
<!--    </div>-->
<!--  </div>-->
  }
  <hr class="separation-line">
  <app-cc-params-user-details-website></app-cc-params-user-details-website>
</div>
