import { EventsService } from "@app/services/events.service";
import { PermsService } from "./../services/perms.service";
import { GenAutocompleteComponent } from "@app/gen-inputs/gen-autocomplete/gen-autocomplete.component";
import { ApiService } from "@app/services/api.service";
import { environment } from "@env/environment";
import { LangService } from "@app/services/lang.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ConfigDefinitions } from "@app/definitions/types";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalService } from "@app/services/modal.service";
import { StoreService } from "@app/services/store.service";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrl: "./menu.component.css"
})
export class MenuComponent implements OnInit {
  environment = environment;
  cliniqData: any = null;
  userMenuIsOpen: boolean = false;
  notiTotal: number = null;
  @ViewChild("searchFuzzy") searchFuzzy: GenAutocompleteComponent;
  isOwner: boolean = false;
  showSearchByPhone: string = 'no';
  old_debts_report: boolean = false;
  enable_israel_invoice: boolean = false;
  curMenuItem:string="";
  menu: any[] = [
    { lang: "calendar", icon: "main_calendar", link: "calendar" },
    {
      lang: "financial",
      icon: "financial",
      id: "financial",
      children: [
        {
          lang: "income",
          icon: "report",
          report_name: "invoices_report",
          isReportsModal: true,
        },
        {
          lang: "follow_ups_report",
          icon: "report",
          report_name: "followups_report",
          isReportsModal: true,
        },
        {
          lang: ( this.permsService.owner_has_users ? "rent_report" : "contacts_meetings" ),
          icon: "report",
          report_name: "rent_report",
          id: "rent_report",
          isReportsModal: true,
          perms: ["administrative"],
        },
        {
          lang: "locations_report",
          icon: "report",
          report_name: "locations_report",
          id: "locations_report",
          isReportsModal: true,
          perms: ["administrative"],
        },
        {
          lang: "invoices_sub_user_report",
          id: "invoices_sub_user_report",
          icon: "report",
          report_name: "invoices_sub_user_report",
          isReportsModal: true,
        },
        {
          lang: "followups_sub_user_report",
          id: "followups_sub_user_report",
          icon: "report",
          report_name: "followups_sub_user_report",
          isReportsModal: true,
          //perms: ["administrative"],
        },
        {
          lang: "followups_sub_user_rent_report",
          id: "followups_sub_user_rent_report",
          icon: "report",
          report_name: "followups_sub_user_rent_report",
          isReportsModal: true,
        },
        // {
        //   lang: "patients_sub_user_report",
        //   id: "patients_sub_user_report",
        //   icon: "report",
        //   //modal: "sub-user-patients",
        //   report_name: "patients_sub_user_report",
        //   isReportsModal: true,
        // },
        {
          lang: "patients_sub_user_report",
          id: "patients_sub_user_report",
          icon: "report",
          modal: "sub-user-patients",
        },
        {
          lang: "sub_user_payments",
          id: "sub_user_payments",
          icon: "report",
          modal: "sub-user-payments",
          perms: ["finances_income", "administrative"],
        },
        {
          lang: "text_report",
          icon: "report",
          report_name: "followups_text_report",
          isReportsModal: true,
          perms: ["clinical"],
        },
        {
          lang: "debt",
          icon: "report",
          id: "debts_report",
          report_name: "debts_report",
          isReportsModal: true,
        },
        {
          lang: "debt",
          icon: "report",
          id: "debts_report2",
          report_name: "debts_report2",
          isReportsModal: true,
        },
        // {
        //   lang: "real_debt",
        //   icon: "report",
        //   report_name: "real_debt_report",
        //   isReportsModal: true,
        // },
        {
          lang: "payors",
          icon: "payor",
          link: "table/payors",
          perms: ["finances_income"],
        },
        {
          lang: "expenses",
          icon: "expense",
          link: "table/expenses",
          perms: ["finances_expenses"],
        },
        {
          lang: "expenses_report",
          icon: "report",
          report_name: "expenses_report",
          isReportsModal: true,
          perms: ["finances_expenses"],
        },
        {
          lang: "profitloss_report",
          icon: "report",
          modal: "profitloss",
          perms: ["finances_income"],
        },
        {
          lang: "proformas_report",
          icon: "report",
          report_name: "proformas_report",
          isReportsModal: true,
          perms: ["finances_income", "administrative"],
        },
        {
          lang: "justinvoices_report",
          icon: "report",
          report_name: "justinvoices_report",
          isReportsModal: true,
          perms: ["finances_income", "administrative"],
        },
        {
          lang: "taxes_calc",
          icon: "tax",
          modal: "taxes-report",
          perms: ["finances_income"],
        },
        {
          lang: "irs_journal_report",
          icon: "report",
          report_name: "followups_all_report",
          isReportsModal: true,
        },

        // { lang: 'irs',
        //   icon: 'report',
        //   modal: "bkmvdata-report",
        //   perms: ["administrative"] },
        // { lang: 'irs', icon: 'irs', report_name: 'irs', isReportsModal: true },
        // {
        //   lang: "export",
        //   icon: "export",
        //   modal: "data-export",
        //   perms: ["administrative"],
        // },


        { lang: "graphs", icon: "graph", modal: "graphs-data",perms: ["finances_income", "administrative"], },

        { lang: "supervisor_notes", icon: "report",id: "supervisor_notes", modal: "followups-for-supervisor-list" ,perms: ["authorized_signer","clinical_admin"]}
      ],
    },
    {
      lang: "configuration",
      icon: "setting",
      id: "configuration",
      children: [


        {
          lang: "configcliniqs",
          icon: "setting2",
          link: "config-cliniq?showMenu=1",
          perms: ["administrative"],
        },
        { lang: "report_problem", icon: "bug", modal: "error" },
        {
          lang: "email_tracking",
          icon: "email",
          link: "table-pagi/notifications",
        },
        // for therapist it's own{
        // {
        //   lang: "sms_tracking",
        //   icon: "sms",
        //   link: "table/smslogs",
        // }, // for therapist it's own
        {
          lang: "sign",
          icon: "sign_document",
          id: "sign-documents",
          modal: "sign-documents",
          perms: ["authorized_signer"],
        },
        { lang: "patients", icon: "patient", link: "table/patients" },
        { lang: "inquirers", icon: "patient", link: "table/inquirers" },
        { lang: "groups", icon: "group", link: "table/groups" }, // add is_group as filter
        {
          lang: "sub_users_dashboard",
          icon: "group",
          id: "sub_users",
          link: "table/sub_users",
          perms: ["administrative"],
        },
        {
          lang: "contacts",
          icon: "contact",
          link: "table/contacts",
          perms: ["administrative"],
        },
        { lang: "meeting_times", icon: "times", modal: "meeting-times" },
        {
          lang: "home-admin",
          icon: "setting2",
          link: "home-super-admin",
          id: "home-super-admin",
        },
        // { lang: "add",icon: "setting2",modal:"types-create"},

      ],
    },
  ];

  constructor(
    public modalService: ModalService,
    public lang: LangService,
    private router: Router,
    public route:ActivatedRoute,
    private apiService: ApiService,
    public store: StoreService,
    public permsService: PermsService,
    private eventsService: EventsService
  ) {}

  async ngOnInit() {

    this.cliniqData = this.store.getCliniqDataOrExit();
    this.isOwner = this.cliniqData.has_users;
    this.showSearchByPhone = this.cliniqData.show_search_by_phone;
    const isSuperAdmin = this.cliniqData?.role == 2;
    this.enable_israel_invoice = this.cliniqData.enable_israel_invoice=="yes"?true:false;
    this.old_debts_report = this.cliniqData.old_debts_report=="yes"?true:false;
    if(this.cliniqData.invoice_per_followup=="no"){
      this.old_debts_report=true;
    }

    this.removeForPerms(this.menu);

    const has2HP =
      this.permsService.owner_has_users &&
      this.permsService.perms?.sees_admin_homepage &&
      this.permsService.perms?.clinical;

    if (has2HP) {
      if (!this.menu.find((it) => it?.id == "home")) {
        this.menu.unshift({
          lang: "home",
          id: "home",
          icon: "home",
          children: [
            { lang: "clinical_home", icon: "home-grey", link: "home" },
            { lang: "home-admin", icon: "tasks", link: "home-admin" },
          ],
        });
      }
    }
    else if (
      !this.permsService.owner_has_users ||
      (this.permsService.owner_has_users &&
        !this.permsService.perms.sees_admin_homepage)
    ){
      if (!this.menu.find((it) => it?.id == "home")) {
        this.menu.unshift({
          lang: "home",
          id: "home",
          icon: "home",
          link: "home",
        });
      }
    }
    else {
      if (!this.menu.find((it) => it?.id == "home")) {
        this.menu.unshift({
          lang: "home",
          id: "home",
          icon: "home",
          link: "home-admin",
        });
      }
    }



    if (!this.permsService.owner_has_users) {
      let configuration = this.menu.find((it) => it?.id == "configuration");
      if (configuration) {
        configuration.children = configuration.children.filter(
          (it) => it?.id != "sub_users"
        );
        configuration.children = configuration.children.filter(
          (it) => it?.id != "sign-documents"
        );
      }

      let financial = this.menu.find((it) => it?.id == "financial");
      if (financial) {
        financial.children = financial.children.filter(
          (it) => it?.id != "followups_sub_user_report"
        );
        financial.children = financial.children.filter(
          (it) => it?.id != "followups_sub_user_rent_report"
        );
        financial.children = financial.children.filter(
          (it) => it?.id != "invoices_sub_user_report"
        );
        financial.children = financial.children.filter(
          (it) => it?.id != "patients_sub_user_report"
        );
        // financial.children = financial.children.filter(
        //   (it) => it?.id != "rent_report"
        // );
        financial.children = financial.children.filter(
          (it) => it?.id != "locations_report"
        );
        financial.children = financial.children.filter(
          (it) => it?.id != "sub_user_payments"
        );
        financial.children = financial.children.filter(
          (it) => it?.id != "supervisor_notes"
        );
      }
    }

    if (!isSuperAdmin) {
      let configuration = this.menu.find((it) => it?.id == "configuration");
      if (configuration) {
        configuration.children = configuration.children.filter(
          (it) => it?.id != "home-super-admin"
        );
      }
    }

    if (!this.old_debts_report) {
      let financial = this.menu.find((it) => it?.id == "financial");
      if (financial) {
        financial.children = financial.children.filter(
          (it) => it?.id != "debts_report"
        );
      }
    }
    if (this.old_debts_report) {
      let financial = this.menu.find((it) => it?.id == "financial");
      if (financial) {
        financial.children = financial.children.filter(
          (it) => it?.id != "debts_report2"
        );
      }
    }

    this.menu.forEach((it) => {
      if (this.lang.getConfigVal("img." + it.icon)) {
        it.isImg = true;
      }
      if (it.children) {
        it.children.forEach((it2) => {
          if (this.lang.getConfigVal("img." + it2.icon)) {
            it2.isImg = true;
          }
          if (it2.children) {
            it2.children.forEach((it3) => {
              if (this.lang.getConfigVal("img." + it3.icon)) {
                it3.isImg = true;
              }
            });
          }
        });
      }
    });
    

    this.eventsService.docClickSubject.subscribe((val: any) => {
      if (val?.src !== "userMenu") {
        this.userMenuIsOpen = false;
      }
    });

    this.hideShowMenuItems();

    this.store.hiddenMenuItemsSubj.subscribe(()=>this.hideShowMenuItems());

    this.router.events.subscribe(event=>{
      if(this.router?.url){
        const parts=this.router.url.split("/");
        this.curMenuItem=parts[1];
        if(this.curMenuItem=="home-admin"){
          this.curMenuItem="home";
        }
      }
    });


    this.route.queryParams.subscribe(params=>{

      if(params?.taskId){
        // this.modalService.openMulti("tasks",{taskId:params.taskId})
        this.modalService.openMulti("task",{taskId:params.taskId})
      }
    });
    await this.store.timeout(2000);
    this.store.get_task_notifications_total();
  }

  hideShowMenuItems(){
    this.cliniqData = this.store.getCliniqDataOrExit();
    const reports=this.cliniqData?.reports;
    if(!reports){return;}

    this.menu.filter(it=>it.children).forEach((it) => {
      it.children.forEach(it2 => {
        if(it2.lang in reports){
          it2.hiddenCc=!reports[it2.lang];
        }
      });
    });
  }

  userMenuOpen(ev: any) {
    this.userMenuIsOpen = !this.userMenuIsOpen;
    ev.stopImmediatePropagation();
    this.eventsService.onDocClick({ src: "userMenu" });
  }

  logout() {
    this.apiService.doLogout();
  }
  removeForPerms(arrItems: any[]) {
    for (let item of arrItems) {
      if (item.perms) {
        const findPermsThatIDoNotHave = item.perms.find(perm => !this.permsService.perms[perm]);
        if (findPermsThatIDoNotHave) {
          item.hidden = true;
        }
      }
      if (item.children) {
        this.removeForPerms(item.children);
      }
    }
  }

  async fuzzyClick(obj: any = null) {
    if (obj && obj.id) {
      this.router.navigateByUrl("/" + obj.typeClassName + "/" + obj.id);
      await this.store.timeout();
      this.searchFuzzy.emptySearchStr();
    }
  }


  clickNoti() {
    // this.modalService.openMulti("tasks");
    this.modalService.openMulti("task");
  }

  menuItemClick(item: any) {
    if (!item.link && !item.modal && !item.isReportsModal) {
      return;
    }
    this.modalService.closeLatestModal();
    if (item.isReportsModal) {
      this.modalService.openMulti("reports", {report_name: item.report_name});
    }
    else if (item.modal){
      this.modalService.openMulti(item.modal);
    }
    else {
      if(item.link==="calendar"){
        const to=`/calendar/${this.store.lastCalView || "week"}/today`;
        this.router.navigateByUrl(to);
      }
      else{
        this.router.navigateByUrl("/" + item.link);
      }
    }
    this.menu.forEach((it) => {
      if (it.isOpen) {
        it.isOpen = false;
      }
      if (it.children) {
        it.children.forEach((it2) => {
          if (it2.isOpen) {
            it2.isOpen = false;
          }
          if (it2.children) {
            it2.children.forEach((it3) => {
              if (it3.isOpen) {
                it3.isOpen = false;
              }
            });
          }
        });
      }
    });
  }

  openAffiliateLink(){
    const aff_code=this.store.getCliniqDataOrExit()?.aff_code;
    const link=location.origin+"/regi/regi?affiliate_user_id="+aff_code;
    const langText="affiliate_link";
    this.modalService.openMulti("copy-link",{link,langText});
  }
  openInvitePartner(){
    this.modalService.openMulti("invite-partner");
  }
  openCreateTakbullAccount(){
    this.modalService.openMulti("create-takbull-account");
  }
  openItaGetAuth(){
    this.modalService.openMulti("ita-get-auth");

  }
  openChangePassword(){
    this.modalService.openMulti("change-password");
  }
  openPatientForm(){
    const newName="";
    // const newName=((this.personFuzzyInput?.searchStr || ""));
    const newPatient:any= this.modalService.openMulti("types-create",{
      newName,
    });

    // if(newPatient){
    //   this.choosePatientOrContact(newPatient);
    //   if(newPatient?.assoc){
    //     // this.emptyAssoc();
    //     const objs=this.store.getSiteDataTable("sub_users").filter(it=>newPatient.assoc.includes(it.id));
    //     // objs.forEach(it=>this.addSubUser({...it,typeClassName:"sub_users"}));
    //   }
    // }
  }
}
