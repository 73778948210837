import { StoreService } from '@app/services/store.service';
import { ConfigDefinitions, ConflictReason, FollowupserieSave } from '@app/definitions/types';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { PermsService } from './perms.service';




@Injectable({
	providedIn: 'root'
})
export class OverlapService {

	constructor(private store: StoreService, private permsService: PermsService) { }

	async find_overlap(newFus: FollowupserieSave) {  //return either false OR string containing the overlap message
		//followuptype_id!=1 OR takes_place=="no" (no overlap)

		let assoc_sub_user_ids = this.store.getFusSpreadAssocIds(newFus);
    if(this.permsService.owner_has_users && !this.permsService.perms?.administrative){
     assoc_sub_user_ids=[String(this.store.getCliniqDataOrExit()?.user_id)];
    }


		if (newFus.followuptype_id != 1 || newFus?.takes_place == "no") {
			return false;
		}

    const overlapMR=this.getOverlapWithMRs(newFus);
    if(overlapMR){
      return {...overlapMR,isMRConflict:true};
    }

		newFus.start_day = moment.utc(newFus.followupserie_date).weekday();
		const ownerHasUsers = this.permsService?.owner_has_users;


		//range to check against: all fus (followuptype_id==1 AND takes_place=="yes" AND (series_limit_date is NONE OR  series_limit_date>=date) AND start_day=start_day )

		const allFus: any = await this.store.getAllFus();

		let potentialFuses = allFus.followupseries.filter(pFus => pFus.followuptype_id == 1
			&& pFus.id != newFus.id
			&& pFus.series_repeat != "series_exception"
			&& pFus.status == "active"
			&& pFus.takes_place == "yes"
			&& pFus.start_day == newFus.start_day
			&& (pFus.series_limit_date == null || moment.utc(pFus.series_limit_date).isSameOrAfter(moment.utc(newFus.followupserie_date)))
		);

		if (!potentialFuses.length) { return false; }

		let nonConflicts = [];

		const newFusLocation = this.store.searchSiteDataItem(newFus.location_id, ["locations"]);
		if (ownerHasUsers) {
			for (let potentialFus of potentialFuses) {	//iterate potential to decide of they remain potential or not
				potentialFus.conflictReasons = [];



				//if same patient - has conflict
				if (potentialFus.patient_id && potentialFus.patient_id == newFus?.patient_id) {
					potentialFus.conflictReasons.push("patient");
					// continue;	//will continue after collecting all conflictReasons
				}

				//if same user - has conflict
				if (potentialFus.user_id && potentialFus.user_id == newFus?.user_id) {
					potentialFus.conflictReasons.push("user");
					// continue;	//will continue after collecting all conflictReasons
				}

				//if same contact - has conflict
				if (potentialFus.contact_id && potentialFus.contact_id == newFus?.contact_id) {
					potentialFus.conflictReasons.push("contact");
					// continue;	//will continue after collecting all conflictReasons
				}

				const cc=this.store.getCliniqDataOrExit();
				if(!cc?.locations_only){

					//if this user id exists in new fus' assoc user ids - STILL POTENTIALLY A CONFLICT!
					potentialFus.assoc_sub_user_ids = this.store.getFusSpreadAssocIds(potentialFus);

					const foundUserId = potentialFus.assoc_sub_user_ids.find(userId => assoc_sub_user_ids.includes(userId));
					if (foundUserId) {
						potentialFus.conflictReasons.push("assoc");
						// continue;	//will continue after collecting all conflictReasons
					}

					//if potential fus has user_id that's in a assoc - STILL A CONFLICT
					if (potentialFus.user_id) {
						if (assoc_sub_user_ids.includes(String(potentialFus.user_id))) {
							potentialFus.conflictReasons.push("assoc");
							// continue;	//will continue after collecting all conflictReasons
						}
					}

					//if fus has user_id that's in a potential's assoc - STILL A CONFLICT
					if (newFus?.user_id) {
						if (potentialFus.assoc_sub_user_ids.includes(String(newFus.user_id))) {
							potentialFus.conflictReasons.push("user");
							// continue;	//will continue after collecting all conflictReasons
						}
					}
				}



				//ALSO CHECK FOR LOCATION CONFLICTS BEFORE DISMISSING THIS POTENTIAL
				if (newFusLocation && newFusLocation.is_conflicting == "yes" && newFus.location_id == potentialFus.location_id) {
					potentialFus.conflictReasons.push("location");
					// continue;	//will continue after collecting all conflictReasons
				}

				if (potentialFus.conflictReasons.length) {	//found at least 1 conflict reason - STILL A CONFLICT
					continue;
				}

				nonConflicts.push(potentialFus.id);	//NOT FOUND any conflicting user ids - remove this potential!
			}
		}
		potentialFuses = potentialFuses.filter(pFus => !nonConflicts.includes(pFus.id));



		if (!potentialFuses.length) { return false; }


		nonConflicts = [];

		//filter out: if "new fus" time is contained in potential fus's time-time_to OR "new fus" time_to is contained in potential fus's time-time_to

		const fusStart = moment.utc(`${newFus.followupserie_date} ${newFus.followupserie_time}`);
		const fusEnd = moment.utc(fusStart).add(newFus.length, "minutes");


		for (let potentialFus of potentialFuses) {
			const potentialFusStart = moment.utc(`${newFus.followupserie_date} ${potentialFus.time}`);
			const potentialFusEnd = moment.utc(`${newFus.followupserie_date_to} ${potentialFus.time_to}`);


			//trap the fusStart - if found then STILL A CONFLICT
			if (fusStart.isBetween(potentialFusStart, potentialFusEnd, null, "[)")) { continue; }

			//trap the fusEnd - if found then STILL A CONFLICT
			if (fusEnd.isBetween(potentialFusStart, potentialFusEnd, null, "(]")) { continue; }

			//trap the potentialFusStart - if found then STILL A CONFLICT
			if (potentialFusStart.isBetween(fusStart, fusEnd, null, "(]")) { continue; }

			//trap the potentialFusEnd - if found then STILL A CONFLICT
			if (potentialFusEnd.isBetween(fusStart, fusEnd, null, "[)")) { continue; }

			//neither start nor end were inside this potential fus times - remove it from potentials list


			nonConflicts.push(potentialFus.id);	//NOT FOUND any conflicting - remove this potential!
		}
		potentialFuses = potentialFuses.filter(pFus => !nonConflicts.includes(pFus.id));

		if (!potentialFuses.length) { return false; }




		const hasSeriesLimitData = (newFus.series_repeat != "one_time" && newFus.series_limit_date);
		//also get all exceptions for same potential fus - if found overlap, make sure exception doesn't exist in this date
		const exceptionFuses = allFus.fusExceptions.filter(exFus => (
			potentialFuses.map(pFus => pFus.id).includes(exFus.series_id)
			&& moment.utc(exFus.date).isSameOrAfter(moment.utc(newFus.followupserie_date))
			&& (!hasSeriesLimitData || moment.utc(exFus.date).isSameOrBefore(moment.utc(newFus.series_limit_date)))
		));

		//get all vacations that can override the conflicts
		const vacationFuses = allFus.followupseries.filter(vacFus => (
			vacFus.followuptype_id == 5
			&& moment.utc(vacFus.date_to).isSameOrAfter(moment.utc(newFus.followupserie_date))
			&& (!hasSeriesLimitData || moment.utc(vacFus.date).isSameOrBefore(moment.utc(newFus.series_limit_date)))
		));

		if (newFus.series_repeat == "one_time") {
			//one_time - check this date

      const dateToCheck=newFus.followupserie_date;

			const overlapFus = this.find_overlap_for_date(dateToCheck, potentialFuses, exceptionFuses, vacationFuses);
			return overlapFus;
		}
		else {
			//series
			//start from date, iterate your series_repeat and check every date



			let momDate = moment.utc(newFus.followupserie_date);
			const weekSteps=this.getWeekSteps(newFus);


			for (let i = 0; i < 104 / weekSteps; i++) {   //only check 2 years (104 times in weekly, 52 times in 2weekly, 27 times in monthly)
				//end iteration in 1000

        const dateToCheck=momDate.format(ConfigDefinitions.momentDateFormat);


				const overlapFus = this.find_overlap_for_date(dateToCheck, potentialFuses, exceptionFuses, vacationFuses);

				if (overlapFus) {
					return overlapFus;
				}
				momDate.add(weekSteps, "weeks");

				//if series_limit_date - end iteration in series_limit_date
				// Log::info($momDate->format("Y-m-d")." ".$i);
				if (newFus.series_limit_date && moment.utc(newFus.series_limit_date).isBefore(momDate)) {
					break;
				}
			}
			return false;
		}
	}


	find_overlap_for_date(date: string, potentialFuses: any[], exceptionFuses: any[], vacationFuses: any[]) {    //return either false OR string containing the overlap message

		//for each potential fus, takes its "week in the year" and uses it with %2/%4 to see if happens this date
		const dateMom = moment.utc(date);
		for (let potentialFus of potentialFuses) {  //check each potential if has overlap

			//if potential hasn't started yet - no overlap
			if (moment.utc(potentialFus.date).isAfter(moment.utc(date))) {
				continue;
			}


			//if potential's series_limit_date is before this date - no overlap
			if (potentialFus.series_limit_date && moment.utc(potentialFus.series_limit_date).isBefore(moment.utc(date))) {
				continue;
			}

			//if ((currentDate.week() - moment.utc(fus.date).week()) % 4 == 0) {

			let isNonConflict = false;
			switch (potentialFus.series_repeat) {
				case "one_time":
					if (potentialFus.date != date) { //the potenial's date is different than this date - no overlap
						isNonConflict = true;
					}
					break;
				case "series_weekly":
					//has overlap!
					break;
				case "series_2weekly":
					if (moment.utc(potentialFus.date).diff(dateMom, "weeks") % 2 != 0) {   //the potenial's week and new week are NOT both (either odd or even) - no overlap
						isNonConflict = true;
					}
					break;
				case "series_3weekly":
					if (moment.utc(potentialFus.date).diff(dateMom, "weeks") % 3 != 0) {
						isNonConflict = true;
					}
					break;
				case "series_monthly":
					if (moment.utc(potentialFus.date).diff(dateMom, "weeks") % 4 != 0) {   //the potenial's week and new week are NOT both (remainder of 4) - no overlap
						isNonConflict = true;
					}
					break;
			}
			if (isNonConflict) {
				continue;
			}


			//if made it to here (no continue) - overlap exists!!

			//check if an exception (with the series_id of this potential AND this date) contradicts this overlap
			const foundException = exceptionFuses.find(exFus => {
				return exFus.series_id == potentialFus.id && exFus.date == date;
			});
			if (foundException) {    //found an exception - no overlap!
				continue;
			}
			//did not find exception - overlap still exists

			const ownerHasUsers = this.permsService?.owner_has_users;

			//try to remove this potential fus's conflict - if it does not take place because of a vacation
			if (potentialFus.meeting_on_holiday == "no") { //only "no" - if "yes" than vacations are NOT conflicts to this fus
				//check if any of the vacations "contain" this potential fus
				for (let vacFus of vacationFuses) {

					if (ownerHasUsers) {	//if assoc check is needed
						const vacAssocUserIds = this.store.getFusSpreadAssocIds(vacFus);
						const potentialAssocUserIds = potentialFus.assoc_sub_user_ids;

						if (vacAssocUserIds.length && !potentialAssocUserIds.includes(vacAssocUserIds[0])) {	//if this vac assoc user ISN'T part of the fus assoc users - continue to next vacation
							continue;
						}
					}

					const potentialFusStartMom = moment.utc(date + " " + potentialFus.time);
					const potentialFusEndMom = moment.utc(date + " " + potentialFus.time_to);
					const vacStartMom = moment.utc(vacFus.date + " " + vacFus.time);
					const vacEndMom = moment.utc(vacFus.date_to + " " + vacFus.time_to);


					//trap the fusStart
					if (potentialFusStartMom.isBetween(vacStartMom, vacEndMom, null, "[]")) {	//the potential fus's START is CONTAINED IN THE VACATION!!!! so it's no longer a conflict (does NOT take place)
						isNonConflict = true;
						break;
					}

					//trap the fusEnd
					if (potentialFusEndMom.isBetween(vacStartMom, vacEndMom, null, "[]")) {    //the potential fus's END is CONTAINED IN THE VACATION!!!! so it's no longer a conflict (does NOT take place)
						isNonConflict = true;
						break;
					}

					//trap the fusStart
					if (vacStartMom.isBetween(potentialFusStartMom, potentialFusEndMom, null, "[]")) {  //the potential fus's START is CONTAINED IN THE VACATION!!!! so it's no longer a conflict (does NOT take place)
						isNonConflict = true;
						break;
					}

					//trap the fusEnd
					if (vacEndMom.isBetween(potentialFusStartMom, potentialFusEndMom, null, "[]")) {    //the potential fus's END is CONTAINED IN THE VACATION!!!! so it's no longer a conflict (does NOT take place)
						isNonConflict = true;
						break;
					}
				}

				if (isNonConflict) {	//potential was discovered to be NON CONFLICT so move on to the next potential
					continue;
				}
			}

			//did not resolve conflict with vacation - return message
			return {...potentialFus,conflictDate:date};

		}
		return false;
	}

	doFusesOverlap(timeA: any, timeToA: any, timeB: any, timeToB: any) {
		timeA = this.store.timeToMoment(timeA);
		timeToA = this.store.timeToMoment(timeToA);
		timeB = this.store.timeToMoment(timeB);
		timeToB = this.store.timeToMoment(timeToB);

		return (
			timeA.isBetween(timeB, timeToB, null, "[]") ||
			timeToA.isBetween(timeB, timeToB, null, "[]") ||
			timeB.isBetween(timeA, timeToA, null, "[]") ||
			timeToB.isBetween(timeA, timeToA, null, "[]")
		)
	}

  getOverlapWithMRs(newFus:any){
    const fusStart = moment.utc(`${newFus.followupserie_date} ${newFus.followupserie_time}`);
		const fusEnd = moment.utc(fusStart).add(newFus.length, "minutes");

    const potentiallyConflictingMeetingRequests=[];
    const meeting_requests=this.store.meeting_requests.filter(it=>it.id!=(newFus?.meeting_request_id || null));
    for (let meeting_request of meeting_requests) {
      if(meeting_request.payment_only=="yes"){continue};
      const meetingtype=this.store.searchSiteDataItem(meeting_request.meetingtype_id,["meetingtypes"])
			const potentialMRStart = moment.utc(`${newFus.followupserie_date} ${meeting_request.time}`);
			const potentialMREnd = moment.utc(potentialMRStart).add(meetingtype.length,"minutes");


			//trap the fusStart - if found then STILL A CONFLICT
			if (fusStart.isBetween(potentialMRStart, potentialMREnd, null, "[)")) { potentiallyConflictingMeetingRequests.push(meeting_request);continue; }

			//trap the fusEnd - if found then STILL A CONFLICT
			if (fusEnd.isBetween(potentialMRStart, potentialMREnd, null, "(]")) { potentiallyConflictingMeetingRequests.push(meeting_request);continue; }

			//trap the potentialMRStart - if found then STILL A CONFLICT
			if (potentialMRStart.isBetween(fusStart, fusEnd, null, "(]")) { potentiallyConflictingMeetingRequests.push(meeting_request);continue; }

			//trap the potentialMREnd - if found then STILL A CONFLICT
			if (potentialMREnd.isBetween(fusStart, fusEnd, null, "[)")) { potentiallyConflictingMeetingRequests.push(meeting_request);continue; }

			//neither start nor end were inside this potential fus times - remove it from potentials list
		}
    if(!potentiallyConflictingMeetingRequests.length){return false}

    if (newFus.series_repeat == "one_time") {
      const dateToCheck=newFus.followupserie_date;
      const find=potentiallyConflictingMeetingRequests.find(mr=>mr.date==dateToCheck);
      return find;

    }
    else{
      let momDate = moment.utc(newFus.followupserie_date);
			const weekSteps=this.getWeekSteps(newFus);

      for (let i = 0; i < 104 / weekSteps; i++) {   //only check 2 years (104 times in weekly, 52 times in 2weekly, 27 times in monthly)
				//end iteration in 1000

        const dateToCheck=momDate.format(ConfigDefinitions.momentDateFormat);
        const find=potentiallyConflictingMeetingRequests.find(mr=>mr.date==dateToCheck);
        if(find){
          return find;
        }

        momDate.add(weekSteps, "weeks");

				//if series_limit_date - end iteration in series_limit_date
				// Log::info($momDate->format("Y-m-d")." ".$i);
				if (newFus.series_limit_date && moment.utc(newFus.series_limit_date).isBefore(momDate)) {
					break;
				}
      }

    }
    return false;

  }

  getWeekSteps(newFus:any){
    switch (newFus.series_repeat) {
      case "series_monthly":
        return 4;
      case "series_2weekly":
        return 2;
      case "series_3weekly":
        return 3;
    }
    return 1;
  }

}

// $weekday_name = H:: get_weekday_name($potentialFus -> start_day);
// 			$meeting_name = Lang:: get('g.'.$potentialFus -> series_repeat);

// 			$overlap_txt.= Lang:: get('g.on'). " ".$weekday_name. " ".$potentialFus -> time. " ".
// 				Lang:: get('g.a_meeting'). ' '.$meeting_name. '<br>'.
// 					Lang:: get('g.with'). ' '.$potentialFus -> getWithName(). '<br>'.
// 						Lang:: get('g.start_on').H:: hebrew_date($potentialFus -> date). '<br>';
// 			return $overlap_txt;

