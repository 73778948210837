import { ModalService } from './modal.service';
import { ErrorService } from './error-service.service';
import { ApiService } from '@app/services/api.service';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpResponse,
	HttpErrorResponse,
  HttpParams
} from '@angular/common/http';
import { Observable, EMPTY, throwError, of } from 'rxjs';
import { tap, catchError, retry } from 'rxjs/operators';

@Injectable()
export class RegInterceptor implements HttpInterceptor {	//intercepts network requests to the api - adds the isNg flag, dummy variable, XSRF token, and catches responses with errors
	constructor(public apiService: ApiService, private router: Router, private errorService: ErrorService) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		let body = request.body;

		if (body && body.getAll) {	//sent with uploaded file(s) as formData, so handles the body as forData
			body.append("isNg", true);	//adds isNg flag
			body.append("dummy", (new Date()).getTime());	//adds changing dummy
		}
		else {
			body = { ...body, isNg: true, dummy: (new Date()).getTime() }	//same adds
		}

    // const params=(request.params ? request.params : new HttpParams()).set("ngsw-bypass",true);
		request = request.clone({
			// setHeaders: {
			// 	// Authorization: `Bearer ${this.auth.getJwt()}`
			// 	'X-XSRF-TOKEN': decodeURIComponent(this.apiService.csrf)	//adds csrf token saved in the apiService
			// },
			...request,
			body,
      // params,
		});

		return next.handle(request).pipe(	//handle the request
			tap((event: HttpEvent<any>) => {
				if (event instanceof HttpResponse) {
					const body = event?.body;
					if (body && body?.error && (body?.token || body?.methodError)) {
						//token is for expired CSRF token
						//methodError is in case functions in the server have changed names (along with path in web.php) but front isn't updated yet
						return window.location.assign("/login?mes=token-refresh");	//redirect to login page;
					}
          this.errorService.networkOfflineSubj.next(false);


					// if(event.body){
					// 	if(event.body.jwtFail){
					// 		this.auth.reset();
					// 		this.router.navigate(['/login']);
					// 		this.modalService.callErr('token error');
					// 		throw new Error("");
					// 	}
					// 	if(event.body.err){
					// 		let err=event.body.err;
					// 		err=Array.isArray(err)?err.join("<br>"):err;
					// 		this.modalService.callErr(err);
					// 		throw new Error("");
					// 	}
					// }
				}
				return event;
			}),
			// retry(1),
			catchError((err: any) => {	//if event returned with an error - sends it to the errorService handler and returns empty
				this.errorService.responseErr(err);
				return EMPTY;
			}),
			// catchError(err=>of(EMPTY)),


		);
	}
}
