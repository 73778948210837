<div class="container-wrap">
  <h4  class="gen-title">{{"security" | langVal}}</h4>

  <hr class="separation-line" />

  <div >
    <div class="row">
      <div class="col-6 col-md-2 ">
        <label>{{"time_to_logout" | langVal}}</label>
        <app-gen-input [tabindex]="63" [actualObj]="configcliniq" [errors]="errors" fieldName="time_to_logout" (keyupEvent)="validateAndSave()" />
      </div>
    </div>
    @if(!permsService.owner_has_users){
      <div class="row">
        <div class="col-12 col-lg-2 ">
          <label>{{"is_google2fa" | langVal}}</label>
          <app-gen-button-group [actualObj]="configcliniq" fieldName="is_google2fa" optionsListName="yes_no_opts" (changeEvent)="validateAndSave()" />
        </div>
        <div class="col-12 col-lg-2 ">
          <button class="btn btn-default" (click)="store.openGoogle2faModal()" style="margin-top: 20px" preIcon="changePassword" >{{"get_2fa_key" | langVal}}</button>
        </div>

      </div>
    }

  </div>


</div>

