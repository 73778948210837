<div style="padding: 40px 40px;background-color: white;">
  @if(res){
    <p> {{"test_by" | langVal}}  {{res.user_name}}</p>
  }
  @if(testDone){
    <p>{{res.name}} , {{"thank_you_for_filling" | langVal}}</p>
  }
  @else{
    @if(test_id){
      <app-testanswers-fill (onFinish)="testFinish($event)" [test_id]="test_id" inUserPatientMode  />
    }
  }
</div>
