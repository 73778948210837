import {OverlapService} from '@app/services/overlap.service';
import {SearchSortService} from '@app/services/search-sort.service';
import {StoreService} from '../services/store.service';
import {LangService} from '@app/services/lang.service';
import {
    Component,
    Input,
    OnInit,
    Output,
    ViewChild,
    EventEmitter,
    ElementRef,
    HostListener,
    SimpleChange,
    signal,
    computed,
    booleanAttribute
} from '@angular/core';
import {ApiService} from '@app/services/api.service';
import {ModalService} from '@app/services/modal.service';
import {ActivatedRoute, Router} from '@angular/router';
import {
    CalendarExportAction,
    ConfigDefinitions,
    FollowupserieSave,
    FusCrudReturnObj,
    PatientColor,
    TagsObj,
    UsersColor,
    calUrlView,
} from '@app/definitions/types';
import {Subject} from 'rxjs';
import {PermsService} from '@app/services/perms.service';
import {debounceTime} from 'rxjs/operators';
import * as moment from 'moment';
import {GenAutocompleteComponent} from '@app/gen-inputs/gen-autocomplete/gen-autocomplete.component';
import {EventsService} from '@app/services/events.service';

import {FullCalendarComponent} from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import {CalendarOptions} from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import adaptivePlugin from '@fullcalendar/interaction';


@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrl: './calendar.component.css'
})
export class CalendarComponent implements OnInit {
    dbCallSubj = new Subject();
    hoverObj: any = null;
    fusSubscription: any = null;
    cliniqData: any;

    PatientColor = PatientColor;
    UsersColor = UsersColor;
    @ViewChild("calendar") calendar: FullCalendarComponent;
    // @ViewChild("datePicker") datePicker:DatePickerComponent;
    @ViewChild("container") container: ElementRef;
    @Input({transform:booleanAttribute}) isHome: boolean = false;
    @Input({transform:booleanAttribute}) isGuest: boolean = false;
    @Output() loadingIsTrue = new EventEmitter();
    @Output() outputAction = new EventEmitter<CalendarExportAction>();
    dayEvents: any[] = [];
    dayEventsAll: any[] = [];
    periodTitle: any = "";
    firstChangeHappened: boolean = false;
    firstApiCallHappened: boolean = false;
    currentUserId: number = 0;
    @ViewChild("subUsersFuzzy") subUsersFuzzy: GenAutocompleteComponent;

    printScreenMode: boolean = false;
    currentView = signal("timeGridWeek");
    currentViewLangVal = computed(() => {
        switch (this.currentView()) {
            case "dayGridMonth":
                return "this_month";
            case "timeGridWeek":
                return "this_week";
            default:
                return "today";
        }
    });

    subjectSubs: any[] = [];
    @Input() urlView: calUrlView;
    @Input() urlDate: string = "";
    fcViewToUrlView: any = {
        timeGridWeek: "week",
        dayGridMonth: "month",
        resourceTimeGridDay: "day",
        timeGridDay: "day",
    };
    onlyResourceId: number = null;
    shortMeetingsCalendar:boolean=false;

    isAddMeetingOpen: boolean = false;
    locationTagsObj=new TagsObj;
    userTagsObj=new TagsObj;

    // tslint:disable-next-line
    calendarOptions: CalendarOptions = {
        plugins: [dayGridPlugin, resourceTimeGridPlugin, interactionPlugin,adaptivePlugin],
        //schedulerLicenseKey: "0499824086-fcs-1638263109",
        // schedulerLicenseKey: "0620358725-fcs-1669799109",
        schedulerLicenseKey: "0261304089-fcs-1701338804",
        slotLabelFormat: {hour: "2-digit"},
        initialView: 'timeGridWeek',
        slotMinTime: "05:00:00",
        slotMaxTime: "00:00:00",
        allDaySlot: false,
        height: "auto",
        timeZone: "UTC",
        locale: this.lang.langCode,
        eventDisplay: "block",
        slotEventOverlap: false,
        eventMinHeight: 20,
        direction: this.lang.isRtl ? 'rtl' : 'ltr',
        events: [],


        hiddenDays: [],


        eventDragStart: this.eventDragStart.bind(this),
        eventClick: this.handleEventClick.bind(this), // bind is important!
        dateClick: this.handleDateClick.bind(this),
        eventDrop: this.handleEventDrop.bind(this),
        eventResize: this.handleEventResize.bind(this),

        eventMouseEnter: this.evMouseEnter.bind(this),
        eventMouseLeave: this.evMouseLeave.bind(this),
        navLinkDayClick: this.dayClick.bind(this),
        // eventWillUnmount:this.evUnMount.bind(this),
        eventDidMount: this.evMount.bind(this),
        datesSet: this.changePeriod.bind(this),
        dayHeaderContent: this.dayHeaderContent.bind(this),
        resourceLabelContent: this.resourceLabelContent.bind(this),
        dayCellContent: this.dayCellContent.bind(this),
        resourceLabelDidMount: this.resourceLabelDidMount.bind(this),
        eventContent: this.eventContent.bind(this),
        headerToolbar: false,
        stickyHeaderDates:true,

        editable: false,
        navLinks: true,
        views: {
            week: { // name of view
                titleFormat: {year: "2-digit", month: 'numeric', day: 'numeric'}

                // other view-specific options here
            },
            day: { // name of view
              titleFormat: {year: "2-digit", month: 'numeric', day: 'numeric'}
                // other view-specific options here
            },
          resourceTimelineDay: {

          }
        },
        resourceOrder: "ord",

    };

    constructor(protected apiService: ApiService, public modalService: ModalService, public lang: LangService, protected store: StoreService, protected searchSortService: SearchSortService, protected eventsService: EventsService, public permsService: PermsService, private overlapService: OverlapService, private route: ActivatedRoute, public router: Router) {


        let subs = this.dbCallSubj.pipe(
            debounceTime(250),
        ).subscribe(async () => {
            await this.getFollowupseriesFromStore();
            this.outputActionFunc(this.dayEvents?.length ? this.dayEvents[0] : null);
        });
        this.subjectSubs.push(subs);

        subs = this.store.calendarChangesSubj.subscribe(async (res) => {	//post sync
            await this.getFollowupseriesFromStore();
            // this.refreshLastEv.emit(true);
            this.outputActionFunc();
        });
        this.subjectSubs.push(subs);

        subs = this.store.calendarOpenMeetingSubject.subscribe(async (meeting_request: any) => {
            const dateObj = {date: new Date(meeting_request.date + " " + meeting_request.time + "Z")};
            await this.store.timeout(200);
            this.goToDate(meeting_request.date);
            // await this.store.timeout(200);
            // this.handleDateClick(dateObj,meeting_request);
        });
        this.subjectSubs.push(subs);

        subs = this.store.calendarOpenMeeting2Subject.subscribe(async (creatingObj: any) => {
            const dateObj = {date: new Date(creatingObj.date + " " + creatingObj.time + "Z")};
            await this.store.timeout(200);
            // if(this.permsService.owner_has_users){
            //   this.changeView('dayGridMonth');
            // }
            this.goToDate(creatingObj.date);
            await this.store.timeout(200);
            this.handleDateClick(dateObj, null, creatingObj);
        });
        this.subjectSubs.push(subs);

        // this.determineDays();
    }

    // determineDays(){

    // }

    // ngOnChanges(changes: SimpleChange) {	//if person changed (ex: contact) - send to load function
    // 	if (changes["isHome"]) {
    //     this.determineDays();
    // 	}
    // }

    @HostListener('window:orientationchange', ['$event'])	//on orientationchange - inform the event service (used for isMobile detection)
    onorientationchange(event) {
        if (!this.isHome) {
            //   //event.target.innerWidth < ????
            window.location.reload();
        }
    }

    resourceLabelDidMount(arg: any) {
        arg.el.addEventListener("click", () => {
            this.onlyResourceId = arg.resource?.id;
            this.getFollowupseriesFromStore();
        });
    }

    outputActionFunc(centerFus: any = null) {
        if (!this.isHome || !this.calendar.getApi()) {
            return;
        }


        const currentDate = this.getCurrentCalDate();
        const output: CalendarExportAction = {
            dayEvents: this.dayEvents,
            currentDate,
            centerFus,
        };
        this.outputAction.emit(output);
    }


    evMount(ev: any) {
      if(ev?.event?.extendedProps?.dbObj?.length<25){
        const el=ev.el.parentNode;
        if(!el.classList.contains("short-length")){
          el.classList.add("short-length");
        }
      }
      if (!(ev?.event?.extendedProps?.dbObj?.followuptype_id == 5)) {
          return
      }


      ev.el.parentNode.style.marginLeft = "0px";
      ev.el.parentNode.style.right = "0%";
      ev.el.parentNode.style.left = "40%";
      ev.el.parentNode.style.zIndex = "0";



    }

    getHoliday(date: any) {

        const holidays = this.store.getSiteDataTable("holidays");
        return holidays.find(holiday => {
            const findInstance = holiday.instances.find(instance => {
                return date.isBetween(moment.utc(instance.date), moment.utc(instance.date_to), "day", "[]");
            })
            return !!findInstance;
        });

    }

    dayCellContent(arg: any) {
        if (!this.calendar?.getApi()?.view) {
            return
        }
        if (this.calendar.getApi().view?.type == "dayGridMonth") {
            const date = moment.utc(arg.date);
            const findHoliday = this.getHoliday(date);
            const dateStr = date.date();
            if (!findHoliday) {
                return {html: dateStr}
            }
            return {html: `<div class="cal-month-holiday"  ><div>${findHoliday.short_name}</div><div>${dateStr}</div></div>`};
        }
    }

    dayHeaderContent(arg: any) {
        const date = moment.utc(arg.date);
        const cc = this.store.getCliniqDataOrExit();
        const findHoliday = this.getHoliday(date);
        let arr = arg.text.replace("שבת", "ש").replace("יום ", "").split(" ");
        arr[0] = arr[0].replace("׳", "");
        if (this.eventsService.isMobile && this.currentView() != "timeGridDay" && cc?.lang == "en" && !this.isHome) {
            arr[0] = arr[0].substring(0, 1);
        }
        let html ="";
      if (findHoliday) {
        html += `<div title="${findHoliday.name}" class="holiday-name">${findHoliday.short_name}</div>`;
      }
        html += `<div>${arr.map(it => `<span>${it}</span>`).join("")}</div>`;


        return {html};
    }

    resourceLabelContent(arg: any) {
        const resource = arg.resource;
        const color = resource?.extendedProps?.color || "";


        return {html: `<span style='color:${color}'>${resource.title}<span>`};
    }

    async ngOnInit() {
      this.cliniqData = this.store.getCliniqDataOrExit();
      this.shortMeetingsCalendar=this.cliniqData.short_meetings_calendar === "yes";

      if (this.cliniqData?.no_saturday == "yes") {
        this.calendarOptions.hiddenDays = [6];
      }
      this.resetSubUserIds();
      if (this.isHome) {
        this.calendarOptions.initialView = "timeGridDay";
      } else {
        this.eventsService.updateTitle(this.lang.getVal("calendar"));
      }
      this.calendarOptions.slotMinTime = this.cliniqData.calendar_start_time;
      this.calendarOptions.slotMaxTime = this.cliniqData.calendar_end_time;
      // this.firstChangeHappened=true;
      await this.store.timeout(100);
      this.firstChangeHappened = true;

      if (!this.isHome) {
        this.urlView = this.urlView ?? "week";
        this.urlDate = this.urlDate ?? "today";

        this.changeView(this.urlViewToCalView(this.urlView));
        if (this.urlDate && this.urlDate !== "today") {

          this.goToDate(this.urlDate);
        }
      }

    }

    urlViewToCalView(urlView: calUrlView) {
        switch (urlView) {
            case "week":
                return "timeGridWeek";
            case "month":
                return "dayGridMonth";
            case "day":
                return this.permsService?.owner_has_users ? "resourceTimeGridDay" : "timeGridDay";
        }
    }

    eventDragStart(arg: any) {
      if(this.isGuest){return}
        if (arg.event.extendedProps?.isTa) {
            return
        }
        this.hoverObj = null;
        let dbObj = arg.event?.extendedProps?.dbObj || null;
    }

    getEvTitle(fus: any) {
        // if(this.cliniqData?.full_details_on_calendar=="no" && this.cliniqData?.ownerHasUsers && this.permsService.perms?.administrative){
        //   return "";
        // }else{
        return fus.name;//fus.meeting_with_name || fus.meeting_title_dec || fus.meeting_title;
        // }

    }

    assignEvProps(ev: any) {
        let obj = ev.extendedProps.dbObj;

        let patientIconColor = "black";
        let title = this.getEvTitle(obj);
        const isVacation = obj.followuptype_id == 5;
        let datePart = `${obj.time_to_render}-${obj.time_render}`;
        if (obj.followuptype_id == 4 || obj.followuptype_id == 6) {
            datePart = `${obj.time_render}`;
        }
        if (isVacation) {
            datePart = `${obj.date_render} - ${obj.date_to_render}`;
        }
        let iconCls = "";
        if (obj.contact_id) {
            iconCls = "contact";
        }
        if (obj.patient_id) {
            iconCls = "patient";
        }
        if (obj.user_id) {
            iconCls = "sub_user";
        }
        if (obj.is_group == "yes") {
            iconCls = "group";
        }
        if (!iconCls) {
            iconCls = "handshake";
        }
        let seriesIcon = (obj.series_repeat != 'one_time' ? "repeat" : "");
        let holidayIcon = (obj.holiday_id ? "holiday" : "");
        let minutes = obj.length;
        // let remarks=obj?.followup_remarks_abst || "";
        // remarks=remarks.substr(0,100);
        // let followup_description=obj?.followup_description_abst || "";
        // followup_description=followup_description.substr(0,100);
        let notes = obj?.notes || "";
        let show_id = "";
        if(!this.isGuest){

          if (obj.contact_id) {
              show_id = "C-" + obj.contact_id;
          }
          if (obj.patient_id) {
              show_id = "P-" + obj.patient_id;
          }
          if (obj.user_id) {
              show_id = "U-" + obj.user_id;
          }
        }

        let patientRemarks = "";
        if (obj.patient_id) {
            patientRemarks = this.store.searchSiteDataItem(obj.patient_id, ["patients"])?.remarks || "";
            patientRemarks=this.store.normalizeHtml(patientRemarks, 300);
        }


        let location = "";
        let location_id = obj?.location_id || 0;
        if (obj?.location_id) {
            location = this.store.searchSiteDataItem(obj?.location_id, ["locations"])?.name || "";
        }
        let assocUsers = [];
        if (obj?.assoc_sub_user_ids?.length) {
            assocUsers = this.store.getAssocSubUsersForFus(obj);

            // assocUsers = this.store.getSiteDataTable("sub_users")
            // 	.filter(user => obj.assoc_sub_user_ids.includes(String(user.id)))
            // 	.map(user => user.name)
            // 	.join(", ");
        }


        ev.extendedProps.display = {
            patientIconColor,
            title,
            datePart,
            iconCls,
            seriesIcon,
            holidayIcon,
            minutes,
            location_id,
            // remarks,
            notes,
            // followup_description,
            show_id,
            isVacation,
            location,
            assocUsers,
            patientRemarks,
        }
    }

    getTimeDisplay(d: Date) {
        let hours = d.getUTCHours();
        let minutes = d.getMinutes();

        return `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}`;
    }

    eventContent(arg: any) {
        let ev = arg.event;
        let dispObj = ev.extendedProps.display;
        let timeDisplay = null;
        if (ev.end) {
            timeDisplay = this.getTimeDisplay(ev.end) + "-" + this.getTimeDisplay(ev.start);
            if (this.permsService?.owner_has_users) {
                timeDisplay = this.getTimeDisplay(ev.start)
            }
        }

        if (this.calendar.getApi().view?.type == "dayGridMonth" && this.eventsService.isMobile) {
            //this.eventsService.isMobile
            const html = `<div class="event-square"> ${ev.title} </div>`;
            return {html};
        }


        if (ev.extendedProps?.dbObj?.is_meeting_request) {
            let html = `
			<div class="event-square" >
        	<span class="event-hours"  style="color:green;"  >${timeDisplay || dispObj.datePart}</span>
				<span style="color:black; " class="low_opacity">
				${ev.title}
				</span>
			</div>
			`;
            return {html};
        }
        if (ev.extendedProps?.isTa) {
            let html = `
			<div class="event-square">
				<span style="color:black; " class="low_opacity">
				${ev.title}
				</span>
			</div>
			`;
            return {html};
        }

        let circleColor = "";
        if (dispObj?.location_id) {
            const loc = this.store.searchSiteDataItem(dispObj.location_id, ["locations"]);
            if (loc) {
                circleColor = this.UsersColor[dispObj.location_id % this.UsersColor.length];
                if (loc.color) {
                    circleColor = loc.color;
                }
            }
        }


        let assocUsers = dispObj.assocUsers.map(obj => {
            return `<span class='numberCircle' style='background-color:${circleColor}'  >${obj.name.substring(0, 2)}</span>`;
        }).join("");

        if (this.printScreenMode) {
            assocUsers = dispObj.assocUsers.map(obj => obj.name).join(" ");
        }


        if ((!assocUsers && circleColor)) {
            assocUsers = `<span class='numberCircle' style='background-color:${circleColor}'  ></span>`;
        }


        const cc = this.store.getCliniqDataOrExit();
        if (cc?.only_therapist_id) {
            assocUsers = "";
            if (!cc?.locations_only) {
                dispObj.location = "";
            }
        }


        let title = ""
        if (!this.printScreenMode) {
            title = dispObj.title
            if (!title) {
                title = '';
            }

        }

        const color = ev.extendedProps?.dbObj?.isInVac || ev.extendedProps?.dbObj?.takes_place == "no" ? "#7a7a7a" : dispObj.patientIconColor;

        if (this.eventsService.isMobile && this.permsService.owner_has_users && this.permsService.perms?.administrative && !cc?.only_therapist_id) {
            let html = `
			<div class="event-square"   >
				<span style="color:${color}; " class="low_opacity">
        ${assocUsers}
        ${(dispObj.location && this.currentView() !== "resourceTimeGridDay") ? `<span style="font-size: 12px">${dispObj.location}</span>` : ""}

				</span>
			</div>
		`;

            return {html};
        }
        // if(this.permsService.owner_has_users && this.permsService.perms?.administrative && !cc?.only_therapist_id){
        //   let html = `
        // 	<div class="event-square"   >
        // 		<span style="color:${color}; " class="low_opacity">
        //     ${assocUsers}
        //     <span class="event-hours">${timeDisplay || dispObj.datePart}</span>
        //     ${(dispObj.location && this.currentView()!=="resourceTimeGridDay")?`<span style="font-size: 12px">${dispObj.location}</span>`:""}
        // 		</span>
        // 	</div>
        // `;
        //
        // return { html };
        // }

        // ${ev.extendedProps?.dbObj?.id}
        let html = `
			<div class="event-square"   >
				<span style="color:${color}; " class="low_opacity">
            ${assocUsers}


			<span class="event-hours">${timeDisplay || dispObj.datePart}</span>

 <i class="type-icon ${this.lang.getConfigVal("icon." + dispObj.iconCls)}"></i>
${title}
					${dispObj.seriesIcon ? `<i class="${this.lang.getConfigVal("icon." + dispObj.seriesIcon)}"></i>` : ""}

					${dispObj.holidayIcon ? `<i class="${this.lang.getConfigVal("icon." + dispObj.holidayIcon)}"></i>` : ""}

					${(dispObj.location && this.currentView() !== "resourceTimeGridDay") ? `<span style="font-size: 12px">${dispObj.location}</span>` : ""}

				</span>
			</div>
		`;


        return {html};
    }

    calMove(funcName: "today" | "prev" | "next") {
        this.resetFirstCall();
        this.calendar.getApi()[funcName]();
        this.urlDate = this.getCurrentCalDate();
        this.replaceUrl();
    }

    goToDate(value: string) {
        this.resetFirstCall();
        this.urlDate = value;
        this.replaceUrl();
        this.calendar.getApi().gotoDate(value);
    }

    changeView(newView: string) {
        if (!this.calendar.getApi()) {
            return;
        }

        this.onlyResourceId = null;
        this.currentView.set(newView);
        this.resetFirstCall();
        this.calendar.getApi().changeView(newView);
        this.urlView = this.fcViewToUrlView[newView];
        this.store.lastCalView=this.urlView;
        this.replaceUrl();
    }

    replaceUrl() {
      if (this.isGuest || this.isHome) {
          return;
      }
      const path=location.pathname.split("/")[1];
      this.router.navigateByUrl(`/${path}/${this.urlView}/${this.urlDate}`, {replaceUrl: true});
    }

    dayClick(arg: any) {
        this.goToDate(moment.utc(arg).format(ConfigDefinitions.momentDateFormat));
        this.changeView(this.permsService?.owner_has_users ? 'resourceTimeGridDay' : 'timeGridDay');
    }


    async prepOpenMeetingModal(time: string, optionsObject: any = {}) {

        return await this.openMeetingModal(optionsObject);
    }

    async openMeetingModal(optionsObject: any) {
        if (this.isAddMeetingOpen) {
            return null;
        }
        this.isAddMeetingOpen = true;
        const ret = await this.modalService.openMulti("add-meeting", optionsObject);
        this.isAddMeetingOpen = false;
        return ret;

    }

    async handleEventResize(arg: any) {
      if(this.isGuest){return}
        if (arg.event.extendedProps?.isTa) {
            return
        }

        if (arg.event?.extendedProps?.dbObj?.is_meeting_request) {
            return this.getFollowupseriesFromStore();
        }

        let minutes = (arg.event.end.getTime() - arg.event.start.getTime()) / (1000 * 60);
        let dbObj = arg.event?.extendedProps?.dbObj || null;

        if (dbObj?.id) {
            if (this.preventDueToPerms(dbObj)) {
                return this.getFollowupseriesFromStore();
            }
        }

        this.hoverObj = null;
        const retObj: any = await this.prepOpenMeetingModal(arg.event?.extendedProps?.dbObj?.date, {
            inputDate: null,
            inputMinutes: minutes,
            isUpdateMode: true,
            followupserie: dbObj,
            isFormSubmittable: true
        });
        this.onReturnFromEditMeeting(retObj);
    }

    addCurrentDate(obj: any) {
        if (obj) {
            obj.currentDate = this.getCurrentCalDate();
        }
        return obj;
    }

    getCurrentCalDate() {
        return moment.utc(this.calendar.getApi().view.activeStart).format(ConfigDefinitions.momentDateFormat);
    }


    hasPatientOrUser(obj: any) {
        return obj?.user_id || obj?.patient_id;
    }

    async handleEventClick(arg: any) {
      if(this.isGuest){return}
        if (arg.event?.extendedProps?.dbObj?.is_meeting_request) {
            const mr = arg.event?.extendedProps?.dbObj?.meeting_request;
            const dateObj = {date: new Date(mr.date + " " + mr.time + "Z")};

            return this.handleDateClick(dateObj, mr);
        }
        if (arg.event.extendedProps?.isTa) {
            return
        }
        let dbObj = arg.event?.extendedProps?.dbObj || null;

        if (dbObj?.id) {
            if (this.preventDueToPerms(dbObj)) {
                return;
            }
        }
        // if (dbObj.isInVac) { return }

        if (!this.isHome || dbObj.isInVac || !this.hasPatientOrUser(dbObj)) {
            this.hoverObj = null;

            const retObj: any = await this.prepOpenMeetingModal(arg.event?.extendedProps?.dbObj?.date, {
                inputDate: null,
                isUpdateMode: true,
                followupserie: dbObj,
                dayEventsAll: this.dayEventsAll,
            });
            this.onReturnFromEditMeeting(retObj)
        } else {
            this.outputActionFunc(dbObj);

        }
    }

    preventDueToPerms(fus: any) {
        const fusIds = this.store.getAssocFusIdsForUser();
        const fusAssoc = this.store.getFusSpreadAssocIds(fus);
        return this.permsService.owner_has_users && !this.permsService.perms?.administrative && (!this.permsService.perms?.clinical || fusAssoc.length != 1 || !fusIds.includes(String(fus.id)));
    }


    async handleDateClick(arg: any, meeting_request: any = null, creatingObj: any = null) {
      if(this.isGuest){return}

        if (!this.calendar.getApi()) {
            this.modalService.openToast("1");//this.lang.getVal("an_error_occured_please_reload"));
            return;
        }

        if (this.permsService.owner_has_users && (!this.permsService.perms?.clinical && !this.permsService.perms?.administrative)) {
            return;
        }


        if (!this.permsService.owner_has_users || this.cliniqData?.only_therapist_id) {	//can't have 2 fuses at the same time, so if a fus is already around the clicked time - push the start time forward to afdter the fus's end (plus margin)

            const prevMeeting = this.calendar.getApi().getEvents()
                .filter(ev => ev.extendedProps?.dbObj?.followuptype_id != 5)    //vacations are out!
                .filter(ev => !ev.extendedProps?.isTa)    //ta are out are out!
                .filter(ev => !ev.extendedProps?.dbObj?.is_meeting_request)    //mrs are out are out!
                .filter(ev => ev.end <= arg.date || ev.start <= arg.date)    //starts or ends earlier than the clicked moment
                .reduce((acc, ev) => {	//choose the meeting by which one ENDS later
                    if (!acc) {
                        return ev
                    }
                    return (acc.end > ev.end ? acc : ev);
                }, null);
            // const nextMeeting = this.calendar.getApi().getEvents().filter(ev => ev.start >= arg.date).reduce((acc, ev) => {
            // 	if (!acc) { return ev }
            // 	return (acc.start < ev.start ? acc : ev);
            // }, null);

            const neededMargin = this.cliniqData.break_length * 60 * 1000;

            if (prevMeeting && prevMeeting.end && arg && arg.date && (prevMeeting.end.getTime() + neededMargin) > arg.date.getTime()) {
                arg.date = new Date(prevMeeting.end.getTime() + neededMargin);
            }
        }

        let fieldsToUpdate: any = {
            inputDate: arg.date,
            location_id: arg?.resource?.id,
            userId: this.currentUserId,
            meeting_request
        };
        if (creatingObj) {
            fieldsToUpdate = {inputDate: arg.date, creatingObj}
        }
        if (this.locationTagsObj.ids) {
            fieldsToUpdate.locationIds = [...this.locationTagsObj.ids];
        }


        const retObj: any = await this.prepOpenMeetingModal(arg.date, fieldsToUpdate);

        this.onReturnFromAddMeeting(retObj);
        // let id=(100+Math.floor(Math.random()*10000000)).toString();
        // this.calendar.getApi().addEvent({date:arg.dateStr,id,title:'title '+id});
    }

    handleFusRetObj(retObj: FusCrudReturnObj) {
        // this.store.lastUpdateTime = null;
        if (retObj.add) {
            this.store.addFus(retObj.add);
        }
        if (retObj.update) {
            this.store.updateFus(retObj.update.id, retObj.update);
        }
        if (retObj.remove) {
            this.store.removeFus(retObj.remove);
        }
        if (retObj.exceptions) {
            this.store.addFusExceptionArr(retObj.exceptions);
        }
        if (retObj.followups) {
            //get date (today -2)
            //in store - override all follouwps from date
            this.store.overrideRecentFollowups(retObj.followups);
        }
    }


    async onReturnFromEditMeeting(retObj: FusCrudReturnObj) {
        if (retObj?.res1) {
            await this.onReturnFromEditMeeting(retObj.res1);
            await this.onReturnFromEditMeeting(retObj.res2);
            return;
        }


        if (!retObj) {

            await this.getFollowupseriesFromStore();
            this.outputActionFunc();
            // this.refreshLastEv.emit(true);
            return;
        }


        this.handleFusRetObj(retObj);
        //cancel - null
        //update - fus
        //delete -


        await this.getFollowupseriesFromStore();


        if (this.hasPatientOrUser(retObj.add) && !retObj.add.isInVac && retObj.add.date == this.getCurrentCalDate()) {
            this.outputActionFunc(retObj.add);
        } else {
            this.outputActionFunc();
            // this.refreshLastEv.emit(true);
        }

        // const ev=retObj.add || retObj.update;
        // if(ev){
        // }

        // this.changePeriod();
    }

    async onReturnFromAddMeeting(retObj: FusCrudReturnObj = null) {

        if (!retObj) {
            return;
        }
        this.handleFusRetObj(retObj);
        if (retObj.add?.patient) {
            this.store.addGenItemRow("patients", this.store.removeDecItem(retObj.add.patient));
        }
        if (retObj.add?.contact) {
            this.store.addGenItemRow("contacts", this.store.removeDecItem(retObj.add.contact));
        }
        // if(retObj.add?.group){
        // 	this.store.addGenItemRow("patients",this.store.removeDecItem(retObj.add.group));
        // }

        await this.getFollowupseriesFromStore();


        if (this.hasPatientOrUser(retObj.add) && !retObj.add.isInVac && retObj.add.date == this.getCurrentCalDate()) {
            this.outputActionFunc(retObj.add);
        } else {
            this.outputActionFunc();
            // this.refreshLastEv.emit(true);
        }

        if (retObj.add && retObj.add?.is_group == "yes" && retObj.add?.is_new_patient_or_contact == "yes") {
            this.modalService.openMulti("update-group", {group: this.store.removeDecItem(retObj.add.patient)});

        }

    }

    async handleEventDrop(arg: any) {
      if(this.isGuest){return}
        if (arg.event.extendedProps?.isTa) {
            return
        }
        if (arg.event?.extendedProps?.dbObj?.is_meeting_request) {
            return this.getFollowupseriesFromStore();
        }
        let dbObj = arg.event?.extendedProps?.dbObj || null;

        if (dbObj?.id) {
            if (this.preventDueToPerms(dbObj)) {
                return this.getFollowupseriesFromStore();
            }
        }

        // if(!this.isHome){
        this.hoverObj = null;


        const retObj: any = await this.prepOpenMeetingModal(arg.event?.extendedProps?.dbObj?.date, {
            inputDate: arg.event.start,
            isUpdateMode: true,
            isDuplicate: true,
            followupserie: dbObj,
            isFormSubmittable: true,
            location_id: arg?.newResource?.id,
        });
        this.onReturnFromEditMeeting(retObj);
    }

    evMouseEnter(arg: any) {
        if (arg.event.extendedProps?.isTa) {
            return
        }

        let rect = this.container.nativeElement.getBoundingClientRect();
        const rectForMeeting = arg.jsEvent.target.getBoundingClientRect();
        // const bottomRightCorner = { x: 0, y: 0 }
        const bottomRightCorner = {
            x: rectForMeeting.left + window.pageXOffset,
            y: rectForMeeting.top + rectForMeeting.height + window.pageYOffset
        };	//rectForMeeting.width +
        if (this.isHome) {
            // bottomRightCorner.x = 0;
            // bottomRightCorner.y -= 86;
        } else {
            // if (bottomRightCorner.x > window.innerWidth / 2) {
            // 	bottomRightCorner.x = rectForMeeting.left + window.pageXOffset;
            // }
        }
        //

        let ex = (this.isHome ? 0 : 120);
        // let x = arg.jsEvent.clientX - rect.x + 10;
        // let y = arg.jsEvent.clientY - rect.y + 10 + ex;
        let x = bottomRightCorner.x;
        let y = bottomRightCorner.y;
        if (window.innerWidth - 300 < x) {
            x = window.innerWidth - 300;
        }
        let dispObj = arg.event.extendedProps.display;
        dispObj.followup_description = "";
        const dbObj = arg.event.extendedProps.dbObj;
        if (!this.isGuest && this.hasPatientOrUser(dbObj)) {

            const fusIds = this.store.getAssocFusIdsForUser();

            if (!this.permsService.owner_has_users || (this.permsService.perms?.clinical && fusIds.includes(String(dbObj.id)))) {

                const person_id = this.hasPatientOrUser(dbObj);
                const fieldname = dbObj.patient_id ? "patient_id" : "user_id";


                const followup = this.store.getFollowupObjectForSeries(person_id, dbObj.id, moment.utc(arg.event.start).format(ConfigDefinitions.momentDateFormat), fieldname);
                let desc = followup?.description || "";
                if (desc) {
                    desc = this.store.normalizeHtml(desc, 100);
                }
                dispObj.followup_description = desc;
            }

        }
        dispObj.assocUserNames = dispObj.assocUsers.map(it => it.name).join(", ");
        const cc = this.store.getCliniqDataOrExit();

        if (cc?.only_therapist_id) {
            dispObj.assocUserNames = "";
            if (!cc?.locations_only) {
                dispObj.location = "";
            }
        }

        if (arg.event.extendedProps?.dbObj?.is_meeting_request) {
            const obj = arg.event.extendedProps?.dbObj;
            dispObj.remarks = obj.remarks;
            dispObj.isNew = obj.isNew;
            dispObj.is_meeting_request = true;
        }

        this.hoverObj = {dispObj, x, y};
    }


    evMouseLeave(arg: any) {
        this.hoverObj = null;
    }

    resetFirstCall() {
        this.firstApiCallHappened = false;
    }

    ngOnDestroy() {
        if (this.fusSubscription) {
            this.fusSubscription.unsubscribe();
        }
        for (let subs of this.subjectSubs) {
            subs.unsubscribe();
        }
    }

    async changePeriod(arg: any = null) {
        if (this.fusSubscription) {
            this.fusSubscription.unsubscribe();
        }
        if (!this.calendar.getApi()) {
            // this.modalService.openToast("2");//this.lang.getVal("an_error_occured_please_reload"));
            return;
        }
        let view = this.calendar.getApi().view;

        this.periodTitle = view.title;

        if (this.permsService?.owner_has_users && view.type == "resourceTimeGridDay") {
            const realDay = moment(view.activeStart);
            // realDay.add(1,"d");

            this.periodTitle += " " + this.lang.getVal(realDay.format('dddd'));
        }
        this.hoverObj = null;


        this.calendar.getApi().batchRendering(() => {
            this.calendar.getApi().getEvents().forEach(ev => ev.remove());
        });
        this.loadingIsTrue.emit();
        this.dbCallSubj.next(true);


    }

    evGetColor(fus: any, doSoften: boolean = false) {

        const alpha = (doSoften ? "33" : "D9");
        const cc = this.store.getCliniqDataOrExit();
        if (fus.followuptype_id == 6 || fus.followuptype_id == 4) {
            return this.lang.getConfigVal("color.message") + alpha;
        }
      // to check here if there is userColorHex - and if it is with user put the colorHex
        let color = this.lang.getConfigVal("color.meeting");
        if (fus.meeting_with == "contact") {
            color = this.lang.getConfigVal("color.contact");
        // } else if (fus.meeting_with == "user") {
        //     color = this.lang.getConfigVal("color.user");
        } else if (fus.patient_id || fus.meeting_with == "user") {
            color = this.lang.getConfigVal("color.user");
            if (this.permsService.owner_has_users && !cc?.only_therapist_id && this.permsService?.perms?.administrative) {
                if (fus?.assoc_sub_user_ids?.length) {
                    const firstAssoc = Number(fus.assoc_sub_user_ids[0]);
                    const userColorHex = this.store.searchSiteDataItem(firstAssoc, ["sub_users"])?.color_hex;
                    const userColorId = this.store.searchSiteDataItem(firstAssoc, ["sub_users"])?.color;
                    if (userColorId && userColorId > 28) {
                        userColorId - 28;
                    }
                    if (userColorHex) {
                        color = userColorHex
                    } else if (userColorId) {
                        color = this.UsersColor[userColorId];
                    } else {
                        color = this.UsersColor[firstAssoc % this.UsersColor.length];
                    }

                }

            } else {
                color = this.PatientColor[fus.patient_id % this.PatientColor.length];
            }

        }

        return color + alpha;
    }

    async getFollowupseriesFromStore() {
        if (!this.calendar?.getApi()) {
            return;
        }

        const cc = this.store.getCliniqDataOrExit();


        // if (!this.calendar?.getApi().getResources()?.length) {
        this.calendar.getApi().getResources().forEach(r => r.remove());
        let locations = this.store.getSiteDataTable("locations");

        if (this.printScreenMode) {
          locations = locations.filter(loc => loc.is_conflicting == "yes");
        }
        if (this.locationTagsObj.hasAny) {
          locations = locations.filter(loc => this.locationTagsObj.ids.includes(loc.id));
        }
        if (this.onlyResourceId) {
          locations = locations.filter(loc => loc.id == this.onlyResourceId);
        }
        locations.sort((a, b) => a.name.localeCompare(b.name));

        locations.forEach((loc, i) => {
            this.calendar.getApi().addResource({id: loc.id, title: loc.name, ord: i + 1, color: loc?.color});
        });
        if (cc?.show_allocation == "yes") {
            this.calendar.getApi().addResource({id: "0", title: this.lang.getVal("no_allocation"), ord: 0, color: ""});
        }

        let view = this.calendar.getApi().view;

        let obj = {
            // start: view.currentStart.getTime() / 1000,
            // end: view.currentEnd.getTime() / 1000,
            start: view.activeStart.getTime() / 1000,
            end: view.activeEnd.getTime() / 1000,
            is_home: this.isHome
        };




        let fusInstanceArr = await this.getFusInRange(obj.start, obj.end);
        const allInstances = [...fusInstanceArr];


        if (this.permsService?.owner_has_users) {

            fusInstanceArr = fusInstanceArr.filter(fus => {
                if (fus.vacation_all_users == "yes") {
                    return true;
                }

                if (!this.userTagsObj.hasAny) {
                    return true;
                }
                const fusUserIds = this.store.getFusSpreadAssocIds(fus);
                const intersectingUserId = this.userTagsObj.ids.find(subUserId => fusUserIds.includes(String(subUserId)));
                return !!intersectingUserId;
            });
        }


        if (this.currentUserId) {
            fusInstanceArr = fusInstanceArr.filter(fus => this.store.getFusSpreadAssocIds(fus).includes(String(this.currentUserId)) || fus.vacation_all_users == "yes" || fus.user_id == this.currentUserId);
        }

        if (this.locationTagsObj.hasAny) {
            fusInstanceArr = fusInstanceArr.filter(fus => this.locationTagsObj.ids.includes(fus.location_id));
        }

        const isDayResourceDisplay = view.type == "resourceTimeGridDay";


        if (!this.permsService?.perms?.all_patients_all_fus) {
            //if MY perms have clinical BUT NOT all_patients_all_fus - if display is resourceTimeGridDay
            const canSeeFusesNoAdmin = this.permsService.owner_has_users && this.permsService?.perms?.clinical && isDayResourceDisplay;
            if (!canSeeFusesNoAdmin) {
                const myId = this.store.getCliniqDataOrExit()?.user_id;
                fusInstanceArr = fusInstanceArr.filter(fus => this.store.getFusSpreadAssocIds(fus).includes(String(myId)) || fus.user_id == myId || fus.vacation_all_users == "yes");
            }

        }
        if (!cc?.only_therapist_id && this.isHome && this.permsService.owner_has_users) {
            //only keep fuses that I am assoc with
            const userFusIds = this.store.getAssocFusIdsForUser();
            fusInstanceArr = fusInstanceArr.filter(fus => {	//return true means I'm still here, false means I'm out
                //see: fuses that I'm (or a group I'm part of) assoc with
                return userFusIds.includes(String(fus.id));
            })

        }
        let events = fusInstanceArr.map(fusInstance => this.fusInstanceToFCEvent(fusInstance));

        events.forEach(ev => {
        });
        if (!this.calendar.getApi()) {
            // this.modalService.openToast("2");//this.lang.getVal("an_error_occured_please_reload"));
            return;
        }

        let timeAllocationEvents = [];
        if (this.currentUserId || isDayResourceDisplay) {
            let timeAllocations = this.store.getSiteDataTable("time_allocations");

            const firstDay = moment.utc(this.calendar.getApi().view.currentStart);
            if (isDayResourceDisplay) {
                timeAllocations = timeAllocations.filter(ta => ta.day == firstDay.day());
            }
            if (this.currentUserId) {
                timeAllocations = timeAllocations.filter(ta => ta.user_id == this.currentUserId);
            }
            timeAllocationEvents = timeAllocations.map(ta => {

                const locations = this.store.getSiteDataTable("locations");


                const color = "#ccc"; //"#ffefe8";//this.PatientColor[ta.user_id % this.PatientColor.length];
                const day = moment.utc(firstDay);
                if (!isDayResourceDisplay) {
                    day.add(ta.day, "days");
                }
                const start = new Date(day.format(ConfigDefinitions.momentDateFormat) + " " + ta.time + "Z");
                const end = new Date(day.format(ConfigDefinitions.momentDateFormat) + " " + ta.time_to + "Z");
                const location = locations.find(it => it.id == ta.location_id);
                const userName = this.store.searchSiteDataItem(ta.user_id, ["sub_users"])?.name;
                const title = !isDayResourceDisplay ? (location?.name || this.lang.getVal("no_location")) : userName;

                return {
                    id: "ta-" + ta.id,
                    start,
                    end,
                    title,
                    display: "background",
                    color,
                    extendedProps: {isTa: true},
                    resourceId: ta.location_id
                    // editable: !fusInstance.isInVac
                }

            })

        }

        let allOccupiedEvents = [];
        if (this.currentUserId) {
            //find times when all these rooms are occupied
            const conflictingLocations = this.store.getSiteDataTable("locations").filter(it => it.is_conflicting == "yes");
            if (conflictingLocations.length) {
                const firstLocationId = conflictingLocations[0].id;
                const allOtherLocationIds = conflictingLocations.filter(loc => loc.id != firstLocationId).map(loc => loc.id)

                //get all fuses that takes_place="yes"
                const takesPlaceFuses = allInstances.filter(f => f.takes_place == "yes" && f.location_id);

                //get all fuses of the first location
                const firstLocationFuses = takesPlaceFuses.filter(f => f.location_id == firstLocationId);
                if (firstLocationFuses.length) {
                    for (let firstLocationFus of firstLocationFuses) {
                        let time = firstLocationFus.time;
                        let timeTo = firstLocationFus.time_to;


                        let locationWasNotFound = false;
                        //for every other location - try to find a fus with that location that has correlating start/end
                        for (let locId of allOtherLocationIds) {
                            const locFuses = takesPlaceFuses.filter(f => f.location_id == locId && f.start_day == firstLocationFus.start_day);

                            let overlapFound = false;
                            for (let locFus of locFuses) {
                                //are location in any overlap?
                                const doFusesOverlap = this.overlapService.doFusesOverlap(time, timeTo, locFus.time, locFus.time_to);

                                if (doFusesOverlap) {
                                    //if so - change time to the later of the 2 and timeTo to the earlier
                                    if (this.store.timeToMoment(time).isBefore(this.store.timeToMoment(locFus.time))) {
                                        time = locFus.time;
                                    }
                                    if (this.store.timeToMoment(timeTo).isAfter(this.store.timeToMoment(locFus.time_to))) {
                                        timeTo = locFus.time_to;
                                    }
                                    overlapFound = true;
                                    break;
                                }

                            }

                            if (!overlapFound) {
                                //if not found - fail and return

                                locationWasNotFound = true;
                                break;
                            }

                            //how to deal with 10:00-10:50 && 10:30-11:20


                        }
                        if (locationWasNotFound) {
                            continue;
                        }
                        //all locations were found:

                        allOccupiedEvents.push({time, timeTo, date: firstLocationFus.date});


                    }
                }
            }

        }

        allOccupiedEvents = allOccupiedEvents.map(times => {
            const color = "#F51720";//this.PatientColor[ta.user_id % this.PatientColor.length];
            const start = new Date(times.date + " " + times.time + "Z");
            const end = new Date(times.date + " " + times.timeTo + "Z");
            const title = this.lang.getVal("occupied");
            return {
                start,
                end,
                title,
                display: "background",
                color,
                borderColor: color,
                extendedProps: {isTa: true},
            }
        });

        let taOccupiedEvents: any[] = [];
        if (this.currentUserId && timeAllocationEvents.length) {

            //find all ta blocks that have a location that is_conflicting=="yes"
            const taConflictingEvs = timeAllocationEvents.filter(ta => {
                const loc = this.store.searchSiteDataItem(ta.resourceId, ["locations"]);
                return loc?.is_conflicting == "yes";
            });

            //iterate the ta blocks
            for (let taConflictingEv of taConflictingEvs) {
                //get all instances (NON USER SPECIFIC) with same day and location
                const locationId = taConflictingEv.resourceId;
                const mom = moment.utc(taConflictingEv.start);
                const dayOfWeek = mom.day();
                const time = mom.format("HH:mm");
                const timeTo = moment.utc(taConflictingEv.end).format("HH:mm");
                const sameLocationFuses = allInstances.filter(f => f.takes_place == "yes"
                    && f.location_id == locationId
                    && f.start_day == dayOfWeek
                    && !this.store.getFusSpreadAssocIds(f).includes(String(this.currentUserId)));

                //go over sameLocationFuses
                for (let sameLocationFus of sameLocationFuses) {
                    //see if location has some time overlap

                    const doFusesOverlap = this.overlapService.doFusesOverlap(time, timeTo, sameLocationFus.time, sameLocationFus.time_to);
                    if (doFusesOverlap) {
                        const times = {
                            time: sameLocationFus.time,
                            timeTo: sameLocationFus.time_to,
                            date: sameLocationFus.date,
                            location_id: sameLocationFus.location_id
                        };
                        if (this.store.timeToMoment(times.time).isBefore(this.store.timeToMoment(time))) {
                            times.time = time;
                        }
                        if (this.store.timeToMoment(times.timeTo).isAfter(this.store.timeToMoment(timeTo))) {
                            times.timeTo = timeTo;
                        }

                        taOccupiedEvents.push(times);
                    }
                }
            }
        }


        taOccupiedEvents = taOccupiedEvents.map(times => {
            const color = "#f7f2ff";//this.PatientColor[ta.user_id % this.PatientColor.length];
            const start = new Date(times.date + " " + times.time + "Z");
            const end = new Date(times.date + " " + times.timeTo + "Z");
            const title = this.lang.getVal("occupied");
            return {
                start,
                end,
                title,
                display: "background",
                color,
                borderColor: color,
                extendedProps: {isTa: true},
                resourceId: times.location_id
            }
        });


        this.calendar.getApi().getEventSources().forEach(ev => ev.remove());
        this.calendar.getApi().addEventSource([...events, ...timeAllocationEvents, ...allOccupiedEvents, ...taOccupiedEvents]);
        // this.calendar.getApi().addEventSource(events);


        let eventsWithPatientOrUserId = events.filter(it => this.hasPatientOrUser(it.extendedProps.dbObj) && !it.extendedProps.dbObj.isInVac);

        eventsWithPatientOrUserId.sort((a, b) => a.start > b.start ? 1 : -1);
        this.dayEvents = eventsWithPatientOrUserId.map(it => this.addCurrentDate(it.extendedProps.dbObj));
        this.dayEventsAll = events.filter(it => !it.extendedProps.dbObj.isInVac).map(it => this.addCurrentDate(it.extendedProps.dbObj));

        if (!this.firstApiCallHappened) {
            this.firstApiCallHappened = true;
        }
        //  });

        const dateFrom = moment.utc(view.activeStart).format(ConfigDefinitions.momentDateFormat);
        const dateTo = moment.utc(view.activeEnd).format(ConfigDefinitions.momentDateFormat);

        let type = (view.type as any);
        if (type == "resourceTimeGridDay") {
            type = "timeGridDay";
        }

        if (!this.isGuest && (this.permsService?.perms?.administrative || this.permsService?.perms?.clinical)) {
            await this.store.getFollowupsForPeriod(dateFrom, dateTo, type);
        }


        if (!this.calendar.getApi()) {
            // this.modalService.openToast("2");//this.lang.getVal("an_error_occured_please_reload"));
            return;
        }

        this.calendar.getApi().getEvents().forEach(ev => {
            if (ev.extendedProps?.isTa) {
                return
            }

            const dbObj = ev.extendedProps.dbObj;

            const id = dbObj.id;
            const date = ev.start;


            if (dbObj.isInVac || dbObj?.takes_place == "no") {
                const col = this.evGetColor(dbObj, true);
                ev.setProp("color", col)

            }

            if (!this.isGuest && this.hasPatientOrUser(dbObj)) {
                const person_id = this.hasPatientOrUser(dbObj);
                const fieldname = dbObj.patient_id ? "patient_id" : "user_id";
                const followup = this.store.getFollowupObjectForSeries(person_id, id, moment.utc(date).format(ConfigDefinitions.momentDateFormat), fieldname);

                if (followup && followup.followuptype_id == 1 && followup.arrived != "yes") {
                    const col = this.evGetColor(dbObj, true);
                    ev.setProp("color", col)
                }
            }
        })
    }

    /////////////////

    fusInstanceToFCEvent(fusInstance: any) {
        let color = this.evGetColor(fusInstance);
        let borderColor = "#00000000";
        if (fusInstance.meetingtype_id) {
            const meetingType = this.store.getSiteDataTable("meetingtypes").find(it => it.id == fusInstance.meetingtype_id);
            if (meetingType?.color) {
                borderColor = meetingType.color;//'#FF0000';
            }
        }

        if (fusInstance?.is_meeting_request) {
            //color="#30D5C8";
            color = "yellow";
        }


        let ev = {
            id: fusInstance.id,
            start: new Date(fusInstance.instance_start_unix * 1000),
            end: new Date(fusInstance.instance_end_unix * 1000),
            title: this.getEvTitle(fusInstance),
            color,
            //  eventColor:this.PatientColor[ev.color],
            borderColor,
            //  backgroundColor:this.PatientColor[ev.color],
            extendedProps: {dbObj: fusInstance},
            editable: !fusInstance.isInVac,
            resourceId: fusInstance.location_id || 0,
            is_meeting_request: fusInstance?.is_meeting_request,
        };
        this.assignEvProps(ev);
        return ev;
    }


    async getFusInRange(fromDateSec: number, toDateSec: number) {
        const from = moment.utc(fromDateSec * 1000);


        const to = moment.utc(toDateSec * 1000);
        if (from >= to) {
            return []
        }

        const yearAgoTs=moment.utc().subtract(1,"year").unix();
        const doGetAll= fromDateSec < yearAgoTs;
        //isHome -
        const retObj: any = await this.store.calendarLoad(doGetAll);


        const meeting_requests = retObj?.meeting_requests || [];

        let followupseries = retObj.followupseries;

        const fusExceptions = retObj.fusExceptions;

        const possibleFus = followupseries.filter(fus => {
            if (fus.series_limit_date && moment.utc(fus.series_limit_date) < from) {	//2020/05/03
                return false;
            }
            return true;
        })


        //X -> Y (1,2,3,4,5,6,7)
        const possibleVacationFus = possibleFus.filter(fus => fus.followuptype_id == 5);

        let dates = {};	//each date -> arr for fus INSTANCES
        const vacationFus = [...possibleVacationFus];

        let currentDate = moment(from);
        while (currentDate < to) {
            let fusInstances = [];
            //if a vacation exists that started BEFORE currentDate and ends AFTER currentDate - continue;
            const todayVacation = vacationFus.find(vacFus => {
                return false;
                return moment(currentDate.format(ConfigDefinitions.momentDateFormat)).isBetween(vacFus.date, vacFus.date_to);
            });
            if (todayVacation) {
                currentDate.add(1, "day");
                continue;
            }


            //(currentDate.format());
            possibleFus.forEach(fus => {
                fus = {...fus};
                // limit date  on server
                // inserted date = series_limit_date = inserted date             end date included
                // update/delete =  series_limit_date = inserted date - 1     the limit date indicates the beginning of the new series


                if (fus.series_limit_date && moment.utc(fus.series_limit_date) < currentDate) {
                    return;
                }

                if (currentDate.isBefore(moment.utc(fus.date))) {
                    return;
                }	//get out

                if (fus.followuptype_id == 5) {	//VACATION!!
                    // if(!vacationFus.find(vacFus=>vacFus.id==fus.id)){
                    // 	vacationFus.push(fus);
                    // }
                    return;	//this fus will NOT be rendered
                }

                let exception = fusExceptions.find(fusException => fusException.series_id == fus.id && currentDate.isSame(moment.utc(fusException.date)));
                if (exception) {
                    return;
                }

                fus.ser_id = fus.id;


                switch (fus.series_repeat) {	//'one_time','series_daily','series_weekly','series_monthly','series_exception','series_2weekly'
                    case "one_time":
                        //start_unix (and end) are within range
                        if (fus.followuptype_id == 5) {	//holiday
                            if (moment.utc(fus.date_to).isSameOrAfter(currentDate)) {
                                fusInstances.push(fus);
                            }
                        } else {
                            if (currentDate.isSame(moment.utc(fus.date))) {
                                fusInstances.push(fus);
                            }
                        }
                        break;
                    case "series_daily":
                        fusInstances.push(fus);
                        break;
                    case "series_weekly":
                        if (currentDate.day() == fus.start_day) {
                            fusInstances.push(fus);
                        }
                        break;
                    case "series_monthly":	//every 4 weeks
                        // if(fus.id==312){
                        // }
                        if (currentDate.day() == fus.start_day) {
                            if (currentDate.diff(moment.utc(fus.date), "weeks") % 4 == 0) {
                                fusInstances.push(fus);
                            }
                        }
                        break;
                    case "series_2weekly":
                        if (currentDate.day() == fus.start_day) {
                            if (currentDate.diff(moment.utc(fus.date), "weeks") % 2 == 0) {
                                fusInstances.push(fus);
                            }
                        }
                        break;
                    case "series_3weekly":
                        if (currentDate.day() == fus.start_day) {
                            if (currentDate.diff(moment.utc(fus.date), "weeks") % 3 == 0) {
                                fusInstances.push(fus);
                            }
                        }
                        break;
                }


                //1-1-2021 + 13:30:00

            });

            const dateString = currentDate.format(ConfigDefinitions.momentDateFormat);

            for (let meeting_request of meeting_requests) {
              if(meeting_request.payment_only=="yes"){continue};
                if (meeting_request.date == dateString) {
                    if (meeting_request.meetingtype_id) {
                        const meetingtype = this.store.searchSiteDataItem(meeting_request.meetingtype_id, ["meetingtypes"]);
                        if (meetingtype) {
                            const time_to = moment.utc(meeting_request.date + " " + meeting_request.time).add(meetingtype.length, "minutes").format("HH:mm");
                            const obj = {
                                ...meeting_request,
                                id: "mr-" + meeting_request.id,
                                date_to: meeting_request.date,
                                time_to,
                                is_meeting_request: true,
                                meeting_request,
                            };

                            fusInstances.push(obj);
                        }
                    }
                }
            }


            //iterate fusInstances to see which ones are in conflict with vacations (conflict means the fus doesn't take place/is color alpha reduced)
            const ownerHasUsers = this.permsService.owner_has_users;
            fusInstances.forEach(fus => {

                if (fus.meeting_on_holiday == "yes") {
                    return true;
                }

                //FUS M7:00-M11:30
                //Vac M11:00-T10:00


                //if vacation exists that EITHER (this fus time OR this fus time_to is between the vacation's time->time_to) OR (the vacation time OR vacation time_to is between fus time->time_to)

                vacationFus.forEach(vac => {
                    if (ownerHasUsers) {	//if assoc check is needed

                        //only be able to dismiss this vac if no vacation_all_users
                        if (vac?.vacation_all_users != "yes") {

                            const vacAssocUserId = vac.assoc_sub_user_ids?.length ? vac.assoc_sub_user_ids[0] : 0;
                            if (!fus.assoc_sub_user_ids.includes(vacAssocUserId)) {	//if this vac assoc user ISN'T part of the fus assoc users - can't be isInVac
                                return;
                            }
                        }
                    }

                    const fusStart = moment.utc(`${currentDate.format(ConfigDefinitions.momentDateFormat)} ${fus.time}`);
                    const fusEnd = moment.utc(`${currentDate.format(ConfigDefinitions.momentDateFormat)} ${fus.time_to}`);
                    const vacStart = moment.utc(`${vac.date} ${vac.time}`);
                    const vacEnd = moment.utc(`${vac.date_to} ${vac.time_to}`);


                    //trap the fusStart
                    // if (fusStart.isBetween(vacStart, vacEnd, null, "[]")) { return true }
                    if (fusStart.isBetween(vacStart, vacEnd, null, "[]")) {
                        fus.isInVac = 1
                    }

                    //trap the fusEnd
                    // if (fusEnd.isBetween(vacStart, vacEnd, null, "[]")) { return true }
                    if (fusEnd.isBetween(vacStart, vacEnd, null, "[]")) {
                        fus.isInVac = 1
                    }

                    //trap the fusStart
                    // if (fusStart.isBetween(vacStart, vacEnd, null, "[]")) { return true }
                    if (vacStart.isBetween(fusStart, fusEnd, null, "[]")) {
                        fus.isInVac = 1
                    }

                    //trap the fusEnd
                    // if (fusEnd.isBetween(vacStart, vacEnd, null, "[]")) { return true }
                    if (vacEnd.isBetween(fusStart, fusEnd, null, "[]")) {
                        fus.isInVac = 1
                    }
                });
            });

            dates[currentDate.format(ConfigDefinitions.momentDateFormat)] = fusInstances;
            currentDate.add(1, "day");
        }


        const retArr = [];

        Object.keys(dates).forEach((date: any) => {
            const arr = dates[date];
            // date=moment(date);
            arr.forEach(fus => {
                let fusInstance = this.makeFusIntoInstance(fus, date);
                retArr.push(fusInstance);
            })
        })
        vacationFus.forEach(fus => {
            fus.ser_id = fus.id;
            fus.instance_start_unix = fus.start_unix;
            fus.instance_end_unix = fus.end_unix;
            fus.date_render = this.store.getMomentDisplay(fus.date);
            fus.date_to_render = this.store.getMomentDisplay(fus.date_to);
            fus.name = fus.meeting_title;

            // let fusInstance=this.makeFusIntoInstance(fus,date);
            retArr.push(fus);
        });

        return retArr;

    }


    makeFusIntoInstance(fus: FollowupserieSave, date: string) {

        const patients = this.store.getSiteDataTable("patients");
        const contacts = this.store.getSiteDataTable("contacts");


        let fusInstance: any = {...fus, name: (fus?.meeting_title || fus?.name)};
        fusInstance.is_group = "no";

        if (fusInstance.patient_id) {
            const patient = patients.find(p => p.id == fusInstance.patient_id);
            if (patient) {
                fusInstance.is_group = patient.is_group;

                fusInstance.name = patient?.name;
                fusInstance.color = patient?.color;
            }
        }
        if (fusInstance.contact_id) {
            fusInstance.name = contacts.find(p => p.id == fusInstance.contact_id)?.name || "";
        }
        if (fusInstance.user_id) {
            fusInstance.name = this.store.searchSiteDataItem(fusInstance.user_id, ["sub_users"])?.name || "";
        }
        fusInstance.instance_start_unix = fusInstance.start_unix;
        fusInstance.instance_end_unix = fusInstance.end_unix;
        if (fusInstance.series_repeat != "one_time") {
            fusInstance.instance_start_unix = moment.utc(date + " " + fusInstance.time).unix();
            fusInstance.instance_end_unix = moment.utc(date + " " + fusInstance.time_to).unix();
            fusInstance.date = date;
            fusInstance.date_to = fusInstance.date;
        }
        //2020-01-07
        // "2020-01-07"


        fusInstance.time_render = moment.utc(date + " " + fusInstance.time).format("HH:mm");
        fusInstance.time_to_render = moment.utc(date + " " + fusInstance.time_to).format("HH:mm");

        fusInstance.date_render = this.store.getMomentDisplay(fusInstance.date);
        fusInstance.date_to_render = this.store.getMomentDisplay(fusInstance.date_to);
        fusInstance.meeting_with = "meeting";
        if (fusInstance.patient_id) {
            fusInstance.meeting_with = "patient";
        }
        if (fusInstance.contact_id) {
            fusInstance.meeting_with = "contact";
        }
        if (fusInstance.user_id) {
            fusInstance.meeting_with = "user";
        }

        return fusInstance;
    }

    updSubUser(id: number) {
        this.currentUserId = id;
        this.getFollowupseriesFromStore();
        this.subUsersFuzzy.searchStr = this.store.getSiteDataTable("sub_users").find(it => it.id == id)?.name || "";
    }

    subUsersClick(obj: any = null) {
        this.updSubUser(obj?.id || 0);
    }

    subUserMove(dir: number) {
        let siteDataSubUsers: any = this.store.getSiteDataTable("sub_users");
        siteDataSubUsers = siteDataSubUsers.filter(it => this.userTagsObj.ids.includes(it.id));
        // if (this.userTagsObj.ids?.length) {
        // }
        const subUsers = [{id: 0}, ...siteDataSubUsers];
        const curIndex = subUsers.findIndex(it => it.id == this.currentUserId);

        let nextIndex = (subUsers.length + curIndex + dir) % subUsers.length;
        this.updSubUser(subUsers[nextIndex]?.id || 0);
    }

    openFollowupsList() {
        this.modalService.openMulti("followups-update-list");
    }

    resetSubUserIds() {
        this.userTagsObj.ids = this.store.getSiteDataTable("sub_users").map(it => it.id);
        this.userTagsObj.hasAny = false;
    }

    chooseTagsForLocations(tagObjs: any[]) {
        this.locationTagsObj.ids = [];
        this.store.fillObjForTags(tagObjs,this.locationTagsObj,"location");
        this.getFollowupseriesFromStore();
    }

    async chooseTagsForUsers(tagObjs: any[]) {
        this.resetSubUserIds();
        this.store.fillObjForTags(tagObjs,this.userTagsObj,"user");
        this.subUsersClick();
        await this.store.timeout(200);
        this.subUsersFuzzy.focus();
        this.subUsersFuzzy.clickInput();
    }

    openMeetingTimes() {
        this.modalService.openMulti("meeting-times");
    }
}

