import { Pipe, PipeTransform  } from '@angular/core';
import { StoreService } from '@app/services/store.service';

@Pipe({
	name: 'endDateNull'
})
export class endDateNullPipe implements PipeTransform {
	transform(arr: any[]) {
    return arr.filter(item => item.end_date == null);
	}
}
