import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
	selector: '[appModalDrag]'
})
export class ModalDragDirective {	//this directive adds event listeners to modals to enable drag/drop
	@Input() appModalDrag: any;
	constructor(private el: ElementRef) { }

	@HostListener('mouseup', ["$event"])
  onMouseUp(ev: MouseEvent) {	//uses the eventsService event
		this.appModalDrag.eventsService.dragMouseUp(ev);
	}

	@HostListener('mousedown', ["$event"])
  onMouseDown(ev: MouseEvent) {	//uses the eventsService event
		this.appModalDrag.headerMouseDown(ev);
	}

	@HostListener('click', ["$event"])
  onClick(ev: MouseEvent) {	//prevent clicks from interfering with drag functionality
		ev.stopImmediatePropagation();
	}



}
