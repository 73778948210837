import { Component, ElementRef, Input, OnInit, SimpleChange, ViewChild } from '@angular/core';
import { EventsService } from '@app/services/events.service';
import { BasicInputFormClass, TimeInpMode } from '@app/definitions/types';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { InputsService } from '@app/services/inputs.service';

@Component({
	selector: 'app-time-picker',
	templateUrl: './time-picker.component.html',
	styleUrl: './time-picker.component.css'
})
export class TimePickerComponent extends BasicInputFormClass {
	// @Input() timeStr:string="";
	baseHeight: number = 120;	//default height
	popupHeight: number = 120;	//popup dimensions
	popupWidth: number = 200;
	popupProps: any = {};	//object to retain the calculated dimensions from the popup
	@ViewChild("inpEl") inpEl: ElementRef;

	docClickSubscription: any;
	changeSubject = new Subject();
	mode: TimeInpMode = null;
	vals: string[] = [];


	hour: number = 0;
	minute: number = 0;
	isPopupOpen: boolean = false;	//isPopup currently visible
	prevFieldVal: string = "";

	constructor(private eventsService: EventsService, private inputsService:InputsService) { super() }


	ngOnInit() {
		this.inputsService.loadVarsToInput(this);

		if (this.actualObj[this.fieldName]) {
			this.updIfStrLegit();
		}
		this.updTimeStr(true);

		this.docClickSubscription = this.eventsService.docClickSubject.subscribe((val: any) => {
			if (val.src != this) {
				this.onLeave();
			}
		});

		this.changeSubject.pipe(
			debounceTime(3000),
			distinctUntilChanged()
		).subscribe(newText => {
			this.onLeave();
		});

	}
	ngOnChanges(changes: SimpleChange) {
		if (changes["actualObj"] && this.actualObj[this.fieldName] !== undefined && this.prevFieldVal != this.actualObj[this.fieldName]) {
			this.updIfStrLegit();
			this.updTimeStr(true);
		}
	}
	ngOnDestroy() {
		this.docClickSubscription.unsubscribe();
	}
	clickTop(ev: any) {
		ev.stopImmediatePropagation();
		this.eventsService.onDocClick({ src: this });
	}
	updTimeStr(avoidEmit: boolean = false) {
		let newStr = `${this.hour < 10 ? "0" + this.hour : this.hour}:${this.minute < 10 ? "0" + this.minute : this.minute}`;
		let doChange = (newStr != this.actualObj[this.fieldName] || this.prevFieldVal != newStr);
		this.actualObj[this.fieldName] = newStr;
		this.prevFieldVal = this.actualObj[this.fieldName];
		if (!avoidEmit && doChange) {
			this.changeEvent.emit(this.actualObj[this.fieldName]);
		}
	}
	updIfStrLegit() {
		let curStr = this.actualObj[this.fieldName];
		if (!curStr) { return }
		if (curStr.indexOf(":") == -1) {
			if (curStr.length > 2) {
				curStr = curStr.slice(0, -2) + ":" + curStr.slice(-2);
			}
			else {
				curStr += ":00";
			}
		}
		let arr = curStr.split(":");
		if (arr.length >= 2) {
			let hour = Number(arr[0]);
			let minute = Number(arr[1]);
			if (hour >= 0 && hour <= 23 && minute >= 0 && minute <= 59) {
				this.hour = hour;
				this.minute = minute;
			}
		}

	}
	move(type: string, isUp: boolean) {
		this[type] = this[type] + (isUp ? 1 : -1);
		let over = (type == "hour" ? 24 : 60);
		if (this[type] >= over) {
			this[type] = 0;
		}
		else if (this[type] < 0) {
			this[type] = over - 1;
		}
		this.updTimeStr();
	}
	onLeave() {
		this.updIfStrLegit();
		this.updTimeStr();
		this.isPopupOpen = false;
	}
	openPopup(ev: any) {
		this.mode = null;
		this.popupHeight = this.baseHeight;
		this.popupProps = this.eventsService.datePopUpProps(this.inpEl, this.popupHeight, this.popupWidth, ev);

		this.isPopupOpen = true;
		ev.stopImmediatePropagation();
		this.eventsService.onDocClick({ src: this });
	}
	onKeyDown(ev: KeyboardEvent) {
		if (ev.key == "Tab") {
			this.onLeave();
		}
		else {
			this.changeSubject.next((ev as any).target.value);
		}
	}

	chMode(mode: TimeInpMode) {
		this.mode = mode;
		const step = (mode == "h" ? 1 : 5);
		const end = (mode == "h" ? 24 : 60);
		this.vals = [];
		for (let i = 0; i < end; i += step) {
			this.vals.push(String(i < 10 ? "0" + i : i));
		}
		this.popupHeight = Math.ceil(48 * this.vals.length / 4);
	}
	chVal(val: string) {
		this[(this.mode == "h" ? "hour" : "minute")] = Number(val);
		this.isPopupOpen = false;
		this.updTimeStr();

	}


}
//if after X milli no new manual insert - run onLeave
