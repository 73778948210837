import { Component, OnInit } from '@angular/core';
import { CcParamsGen } from '@app/cc-params-gen/cc-params-gen';

@Component({
  selector: 'app-cc-params-switch',
  templateUrl: './cc-params-switch.component.html',
  styleUrl: './cc-params-switch.component.css'
})
export class CcParamsSwitchComponent extends CcParamsGen{
  saveObjName: string = "switches";	//which part to save while updating

  openItaGetAuth(){

    if(this.configcliniq.enable_israel_invoice=="yes"){
      this.modalService.openMulti("ita-get-auth");
    }


  }
}
