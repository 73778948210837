import { Component } from '@angular/core';
import { ApiService, AvailablePostPaths } from '@app/services/api.service';
import { StoreService } from '@app/services/store.service';

@Component({
  selector: 'app-import-common',
  templateUrl: './import-common.component.html',
  styleUrl: './import-common.component.css'
})
export class ImportCommonComponent{
  error: string = "";	//will hold errors for the fields in the form
  isFormSubmittable: boolean = false;	//can submit (controls disabled in the submit button)
  columns: {[key:string]: any} = {};
  table: any[] = [];
  fields = [];
  importFuncName:AvailablePostPaths="import_tags";
  headerLang:string="";
  hasWarning:boolean=false;
  hasDate:boolean=false;
  isBlocked:boolean=false;
  isUploadingXlsx:boolean=false;
  isSaving:boolean=false;

  constructor(public apiService: ApiService,public store: StoreService){}

  async uplFile(files: FileList = null) {
    let file: any = (files ? files.item(0) : "");
    if (file) {
      let ext = file.name.split(".").pop().toLowerCase();
      if (ext == "csv") {
        let reader = new FileReader();
        reader.readAsText(file, "utf8");
        await new Promise(resolve => {
          reader.onload = (e: any) => {
            let data = e.target.result.split("\n").map(row => row.split(","));
            this.table = data;
            this.colChosen();
            resolve(true);
          };
        });
      }
      else if (ext == "xlsx") {
        this.isUploadingXlsx=true;
        const res:any=await this.apiService.upload("parse_xlsx",{document: file});
        this.isUploadingXlsx=false;
        this.table = res.data;
        this.colChosen();
      }
      else {
        this.table = [];
      }

      if (this.table) {
        let maxCellsNum = 0;
        this.table.forEach(row => {
          maxCellsNum = Math.max(maxCellsNum, row.length);
        });
        this.columns = {};
        for (let i = 0; i < maxCellsNum; i++) {
          this.columns[this.leadingZeros(i)] = "";
        }

        // Array(maxCellsNum).fill("");
        //this.isFormSubmittable = true;
      }
    }
  }
  leadingZeros(n:any) {
    let len = String(n).length;
    return "0".repeat(5 - len) + n;
  }
  colChosen() {
    const nameCol = Object.values(this.columns).find(col => col == "name");
    this.error = (nameCol ? "" : "please_choose_name_column");
    this.isFormSubmittable = !!nameCol;
  }
  deleteRow(position: number) {
    this.table = this.table.filter((row, rowPos) => rowPos != position);
  }


  async import() {
    const data = [];
    this.table.forEach(row => {
      const dataRow = {}
      Object.keys(this.columns).forEach((key, pos) => {

        const col = this.columns[key];
        if (col) {

          dataRow[col] = row[pos];
        }
      })
      data.push({ row: dataRow })
    })

    this.isSaving=true;
    const res:any=await this.apiService.post(this.importFuncName,{data:JSON.stringify(data)});
    if(res?.tags){
      res.tags.forEach(tag=>{
        this.store.addGenItemRow("tags",tag);
      })
    }
    await this.store.timeout(500);
    this.isSaving=false;
    window.location.reload();
  }


}

@Component({
	selector: 'app-import-patients',
	templateUrl: './import-common.component.html',
  styleUrl: './import-common.component.css'
})
export class ImportPatientsComponent extends ImportCommonComponent {
	fields = ['', 'id', 'name', 'family_name', 'id_number', 'name_en', 'patienttype', 'therapist', 'payor', 'year_of_birth','date_of_birth','date_start_therapy', 'marital_status', 'gender', 'number_of_kids',
    'account_in_hashav','active', 'price', 'email', 'second_email', 'phone','address','city','tag', 'second_phone','bank_code','branch_id','number','referer',
    'contact_name1', 'contact_id1', 'contact_relation1', 'contact_phone1', 'contact_email1','contact_name2', 'contact_id2', 'contact_relation2', 'contact_phone2', 'contact_email2','contact_name3', 'contact_id3', 'contact_relation3', 'contact_phone3', 'contact_email3'];
  importFuncName: AvailablePostPaths="import_patients";
  headerLang:string="import_patients";
  hasWarning:boolean=true;
	//date_of_birth

}

@Component({
  selector: 'app-import-tags',
  templateUrl: './import-common.component.html',
  styleUrl: './import-common.component.css'
})
export class ImportTagsComponent extends ImportCommonComponent {
  fields = ['', 'name','lang','description'];
  importFuncName:AvailablePostPaths="import_tags";
  headerLang:string="import_tags";
}
