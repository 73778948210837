@if(configcliniq){
  <div>
    <h4 class="gen-title">{{"userdetails_website" | langVal}}</h4>

    @for(rowFields of rows;track rowFields){
      <div class="row">
        @for(field of rowFields;track field){
          <div [className]="'col-'+(field?.xs || clsDefaults.xs)+' col-md-'+(field?.md || clsDefaults.md)">
            <label>{{(field?.lang || field.name) | langVal}}</label>
            <app-gen-input [tabindex]="field?.tabIndex" [className]="(field.name=='email_web'?' email':'')"  [actualObj]="configcliniq" [errors]="errors" [fieldName]="field.name" [title_ph_lang]="(field?.lang || field.name)" (changeEvent)="validateAndSave()"></app-gen-input>
          </div>
        }
      </div>
    }

    <div class="row">
      <div class="col-4">
        <app-gen-file-input fileType="image"  [errors]="imageErrors" urlTemplate="/user-image"
                          [maxDims]="{width:1500,height:1500}" [actualObj]="configcliniq" fieldName="image"
                          (changeEvent)="uploadImage($event,false);" [isUploading]="imageUpl" />
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <app-gen-file-input fileType="image"  [errors]="imageErrors" urlTemplate="/user-logo"
                          [maxDims]="{width:1500,height:1500}" [actualObj]="configcliniq" fieldName="logo"
                          (changeEvent)="uploadImage($event,true);" [isUploading]="logoUpl" />
      </div>
    </div>

    <hr>
    <h4 class="gen-title">{{"extra_freeform_details" | langVal}}</h4>

    <app-userdetail-lines-manage />

  </div>
}
