<div class="container-fluid">
  <div style="line-height: 1.6;">
    <div class="LOGO" title="My-cliniq" style="text-align:left">

      <img src="https://www.my-cliniq.com/wp-content/uploads/2020/07/my-cliniq-logo.png" alt="My-cliniq">
    </div>
    <div>
      <div class="well well-lg" style="margin:100px 300px 500px;">
        <div>
          <h2>{{ "payment_successful" | langVal }}</h2>
          <br>
          <h3>{{ "payment_successful1" | langVal }}</h3>
          <br>
        </div>
      </div>
    </div>
    <div class="footer"></div>
  </div>
</div>

