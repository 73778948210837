import { LangService } from "@app/services/lang.service";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { environment } from "@env/environment";
import { ApiService } from "./api.service";
import { ModalService } from "./modal.service";
import { StoreService } from "./store.service";
import { Subject } from "rxjs";


@Injectable({
  providedIn: "root",
})
export class ErrorService {
  prevErrors: any = []; //holds previous errors for comparison

  isOpen: boolean = false; //is the error modal currently already open?
  networkOfflineSubj = new Subject<boolean>();


  constructor(
    private modalService: ModalService,
    private apiService: ApiService,
    protected lang: LangService,
    private router: Router,
  ) {}

  async handleError(error: any) {
    //handles error (prod - sends to server. dev - console)
    if (!environment.production) {
      //if not prod - just console error
      return console.error(error);
    }

    let err = error.originalError || error;
    if (
      this.prevErrors.find(
        (it) => it.message == err.message && it.stack === err.stack
      )
    ) {
      //if an error wit the same info was sent before - exit
      return;
    }

    this.prevErrors.push(err);

    let errObj = {
      err: err.message,
      stack: err.stack || "",
      location: location.toString(),
      localTime: new Date().toString(),
    }; //send to server the message, stack, utl, time

    this.apiService.post("send_err",errObj);
    return console.error(error);

    // if (!this.isOpen) {	//if modal is not already open
    // 	// this.router.navigateByUrl("/login");
    // 	this.isOpen = true;	//flag the modal as open
    // this.isOpen = false;	//flag as closed after close
    // }
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //used when the route was not captured by any real routes - attemps to take out the "lang" part of url (eg. /iw/patients/6 )
    let parts = state.url.split("/");
    if (parts.length > 1) {
      //bigger than 1: possible lang in the begining - try to remove
      parts.shift(); //remove pre-url empty
      let lang = parts.shift(); //get the lang
      if (this.lang.routeTryChangeLang(lang)) {
        //will try to set lang if it's an approve lang code. on success navigate to url without the lang
        this.router.navigateByUrl("/" + parts.join("/")); //try the url without the lang
        return;
      }
      setTimeout(() => this.router.navigateByUrl("/login"), 50); //navigate to login

      throw new Error("Route " + state?.url + " does not exist"); //error
      // this.handleError({err:"wrong route"});
      // return false;
    }
  }

  handleNetworkChanged() {
    //if the front needs to be reloaded (for example, network-changed error) replace the document body with this message
    document.body.innerHTML = `<div class="network-error">${this.lang.getVal("network_error")}</div>`;
  }

  async responseErr(err: any) {
    //handle an error response from the api

    if (this.apiService.curCsrfErrors > 5) {
      //if failed to get csrf too many times - replace the body with a request to reload

      this.handleError(err); //send to the error handling function (also sends email to server)
      this.handleNetworkChanged();
      // alert(this.lang.getVal("server_error"));
      return;
    }

    if (err?.status == 0) {

      // alert(this.lang.getVal("you_seem_to_be_disconnected"));
      if (err.url.includes("/logout")) {
        window.location.assign("/login"); //redirect to login page
      }

      if (err.url.includes("/get-csrf")) {
        this.apiService.curCsrfErrors++; //add to error count
        this.apiService.verifyCsrf(); //try to get csrf to reset the token
      }
      // return this.handleNetworkChanged();
      this.networkOfflineSubj.next(true);


      return;
    }

    if (err.url.includes("/logout")) {
      //if the error occured during logout - replace the body with a request to reload
      return this.handleNetworkChanged();
    }

    if (err.url.indexOf("get_api_calls_permissions") != -1) {
      //if failed at getting permissions - flag its sending as false
      this.apiService.isSendingApiPerms = false;
    }
    this.apiService.curCsrfErrors++; //add to error count
    this.apiService.verifyCsrf(); //try to get csrf to reset the token

    this.handleError(err); //send to the error handling function (also sends email to server)

    // let message = this.lang.getVal("an_error_occured");
    let message = "";
    if (err?.error?.error) {
      //in some auth/user related error, resolve with specific solutions
      switch (err.error.error) {
        case "no_auth": //not authorized - message and route to login screen
          message = this.lang.getVal("you_are_not_authorized");
          this.router.navigateByUrl("/login");
          break;
        case "not_active": //user is not active currently - message
          message = this.lang.getVal("not_active");
          break;
        case "otp_needed": //otp is not validated - message, request otp from server and open otp modal
          message = this.lang.getVal(
            "please_enter_the_verification_code_that_was_sent_to"
          );
          const res:any=await this.apiService.post("ajax_otp_validation");
          if (res.success) {
            this.modalService.openMulti("otp");
          }
          else {
            this.modalService.openToast(this.lang.getVal("an_error_occured"));
          }
          break;
        case "expired_password": //password expired - message and route to renewal screen
          message = this.lang.getVal("expired_password");
          this.router.navigateByUrl("/password/expired");
          break;
      }
    }

    if (message) {
      //if loaded a message - open it with toast

      this.modalService.openToast(message);
    }
  }
}
