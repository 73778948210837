<div  >
  <h4 class="gen-title">{{"accountant" | langVal}}</h4>
  <div class="row">
    <div [hidden]="scheduledReport.is_new_contact=='yes'" class="col-11 col-md-2 ">
      <app-gen-autocomplete #personFuzzyInput title_ph_lang="name" autofocus [tabindex]="1"
        [fuzzySearchableClasses]="['contacts']" [actualObj]="scheduledReport" fieldName="contact_id"
        [error]="errors.contact_id" (chosenEvent)="onContactChoose($event)">
      </app-gen-autocomplete>
    </div>
    <div class="col-1 " style="max-width: 2%;">
      <button class="btn btn-icon" titleLang="add_new_contact"
        (click)="updScheduledReports('is_new_contact',(scheduledReport.is_new_contact=='yes'?'no':'yes'));onNewContact();validateEntireForm();" [icon]="(scheduledReport.is_new_contact=='yes'?'minus':'plus')"></button>
    </div>
    @if(scheduledReport.is_new_contact=='yes' || contactNeedsEmail){
      <div class="col-9  col-md-7">
        <div class="row">
          @if(scheduledReport.is_new_contact=='yes'){
            <div class="col-11  col-md-6 ">
              <app-gen-input autocomplete="off"  [actualObj]="scheduledReport" [errors]="errors" fieldName="name" (keyupEvent)="validateEntireForm()" />
            </div>
          }
          <div class="col-11  col-md-3 ">
            <app-gen-input autocomplete="off"  [actualObj]="scheduledReport" [errors]="errors" fieldName="email" (keyupEvent)="validateEntireForm()" />
          </div>
        </div>

      </div>
    }


  </div>



  <div class="row">
    @for(checkbox of checkboxes;track checkbox){
      <div class="col-2 col-md-2 col-lg-2 col-xl-1 reports">
        <label (click)="flipCheck(checkbox)" >
          <h4>{{checkbox | langVal}}</h4>
        </label>
        <input type="checkbox" [(ngModel)]="scheduledReport[checkbox]" [checked]="scheduledReport[checkbox]"
          (change)="updateReportList()" />
      </div>
    }

  </div>
  @if(errors.checkboxes){
    <p class="error">{{errors.checkboxes}}</p>
  }


  <div class="row">

    <div class="col-12 col-md-3 ">
      <label>{{"file_format" | langVal}}</label>
      <app-gen-button-group [actualObj]="scheduledReport" fieldName="file_format" optionsListName="file_format_opts"
        (changeEvent)="validateEntireForm()"></app-gen-button-group>


    </div>
    <div class="col-6 col-md-3 ">
      <label>{{"send_freq" | langVal}}</label>
      <app-gen-button-group [actualObj]="scheduledReport" fieldName="send_freq" optionsListName="send_freq_opts"
     (changeEvent)="validateEntireForm()"></app-gen-button-group>


    </div>
    <div class="col-6 col-md-3 ">
      <label>{{"date_in_month" | langVal}}</label>
      <app-gen-button-group [actualObj]="scheduledReport" fieldName="date_in_month" optionsListName="date_in_month_opts"
       (changeEvent)="validateEntireForm()"></app-gen-button-group>

    </div>

  </div>

  <div class="row btn-bottom">
    <div class="col-12">
      <app-btn-ext text="accountant_insert" tabindex="21" [disabled]="!isFormSubmittable" (clickEv)="save()" [isSpin]="isSaving" />
    </div>

  </div>
  <div class="row btn-bottom">
    <div class="col-12">
      <app-btn-ext text="resend_all_reports" (clickEv)="send_all_reports()" [isSpin]="isSendingAll" />
    </div>

  </div>

</div>

<app-gen-table #scheduledReportsTbl tableName="scheduled_reports" />
