import { Component, OnInit } from '@angular/core';
import { LangService } from '@app/services/lang.service';

@Component({
  selector: 'app-payment-client-fail-grow',
  templateUrl: './payment-client-fail-grow.component.html',
  styleUrl: './payment-client-fail-grow.component.css'
})
export class PaymentClientFailGrowComponent implements OnInit {

  success: boolean = false;	//was the purchase a success
  constructor(public lang: LangService) { }

  ngOnInit() {
    let input = location.search.split("?").pop();	//gets the query string (everything in the URL after the ? sign)

  }

}
