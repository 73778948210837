import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';
import { PersonCommonComponent } from '@app/person-common/person-common.component';
import { ReportItemType } from './definitions/types';

@Component({
	selector: 'app-contact',
	templateUrl: './person-common/person-common.component.html',
	styleUrl: './person-common/person-common.component.css'
})
export class ContactComponent extends PersonCommonComponent implements OnInit {	//inherits some fields and methods from PersonCommons!
	personType: ReportItemType = "contact";	//used by PersonCommons to generalize
}
