<div class="payment-form container-fluid"  >
  <div class="center-small-div">
    <div  >
      {{"hello" | langVal}} {{paymentFormObject.user_name}} ,<br>
      {{ "your_monthly_payment" | langVal}}{{
      paymentFormObject.currency_symbol}}{{paymentFormObject.monthlyPrice}}{{
      "plus_vat" | langVal}}.
    </div>
    <div>
      {{ "your_outstanding_payment" | langVal}}
      {{paymentFormObject.currency_symbol}}{{ paymentFormObject.price}}.
    </div>
    @if(paymentFormObject.last_payment_paid){
      <div>
        {{ "you_paid" | langVal}} {{paymentFormObject.currency_symbol}}{{paymentFormObject.last_payment_paid}} {{ "on"
        | langVal}} {{paymentFormObject.last_payment_date}}.
      </div>
    }
    <div>
      ({{ "you_registered_at" | langVal}} {{paymentFormObject.createdAt}}).
    </div>


    <!-- TAKBULL -->
    @if(isBackFromFirstApiCall){
      <button class="btn btn-tall btn-primary" [disabled]="paymentFormObject.price<=0" (click)="redirect()" [spinner]="isFetching2ndApi">{{'text_paying_first_5' | langVal}}</button>
    }
  </div>
</div>
