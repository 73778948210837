import { Modalable } from '@app/Modalable';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';
import { AutoCompleteKeyUpObj, Patient, UpdateGroup } from '@app/definitions/types';
import {GenAutocompleteComponent} from "@app/gen-inputs/gen-autocomplete/gen-autocomplete.component";

@Component({
	selector: 'app-update-group',
	templateUrl: './update-group.component.html',
	styleUrl: './update-group.component.css'
})
export class UpdateGroupComponent extends Modalable {
  modalTitleLang: string="group_details";
  hasExit: boolean=true;

	@Output() closeModal = new EventEmitter();
  isSaving:boolean=false;

	errors: any = {};	//will hold errors for the fields in the form
	isFormSubmittable: boolean = false;	//can submit (controls disabled in the submit button)
	@Input() group: Patient = null;	//group object to update
  @ViewChild('payorFuzzyInput') payorFuzzyInput: GenAutocompleteComponent;
	validationFields: any = {	//field validations, control displaying field errors and ability to submit the form

	};
  excludeIds:number[]=[];
  tableName:string="groups";

  ngOnInit(){
    this.apiService.sendApiGetPerms();
    this.excludeIds = this.store.getNonClinicalSubUserIds();
    if(!this.group){
      this.group=new Patient;
      this.group.is_group="yes";
    }
    this.loadValidationFields();
  }
  loadValidationFields(){
    this.validationFields={
      name:{not_empty:null},
      patienttype_id:{is_legit_fuzzy_value_or_empty: true},
      payor_id:{is_legit_fuzzy_value_or_empty: true},
      patientprice:{is_integer:null}
    };
  }

  validateField(fieldName:string){
    if(!this.group.id){return this.validateEntireForm();}

    if(this.validationFields[fieldName]){
      this.validator.validateField(this.group, this.errors, fieldName,this.validationFields[fieldName]);
    }
    this.isFormSubmittable = this.validator.hasNoErrors(this.errors);
  }

	autocompleteChoice(fieldName: string, obj: any = null) {	//update autocomplete field choices, then validate
		this.group[fieldName] = obj?.id || 0;	//if no object id - set value to 0
		this.validateAndSave();
	}


	async onChange(fieldName:string){
    if(!this.group.id){return;}
    if(this.errors[fieldName]){return;}
    await this.apiService.sendApiGetPerms();
    if(!this.apiService.apiCallPerms || !this.apiService.apiCallPerms[this.tableName]?.saveField){return}
    const res:any=await this.apiService.save_gen_field(this.tableName, this.group.id, fieldName, this.group[fieldName]);
    this.store.updGenItemRowField(this.tableName,this.group.id,fieldName,this.group[fieldName]);
    let message = res?.success ? this.lang.getVal("updated_successfully") : this.lang.getVal("save_failed");	//set message by saving success
    this.modalService.openToast(message);	//toast the message to user
    this.store.reInitTableSubject.next("grouppatients");
  }

	validateEntireForm() {	//validate all fields and detrmine if can be submitted (or has errors)
		this.isFormSubmittable = this.validator.validateEntireForm(this.group, this.errors, this.validationFields);
	}

	keyUpField(obj: AutoCompleteKeyUpObj, fieldName: string) {	//autocomplete key up, if user typed a value after choosing a legitimate option - turns the field value to null
		if (!obj.isIdenticalToPreviousValue) {	//typed value has changed from when last chosen
			this.group[fieldName] = null;
			this.validateEntireForm();
		}
	}

  validateAndSave(){
    this.validateEntireForm();
    this.save();
  }


	async save() {	//save the group via the api

    if(!this.isFormSubmittable){return;}
    await this.apiService.sendApiGetPerms();
    if(!this.apiService.apiCallPerms || !this.apiService.apiCallPerms[this.tableName]?.saveNewRow){return}

    this.isSaving=true;
    const res:any=await this.apiService.save_gen_item(this.tableName, this.group);
    this.isSaving=false;
    if (res.id) {
      this.store.addGenItemRow(this.tableName, res);
      this.closeModal.emit();
      this.store.refreshTableSubject.next("groups");
    }
    const message = this.lang.getVal( res?.id ? "saved_successfully" : ( res?.mes || "save_failed" ) ) ;
    this.modalService.openToast(message);

    //if the store has a patient(ie. group) with this id, update it with some of these fields
    const fieldsToUpdate = ["id", "name", "patienttype_id", "payor_id", "price", "charge_mode", "payment_mode"];
    const objToUpdate = {};
    fieldsToUpdate.forEach(fieldName => {	//gather in the object the fields and values from this group
      objToUpdate[fieldName] = this.group[fieldName];
    })
    this.store.updatePatientPartial(objToUpdate);	//update in the store

    //
	}

  newRowAddAssoc(vals: any[]) {
		this.group.assoc = vals.map(it => it.id);
		this.validateEntireForm();
	}
  async assocAddItem(obj: any) {
		const item = { user_id: obj.id, item_id: this.group.id, item_type: "patient", active: "yes" };
    this.group.assoc = [...this.group.assoc, obj.id];
		const res:any=await this.apiService.save_gen_item("object_to_sub_users", item);
    this.modalService.openToast(this.lang.getVal("assigned_to_assoc"));
	}
	async assocDeleteItem(id: any) {
    //update the api
		const item = { user_id: id, item_id: this.group.id, item_type: "patient" };
    this.group.assoc = this.group.assoc.filter(it => it != id);
		const res:any=await this.apiService.post("delete_assoc",item);
    this.modalService.openToast(this.lang.getVal("unassigned_to_assoc"));
	}
  async openAddPayorModal(){
    const obj: any = await this.modalService.openMulti("gen-table-add-item",{
      tableName: 'payors',
      fuzzyName: this.payorFuzzyInput.returnSearchStrForAdding()
    });

    if (obj) {	//expects either NULL if nothing happened or object of new inserted row!
      this.group.payor_id = obj.id;
      this.payorFuzzyInput.searchStr = obj.name;

    }
    this.validateEntireForm();
  }
}

