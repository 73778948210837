import { StoreService } from '@app/services/store.service';
import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { SubUserConfigcliniq } from '@app/definitions/types';
import { LangService } from "@app/services/lang.service";
import { ValidatorService } from "@app/services/validator.service";
import { PermsService } from "@app/services/perms.service";
import { ModalService } from '@app/services/modal.service';
import { debounceTime, Subject } from 'rxjs';


@Component({
	selector: 'app-cc-params-sub-user',
	templateUrl: './cc-params-sub-user.component.html',
	styleUrl: './cc-params-sub-user.component.css'
})
export class CcParamsSubUserComponent implements OnInit {
	sub_user_configcliniq: any = {};
	fontSizeOpts: any[] = [	//font sizes for default text
		{ id: 10, value: 10 },
		{ id: 12, value: 12 },
		{ id: 14, value: 14 },
		{ id: 16, value: 16 },
	];

  changeSubj=new Subject();

	errors: any = {};	//will hold errors for the fields in the form
	isFormSubmittable: boolean = false;	//can submit (controls disabled in the submit button)
	validationFields: any = {	//field validations, control displaying field errors and ability to submit the form
		name: { 'not_empty': null },
		email: { 'not_empty': null },
	};

  clsDefaults:any={xs:6,lg:2};
  tabIndexStart:number=1;
  rows:any[]=[
    [
      {name:"name"},
      {name:"title",lang:"user_title"},
      // {name:"name_on_invoice",lang:"name_on_invoice",xs:12},
    ],
    [
      {name:"profession"},
      {name:"license_number"},
    ],
    [
      {name:"street"},
      {name:"house",lg:1},
      {name:"city"},
      {name:"zip",lg:1},
    ],
    [
      {name:"phone"},
      {name:"fax"},
      {name:"email"},
    ],
  ];

	constructor(private apiService: ApiService, public lang: LangService, public validator: ValidatorService, public permsService: PermsService, public modalService: ModalService, public store: StoreService) { }

	async ngOnInit() {
    const res:any=await this.apiService.post("get_sub_user_configcliniq_data");
    this.sub_user_configcliniq = res;

    let curTabIndex=this.tabIndexStart;
    this.rows.forEach(rowFields=>{
      rowFields.forEach(field=>{
        field.tabIndex=curTabIndex;
        curTabIndex++;
      })
    })


    this.changeSubj.pipe(
      debounceTime(800),
    ).subscribe(()=>{
      this.save();
    })
	}
  validateAndSave(){
    this.validateEntireForm();
    this.changeSubj.next(true);
  }

	onAccountTypeChange() {	//changes the withoutvat field according to the account type (default to "no")
		this.sub_user_configcliniq.withoutvat = (this.sub_user_configcliniq.tax_account_type == "op" ? "yes" : "no");
	}
	validateEntireForm() {	//validate all fields and detrmine if can be submitted (or has errors)
		let fieldsValidationObjects: any = {	//default - is new contact
			name: { not_empty: true },
			email: { email: null, not_empty: true },
		};

		this.errors = {};

		this.isFormSubmittable = this.validator.validateEntireForm(this.sub_user_configcliniq, this.errors, fieldsValidationObjects);
	}


	async save() {	//save to DB using the api
    if(!this.isFormSubmittable){return;}

		const res:any=await this.apiService.post("update_sub_user_configcliniq_data",this.sub_user_configcliniq);

    if (res && !res?.error) {	//success
      this.modalService.openToast(this.lang.getVal("updated_successfully"));	//open toast with success message


      //which fields from here should I update into cliniqData
      const cliniqData=this.store.getCliniqDataOrExit();
      cliniqData.sub_user_name=res.name;
      cliniqData.default_signature=res.default_signature;
      sessionStorage.setItem('cliniqData', JSON.stringify(cliniqData));	//update the config object in the sessionStorage
    }
    else {	//fail
      this.modalService.openToast(this.lang.getVal(res?.error));	//open toast with fail message
    }
	}



}
