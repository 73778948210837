import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Contactway, Payor } from '@app/definitions/types';
import { PersonUpdateCommons } from '@app/person-update-commons/person-update-commons.component';

@Component({
	selector: 'app-update-payor',
  templateUrl: '../person-update-commons/person-update-commons.component.html'
})
export class UpdatePayorComponent extends PersonUpdateCommons{

  tableName:string="payors";

	ngOnInit() {
    if(!this.payor){
      this.payor=new Payor;
      this.addContactway(true);
    }
    const cc=this.store.getCliniqDataOrExit();
    this.budget_for_payors=cc?.budget_for_payors;

    this.loadValidationFields();
	}

  openPayorprices(){
    this.modalService.openMulti("gen-table-display", {
      tableName: "payorprices",
      filterField: "payor_id",
      objectRow: this.payor
    })
  }

}
