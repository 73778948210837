<div class="payment-form container-fluid"   >
  <div  class="LOGO" title="My-cliniq" style="text-align:left">
    <img src="https://www.my-cliniq.com/wp-content/uploads/2020/07/my-cliniq-logo.png" alt="My-cliniq" />
  </div>
  <div class="center-small-div">
    <div>
      {{"hello" | langVal}} {{clientPaymentFormObject?.patient_name}} ,<br>
      {{"payment_for" | langVal}} {{clientPaymentFormObject?.user_name}} <br>
      <br>
    </div>
    <div>
      {{ "your_outstanding_payment" | langVal}}
      {{clientPaymentFormObject?.currency_symbol}}{{ clientPaymentFormObject?.sum}}
      .<br>
    </div>
    <!-- TAKBULL -->
    @if(isBackFromFirstApiCall){
      <button class="btn btn-tall btn-primary" [disabled]="clientPaymentFormObject?.sum<=0" (click)="redirect()" [spinner]="isFetching2ndApi">{{'text_paying_first_5' | langVal}}</button>
      @if(clientPaymentFormObject?.has_token){
        <button class="btn btn-tall btn-primary" [disabled]="clientPaymentFormObject?.sum<=0" (click)="pay_by_token()" [spinner]="isFetching2ndApi2">{{'repayment_by_token' | langVal}}</button>
      }
    }
    <!-- END TAKBULL -->
  </div>
  <div >
  </div>
</div>
