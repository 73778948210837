
<div class="userdetails-lines">
  <app-table-wrap fieldsStr="subject,description,order_num,actions">
    @if(newUserdetail_line){
      <ng-container  *ngTemplateOutlet="rowFields;context:{curRow:newUserdetail_line,isNewRow:true}"></ng-container>
    }

    @for(userdetail_line of userdetail_lines;track userdetail_line){
      <ng-container *ngTemplateOutlet="rowFields;context:{curRow:userdetail_line}"></ng-container>
    }
  </app-table-wrap>

</div>

<ng-template #rowFields let-curRow="curRow" let-isNewRow="isNewRow">
  <tr >

    <td>
      <app-gen-input #firstField fieldName="subject_web"  [actualObj]="curRow" [errors]="curRow.errors" title_ph_lang="subject"
      (keyupEvent)="validateField(curRow,'subject_web',isNewRow)" (changeEvent)="isNewRow? validateEntireForm(curRow) : saveField(curRow,'subject_web')"></app-gen-input>
    </td>
    <td>
      <app-wysiwyg #firstField fieldName="description_web"  [actualObj]="curRow" [errors]="curRow.errors" title_ph_lang="description"
     (keyupEvent)="validateField(curRow,'description_web',isNewRow)" (changeEvent)="isNewRow? validateEntireForm(curRow) : saveField(curRow,'description_web')"></app-wysiwyg>
    </td>

    <td class="actions"  >
      <app-spin-or-content [isSpin]="curRow.isFlipOrderSending">
        @if(!isNewRow){
          <div class="up-down-wrapper">
            @if(!curRow.is_lowest_order){
              <app-icon icon="arrow_up" (click)="moveOrderNum(curRow,true)"  titleLang="forward" />
            }
            @if(!curRow.is_highest_order){
              <app-icon icon="arrow_down" (click)="moveOrderNum(curRow,false)"  titleLang="backward" />
            }
          </div>
        }
      </app-spin-or-content>
    </td>

    <td>
      <app-spin-or-content [isSpin]="curRow.isDeleting">
        @if(isNewRow){
          <app-btn-ext [disabled]="!curRow.isFormSubmittable" [isSpin]="isNewSaving" (clickEv)="saveNewRow()" text="add" />
        }
        @else{
          <button class="btn btn-icon" (click)="deleteRow(curRow)"  titleLang="delete" icon="delete"></button>
        }
      </app-spin-or-content>
    </td>
  </tr>

</ng-template>
