<div class="guest-hours-sched">

  @if(meetingtype){
    <div class="row">
        <div class="col-12 dates-row">
          <button class="cal-btn cal-icon" (click)="periodGo(-1)" icon="rightArrow"></button>
          <div class="dates-title">{{startDate | dateFormat}} - {{endDate | dateFormat}}</div>
          <button class="cal-btn cal-icon" (click)="periodGo(1)" icon="leftArrow"></button>
        </div>

    </div>
    <div class="row hours-space">
    <!--  <div class="col-12 col-md-4 no-mob">-->
    <!--    <app-date-picker noInput (changeEvent)="chDate($event)"></app-date-picker>-->
    <!--  </div>-->

      <div class="col-12 col-md-8">
        <table>
          <tbody>
          @for(availableDate of availableDates;track availableDate){
            @if(newWeeksDates[availableDate]){
              <tr class="spacer">
                <td colspan="99999"></td>
              </tr>
            }
            <tr class="date-row d-flex">
              <td style="font-size: 16px;">{{datesToDays[availableDate] | langVal}}  </td>
              <td style="font-size: 16px;">{{availableDate | dateFormat}}</td>
              <td class="d-flex">
                @for(block of availableTimeBlocksDates[availableDate];track block){
                  <button class="btn btn-primary" (click)="openMeetingModal(availableDate,block)">{{block}}</button>
                }
              </td>
            </tr>
          }
          </tbody>
        </table>
      </div>
    </div>
  }
  <div class="row scheduler-content">
    <div class="col-12">
      @if(availableMeetingtypesFirst?.length){
        <h4 >{{"for_first_time_meeting" | langVal}}</h4>
        <div >
          @for(availableMeetingtype of availableMeetingtypesFirst;track availableMeetingtype){
            <button [title]="('select_meeting_type' | langVal)+' '+(availableMeetingtype.name | langVal)"
                    (click)="chooseMeetingtypeFirst(availableMeetingtype)" class="btn btn-default"
                    [class.active]="meetingtype?.id==availableMeetingtype.id"
                    [class.btn-primary]="meetingtype?.id==availableMeetingtype.id">
              {{availableMeetingtype.name | langVal}} ({{availableMeetingtype.length}} {{"minutes" | langVal}})
            </button>
          }

        </div>
      }
      @else{
        <p>{{"no_meetingtypes_available" | langVal}}</p>
      }
    </div>

    <div class="col-12 ">
      @if(availableMeetingtypesReturn?.length){
        <h4  >{{"for_return_meeting" | langVal}}</h4>
        <div >
          @for(availableMeetingtype of availableMeetingtypesReturn;track availableMeetingtype){
            <button [title]="('select_meeting_type' | langVal)+' '+(availableMeetingtype.name | langVal)"
                    (click)="chooseMeetingtypeReturn(availableMeetingtype)" class="btn btn-default"
                    [class.active]="meetingtype?.id==availableMeetingtype.id"
                    [class.btn-primary]="meetingtype?.id==availableMeetingtype.id">
              {{availableMeetingtype.name | langVal}} ({{availableMeetingtype.length}} {{"minutes" | langVal}})
            </button>
          }
        </div>
      }
      @else{
        <p>{{"no_meetingtypes_available" | langVal}}</p>
      }
    </div>
  </div>
  @if(eventsService.isMobile && dataObj?.meeting_request_with_calendar){
    <div class="row">
      <div class="col-12" >
        <button class="btn btn-primary btn-back" (click)="backEv.emit()" icon="back"></button>
      </div>
    </div>
  }
</div>
