<div class="row patient-details-fields">
  <div class="col-12">

    <h3>{{"patient_details_fields" | langVal}}</h3>
    <div class="table-responsive">
      <table class="table table-hover" >
        <tbody>
          @for(field of fields;track field){
            <tr>
              <td>
                <input [checked]="saveObj[field]?.show" (change)="saveObj[field].show=$event.target.checked;saveSubj.next(1);" type="checkbox" />
              </td>
              <td>
                {{field | langVal}}
              </td>
              <td>
                <app-gen-button-group [actualObj]="saveObj[field]" fieldName="required" optionsListName="requiredOpts" title_ph_lang="required" (changeEvent)="saveSubj.next(1);" />
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>

    <!-- <div style="margin-top:20px;">
      <app-btn-ext text="save" (clickEv)="save()" [isSpin]="isSaving" />
    </div> -->
  </div>
</div>
