import {Component, OnInit, EventEmitter, Input, Output} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigDefinitions, daysOfWeek } from '@app/definitions/types';
import { EventsService } from '@app/services/events.service';
import { LangService } from '@app/services/lang.service';
import { StoreService } from '@app/services/store.service';
import * as moment from 'moment';

@Component({
  selector: 'app-guest-hours-sched',
  templateUrl: './guest-hours-sched.component.html',
  styleUrl: './guest-hours-sched.component.css'
})
export class GuestHoursSchedComponent implements OnInit {

  @Input() dataObj:any=null;
  @Output() chosenHourOut = new EventEmitter();
  @Output() backEv = new EventEmitter();
  fdsewrfsdfds:string="";
  hgfe5ty6hjtgft454tr:string="";
  daysOfWeek=daysOfWeek;
  fuses:any[]=[];
  dates:any={};
  periodLen:number=14;
  name:string="";
  meetingPadMinutes:number=5;

  startDate:string="";
  endDate:string="";
  isFirstTime:string=null;
  availableMeetingtypesFirst:any[]=[];
  availableMeetingtypesReturn:any[]=[];
  meetingtype:any=null;
  availableTimeBlocksDates:any[]=[];
  availableDates:string[]=[];
  datesToDays:any={};
  meeting_request_with_calendar:boolean=false;
  hours_to_add:number=1;

  newWeeksDates:any={};

  constructor(protected route: ActivatedRoute,public lang: LangService,private store: StoreService,public router: Router,public eventsService:EventsService ) { }

  ngOnInit() {
    this.isFirstTime=null;
    this.meetingtype=null;

    this.load();
  }

  async load(){
    this.name=this.dataObj.name

    this.fuses=this.dataObj.fuses ? this.dataObj.fuses.filter(fus => fus.series_limit_date != "0000-00-00") : null;
    this.meeting_request_with_calendar=this.dataObj.meeting_request_with_calendar;
    this.startDate=moment.utc().format(ConfigDefinitions.momentDateFormat);

    if(this.meeting_request_with_calendar) {this.processFirstTime();}

    if(this.dataObj.break_length){
      this.meetingPadMinutes= this.dataObj.break_length ;
    }
    this.hours_to_add=this.dataObj.guest_sched_hours_to_add;




  }
  chDate(date:string){
    this.startDate=date;
    this.periodGo();

  }

  processFirstTime(){
    this.meetingtype=null;

    this.availableMeetingtypesFirst=this.dataObj.meetingtypes.filter(mt=> {
       return mt.first_time_meeting_request == "yes";});
    this.availableMeetingtypesReturn=this.dataObj.meetingtypes.filter(mt=>{
         return mt.return_meeting_request=="yes";});

  //   if(this.availableMeetingtypesFirst.length==1){
  //     this.chooseMeetingtypeFirst(this.availableMeetingtypesFirst[0]);
  //   }
  // if(this.availableMeetingtypesReturn.length==1){
  //     this.chooseMeetingtypeReturn(this.availableMeetingtypesFirst[0]);
  //   }

  }

  chooseMeetingtypeFirst(meetingtype:any){
    this.meetingtype=meetingtype;
    this.periodGo();

  }
  chooseMeetingtypeReturn(meetingtype:any){
    this.meetingtype=meetingtype;
    this.periodGo();
  }

  periodGo(num:number=0){
    let start=moment.utc(this.startDate);
    if(num!=0){
      start.add(num*this.periodLen,"days")
    }
    if(start.isBefore(moment.utc())){
      start=moment.utc();
    }
    this.startDate=start.format(ConfigDefinitions.momentDateFormat);
    this.endDate=moment.utc(start).add(this.periodLen-1,"days").format(ConfigDefinitions.momentDateFormat);
    this.getFus();
  }


  getFusInRange() {
		const from = moment.utc(this.startDate+" 00:00:00Z");

		const to = moment.utc(this.endDate+" 00:00:00Z").add(1,"day");
		if (from >= to) { return [] };



		let followupseries = this.fuses.filter(fus => fus.series_repeat != "series_exception");
		const fusExceptions = this.fuses.filter(fus => fus.series_repeat == "series_exception");

		const possibleFus = followupseries.filter(fus => {
			if (fus.series_limit_date && moment.utc(fus.series_limit_date) < from) {	//2020/05/03
				return false;
			}
			return true;
		})

		const possibleVacationFus = possibleFus.filter(fus => fus.followuptype_id == 5);

		let dates = {};	//each date -> arr for fus INSTANCES
		const vacationFus = [...possibleVacationFus];

		let currentDate = moment(from);
		while (currentDate < to) {
			let fusInstances = [];
			//if a vacation exists that started BEFORE currentDate and ends AFTER currentDate - continue;
			const todayVacation = vacationFus.find(vacFus => {
				return false;
			});
			if (todayVacation) {
				currentDate.add(1, "day");
				continue;
			}

			possibleFus.forEach(fus => {
				fus = { ...fus };

				// limit date  on server
				// inserted date = series_limit_date = inserted date             end date included
				// update/delete =  series_limit_date = inserted date - 1     the limit date indicates the beginning of the new series



				if (fus.series_limit_date && moment.utc(fus.series_limit_date) < currentDate) {
					return;
				}

				if (currentDate.isBefore(moment.utc(fus.date))) { return; }	//get out

				if (fus.followuptype_id == 5) {	//VACATION!!
					// if(!vacationFus.find(vacFus=>vacFus.id==fus.id)){
					// 	vacationFus.push(fus);
					// }
					return;	//this fus will NOT be rendered
				}

				let exception = fusExceptions.find(fusException => fusException.series_id == fus.id && currentDate.isSame(moment.utc(fusException.date)));
				if (exception) { return; }

				fus.ser_id = fus.id;

				switch (fus.series_repeat) {	//'one_time','series_daily','series_weekly','series_monthly','series_exception','series_2weekly'
					case "one_time":
						//start_unix (and end) are within range
						if (fus.followuptype_id == 5) {	//holiday
							if (moment.utc(fus.date_to).isSameOrAfter(currentDate)) {
								fusInstances.push(fus);
							}
						}
						else {
							if (currentDate.isSame(moment.utc(fus.date))) {
								fusInstances.push(fus);
							}
						}
						break;
					case "series_daily":
						fusInstances.push(fus);
						break;
					case "series_weekly":
						if (currentDate.day() == fus.start_day) {
							fusInstances.push(fus);
						}
						break;
					case "series_monthly":	//every 4 weeks
						// if(fus.id==312){
						// }
						if (currentDate.day() == fus.start_day) {
							if (currentDate.diff(moment.utc(fus.date), "weeks") % 4 == 0) {
								fusInstances.push(fus);
							}
						}
						break;
					case "series_2weekly":
						if (currentDate.day() == fus.start_day) {
							if (currentDate.diff(moment.utc(fus.date), "weeks") % 2 == 0) {
								fusInstances.push(fus);
							}
						}
						break;
					case "series_3weekly":
						if (currentDate.day() == fus.start_day) {
							if (currentDate.diff(moment.utc(fus.date), "weeks") % 3 == 0) {
								fusInstances.push(fus);
							}
						}
						break;
				}

				//1-1-2021 + 13:30:00

			});

			//iterate fusInstances to see which ones are in conflict with vacations (conflict means the fus doesn't take place/is color alpha reduced)
			const ownerHasUsers = false;
			// const ownerHasUsers = this.permsService.owner_has_users;
			fusInstances.forEach(fus => {

				if (fus.meeting_on_holiday == "yes") {
					return true;
				}

				//if vacation exists that EITHER (this fus time OR this fus time_to is between the vacation's time->time_to) OR (the vacation time OR vacation time_to is between fus time->time_to)

        const fusStart = moment.utc(`${currentDate.format(ConfigDefinitions.momentDateFormat)} ${fus.time}`);
        const fusEnd = moment.utc(`${currentDate.format(ConfigDefinitions.momentDateFormat)} ${fus.time_to}`);

				vacationFus.forEach(vac => {
					if (ownerHasUsers) {	//if assoc check is needed

						//only be able to dismiss this vac if no vacation_all_users
						if (vac?.vacation_all_users != "yes") {

							const vacAssocUserId = vac.assoc_sub_user_ids?.length ? vac.assoc_sub_user_ids[0] : 0;
							if (!fus.assoc_sub_user_ids.includes(vacAssocUserId)) {	//if this vac assoc user ISN'T part of the fus assoc users - can't be isInVac
								return;
							}
						}
					}

					const vacStart = moment.utc(`${vac.date} ${vac.time}`);
					const vacEnd = moment.utc(`${vac.date_to} ${vac.time_to}`);


					//trap the fusStart
					// if (fusStart.isBetween(vacStart, vacEnd, null, "[]")) { return true }
					if (fusStart.isBetween(vacStart, vacEnd, null, "[]")) { fus.isInVac = 1 }

					//trap the fusEnd
					// if (fusEnd.isBetween(vacStart, vacEnd, null, "[]")) { return true }
					if (fusEnd.isBetween(vacStart, vacEnd, null, "[]")) { fus.isInVac = 1 }

					//trap the fusStart
					// if (fusStart.isBetween(vacStart, vacEnd, null, "[]")) { return true }
					if (vacStart.isBetween(fusStart, fusEnd, null, "[]")) { fus.isInVac = 1 }

					//trap the fusEnd
					// if (fusEnd.isBetween(vacStart, vacEnd, null, "[]")) { return true }
					if (vacEnd.isBetween(fusStart, fusEnd, null, "[]")) { fus.isInVac = 1 }
				});



        //only inside time-allocation

        const tas = this.dataObj.solo_time_allocations.filter(ta => fusStart.day() == ta.day);
        const foundTas = tas.find(ta => {
          const taStartMom = moment.utc(fusStart.format(ConfigDefinitions.momentDateFormat) + " " + ta.time);
          const taEndMom = moment.utc(fusEnd.format(ConfigDefinitions.momentDateFormat) + " " + ta.time_to);

          return fusStart.isBetween(taStartMom, taEndMom, null, "[]") || fusEnd.isBetween(taStartMom, taEndMom, null, "[]");
        });
        if(!foundTas){
          fus.notInTa=1;
        }

			});

      this.dates[currentDate.format(ConfigDefinitions.momentDateFormat)]=fusInstances;

      fusInstances=fusInstances.filter(it=>!it.notInTa);

			dates[currentDate.format(ConfigDefinitions.momentDateFormat)] = fusInstances;
			currentDate.add(1, "day");
		}

		const retArr = [];

		Object.keys(dates).forEach((date: any) => {
			const arr = dates[date];
			arr.forEach(fusInstance => {

        if (fusInstance.series_repeat != "one_time") {
          fusInstance.date = date;
          fusInstance.date_to = fusInstance.date;
        }

        //add padding to time_to
        fusInstance.time_to=moment.utc(fusInstance.date_to+" "+fusInstance.time_to).add(this.meetingPadMinutes,"minutes").format("HH:mm")

				retArr.push(fusInstance);
			})
		})
		vacationFus.forEach(fus => {
      if(moment.utc(fus.date_to)<from){return;}
			fus.ser_id = fus.id;
			fus.date_render = this.store.getMomentDisplay(fus.date);
			fus.date_to_render = this.store.getMomentDisplay(fus.date_to);
			fus.name = fus.meeting_title;

			retArr.push(fus);
		});

		return retArr;

	}

  getMeetingRequests(){
    let events=[];
    const start=moment.utc(this.startDate);
    const end=moment.utc(this.endDate).add(1,"day");
    for(let meeting_request of this.dataObj.meeting_requests){

      const meetingtype=this.dataObj.meetingtypes.find(mt=>mt.id==meeting_request.meetingtype_id);

      let length=60;
      if(meetingtype){
        length=meetingtype.length+this.meetingPadMinutes;
      }
      const mrStart=moment.utc(meeting_request.date+" "+meeting_request.time);
      const mrEnd=moment.utc(mrStart).add(length,"minutes");
      if(start>mrEnd || end<mrStart){continue;}

      events.push({
        date:mrStart.format(ConfigDefinitions.momentDateFormat),
        date_to:mrEnd.format(ConfigDefinitions.momentDateFormat),
        time:mrStart.format("HH:mm"),
        time_to:mrEnd.format("HH:mm"),
      });

    }
    return events;
  }

  getTimeAllocations(){
    const events=[];
    const localTimeStart=moment().add(this.hours_to_add,"hours");
    const start=moment.utc(this.startDate);
    const end=moment.utc(this.endDate).add(1,"day");
    const currentDate=moment.utc(start);
    while(currentDate<end){

      const day=currentDate.day();
      // const isToday=currentDate.format(ConfigDefinitions.momentDateFormat)==localTime.format(ConfigDefinitions.momentDateFormat);

      const currentDateString=currentDate.format(ConfigDefinitions.momentDateFormat);
      for(let ta of this.dataObj.solo_time_allocations){
        if(ta.day!=day){continue}
        let time=ta.time;
        const timeToMom=moment(currentDateString+" "+ta.time_to);
        if(timeToMom.isBefore(localTimeStart)){continue;}
        const timeMom=moment(currentDateString+" "+time);
        if(timeMom.isBefore(localTimeStart)){

          time=localTimeStart.format("HH:mm");
        }
        const start = new Date(currentDate.format(ConfigDefinitions.momentDateFormat) + " " + time + "Z");
        const end = new Date(currentDate.format(ConfigDefinitions.momentDateFormat) + " " + ta.time_to + "Z");
        const ev= {start,end};
        events.push(ev);
      };
      currentDate.add(1,"day");
    }
    return events;
  }


  splitTAsByEvents(taArr:any[],events:any[]){

    // const date="2022-10-18";

    // const m=[
    //   // "0600-0700",
    //   // "0600-0900",
    //   "0830-1000",
    //   // "0900-1100",
    //   // "0800-1200",
    //   // "0900-1300",
    //   // "0800-1200",
    //   // "1030-1100",
    //   // "1000-1400",
    //   // "1000-1200",
    //   // "1200-1500",
    //   // "1200-1400",
    //   // "1400-1600",
    //   "1500-1530",
    //   // "0800-1900",
    //   "1100-1400",
    // ];


    // events=m.map(dateStr=>{
    //   const times=dateStr.split("-").map(part=>{
    //     return new Date(date+" "+part.substring(0,2)+":"+part.substring(2)+":00Z");
    //   });
    //   return {start:times[0],end:times[1]};
    // });
    // // events.push({
    // //   start:new Date(date+" 14:00:00Z"),
    // //   end:new Date("2022-10-19 09:00:00Z"),
    // // });

    // taArr=[
    //   {
    //     start:new Date(date+" 09:00:00Z"),
    //     end:new Date(date+" 12:00:00Z"),
    //   },
    //   {
    //     start:new Date(date+" 13:00:00Z"),
    //     end:new Date(date+" 16:00:00Z"),
    //   },
    // ];

    for(let ev of events){
      taArr.forEach((ta,taKey)=>{
        if(ev.start<=ta.start){ // if ev start is before= ta start
          if(ev.end<=ta.start){ //     if ev end is before= TA start (0600-0700) - nothing

          }
          else if(ev.end<ta.end){ //     else if ev end is before!= TA end (0830-1000) - change TA.start to EV end (1000-1200)
            taArr[taKey].start=ev.end;
          }
          else{ //     else (ev end is after= TA end) (0800-1300) - remove TA
            taArr=taArr.filter(i=>i!=ta);
          }
        }
        else if(ev.start<ta.end){  // else if ev start before!= TA end
          if(ev.end<ta.end){ //     if ev end is before!= TA end (1030-1100) - remove ta and split to 2 new parts: ta.start->ev.start, ev.end->ta.end
            taArr=[
              ...taArr.filter(i=>i!=ta),
              {...ta,start:ta.start,end:ev.start},
              {...ta,start:ev.end,end:ta.end},
            ];
          }
          else{ //     else (ev end is AFTER= TA end) (1000-1400) - change ta.end to ev start (0900-1000)
            taArr[taKey].end=ev.start;
          }

        }
        else{ // else (ev start is after= ta end) (1400-1600) - nothing

        }


      });
    }

    //check for starts/ends another day!!!!!


    return taArr;

  }


  async getFus(){


		let fusInstanceArr = this.getFusInRange();
    let meetingRequestsArr=this.getMeetingRequests();
    let allEvents=[...fusInstanceArr,...meetingRequestsArr];
		let events = allEvents.map(fusInstance => {
      return {
        ...fusInstance,
        start: new Date(fusInstance.date+" "+fusInstance.time+"Z"),
        end: new Date(fusInstance.date_to+" "+fusInstance.time_to+"Z"),
      }
    });

    const timeAllocationEvents = this.getTimeAllocations();
    const postSplitTas=this.splitTAsByEvents(timeAllocationEvents,events);

    const dateChunks:any={};
    postSplitTas.forEach(chunk=>{
      const date=moment.utc(chunk.start).format(ConfigDefinitions.momentDateFormat);
      if(!dateChunks[date]){
        dateChunks[date]=[];
      }
      dateChunks[date].push(chunk);
    });
    const datesAvailable=Object.keys(dateChunks);
    datesAvailable.forEach(date=>{
      dateChunks[date].sort((a:any,b:any)=>a.start-b.start);
    });


    const availableTimeBlocksDates=[];

    datesAvailable.forEach(date=>{
      dateChunks[date].forEach(chunk=>{
        //can I schedule a meeting either at the begining or end of this chunk?
        const length=this.meetingtype.length+this.meetingPadMinutes;
        const lengthInMilli=length*60*1000;
        const fitsInTheChunk=lengthInMilli<=(chunk.end-chunk.start);
        if(!fitsInTheChunk){return;}
        if(!availableTimeBlocksDates[date]){
          availableTimeBlocksDates[date]=[];
        }
        const first=chunk.start;
        availableTimeBlocksDates[date].push(moment.utc(first).format("HH:mm"));
        const second:any=new Date(chunk.end-lengthInMilli);

        if(second-first){
          availableTimeBlocksDates[date].push(moment.utc(second).format("HH:mm"));
        }
      })
    });



    this.newWeeksDates={};
    this.availableTimeBlocksDates=availableTimeBlocksDates;
    this.availableDates=Object.keys(availableTimeBlocksDates);
    this.availableDates.sort((a:any,b:any)=>moment.utc(a).isBefore(moment.utc(b)) ? -1 : 1);

    let curDay=0;
    this.availableDates.forEach(d=>{
      const dd=new Date(d+" 12:00:00Z");
      const day=dd.getDay();
      if(curDay>day){
        this.newWeeksDates[d]=1;
      }
      curDay=day;
      this.datesToDays[d]=daysOfWeek[day];
    })

  }



  async openMeetingModal(date:string,time:string){
    this.chosenHourOut.emit({date,meetingtype_id:this.meetingtype.id,time});
  }


  disdis(t:any){
    return `${this.min2(t.start.getUTCHours())}:${this.min2(t.start.getUTCMinutes())} - ${this.min2(t.end.getUTCHours())}:${this.min2(t.end.getUTCMinutes())}`;
  }
  min2(v:any){
    return String(v).length<2?"0"+v:v;
  }


}
