<div class="row checkboxes">
  <div class="col-12">

    <h3>{{title | langVal}}</h3>
    <div class="table-responsive">
      <table class="table table-hover" >
        <tbody>
          <tr>
            <td>
              <input [checked]="allOp" (change)="flipAll()" type="checkbox" />
            </td>
            <td>
              <span (click)="flipAll()">{{"select_all_fields" | langVal}}</span>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <hr />
            </td>
          </tr>
          @for(field of fields;track field){
            <tr>
              <td>
                <input [checked]="saveObj[field]" (change)="saveObj[field]=$event.target.checked;saveSubj.next(1);" type="checkbox" />
              </td>
              <td>
                <span (click)="saveObj[field]=!saveObj[field];saveSubj.next(1);">{{field | langVal}}</span>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>

    <!-- <div style="margin-top:20px;">
      <app-btn-ext text="save" (clickEv)="save()" [isSpin]="isSaving" />
    </div> -->
  </div>
</div>
