<div class="button-group" [class.tab]="isTab" [attr.data-fieldname]="fieldName" [class.duo]="options.length==2"    >
  @for(option of options;track option){
    <button [disabled]="disabled" class="btn" [class.btn-tab]="isTab"
      [title]="( (title_ph_lang || fieldName) | langVal) +': '+(option.lang || (option[optionFieldName] | langVal))"
      (click)="onclick(option[optionFieldValue])"
      [class.btn-default]="actualObj[fieldName]!=option[optionFieldValue]"
      [class.active]="actualObj[fieldName]==option[optionFieldValue]"
      [class.btn-primary]="actualObj[fieldName]==option[optionFieldValue]">{{option.lang || (option[optionFieldName] | langVal)}}</button>
  }
</div>
@if(errors[fieldName]){
  <p class="error">{{errors[fieldName]}}</p>
}
