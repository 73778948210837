import { Component, OnInit, ViewChild } from '@angular/core';
import { GenTableComponent } from '@app/gen-table/gen-table.component';
import {
	Branch,
	ConfigDefinitions,
	Diagp,
	Medic,
	Notification,
} from '@app/definitions/types';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
	selector: 'app-gen-table-pagination',
	templateUrl: './../gen-table/gen-table.component.html',
	styleUrl: './../gen-table/gen-table.component.css'
})
export class GenTablePaginationComponent extends GenTableComponent {
	itemsPerPage: number = 20;
	paginationObj: any = { curPage: 1, lastPage: 0 };

	pagiInit() {
		this.itemsPerPage = 20;
		this.paginationObj = { curPage: 1, lastPage: 0 };
	}
	runFiltersPost() {	//in pagi - get items from server using the filters
		this.getItems();
	}


	async getItems(callBack: any = null) {
		this.genTableService.attachApiCallPerms(this);
		let getCallObj: any = {
			curPage: this.paginationObj.curPage,
			itemsPerPage: this.itemsPerPage,
			sortField: this.sortField,
			sortIsAsc: this.sortIsAsc,
			searchStr: this.searchStr,
			searchableFields: this.searchableFields,
      page:this.paginationObj.curPage,
		};
		if (this.filterField) {
			getCallObj.filterField = this.filterField;
			getCallObj.filterFieldValue = this.filterFieldValue;
		}

		let filterFieldApiObject = {};
		if (this.filterFieldApiObjectPreset) {
			filterFieldApiObject = { ...filterFieldApiObject, ...this.filterFieldApiObjectPreset };
		}
		if (this.filterFieldApiObject) {
			filterFieldApiObject = { ...filterFieldApiObject, ...this.filterFieldApiObject };
		}
		for (let filterObj of this.filterFields) {
			let fieldName = filterObj.fieldName;
			let filterValue = this.currentFilterValues[fieldName];
			if (filterValue == "-1") {	//ALL case
				continue;
			}
			filterFieldApiObject[fieldName] = (filterValue === "null" ? null : filterValue);
		}


		if (Object.keys(filterFieldApiObject).length) {
			getCallObj.filterFieldApiObject = filterFieldApiObject;
		}



		this.isLoading = true;
		this.emptyShownRows();
		const res:any=await this.apiService.get_gen_items(this.tableName, getCallObj);
    this.paginationObj.lastPage = res[this.collectionName].last_page;
    this.objectCollection = res[this.collectionName].data;
    // this.apiService.decryptInCollection(this.objectCollection);
    this.objectCollection.forEach(it => {
      it.errors = {};
    })
    this.expandDateFields();
    this.objectCollectionShown = this.objectCollection;
    this.isLoading = false;
    this.isSearching=false;
    if (callBack) {
      callBack();
    }
	}
	sort(fieldName: string) {
		let fieldObj = this.collectionFields.find(it => it.fieldName == fieldName);
		if (fieldObj && !fieldObj.isSortable) { return; }
		this.searchSortService.updateSortField(this, fieldName);
		this.getItems();
	}
	search() {
		this.searchSubj.next(this.currentSearch);
		// this.searchStr=value;
		// this.getItems();
	}
	subscribeToSearch() {	//subscribe to search so the server calls are delayed
		this.searchSubj.pipe(
			debounceTime(800),	//wait 800ms
			distinctUntilChanged()	//only if value changes
		)
			.subscribe((res: string) => {	//update the search and send to server
				// if(res.length){
				this.searchStr = res;
        this.isSearching=true;
				this.getItems();
				// }
			});
	}
}
