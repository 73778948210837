import { ModalService } from '@app/services/modal.service';
import { BasicInputFormClass, ConfigDefinitions } from '@app/definitions/types';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { SearchSortService } from '@app/services/search-sort.service';
import { LangService } from '@app/services/lang.service';
import { environment } from '@env/environment';
import { InputsService } from '@app/services/inputs.service';



@Component({
	selector: 'app-gen-file-input',
	templateUrl: './gen-file-input.component.html',
	styleUrl: './gen-file-input.component.css'
})
export class GenFileInputComponent extends BasicInputFormClass {
	accept: string = "";	//file extensions to accept (only a suggestion)
	@Input() fileType: string = "";	//file or image or xlsx-csv
	@Input() urlTemplate: string = "";	//template for display of images (ex. /headtures/{id})
	@Input() isUploading: boolean = false;	//is currently loading (shows loading gif)
	@Input() showImagePreview: boolean = true;
	@Input() maxDims: any = null;
	url: any = "";	//dispaly image url (either pre loaded or newly loaded)
	extentions: string[] = [];	//allowed extentions
	@ViewChild("elementEl") elementEl: ElementRef;	//the file input, used for resetting and clicking on it when label is clicked


	constructor(public lang: LangService, protected searchSortService: SearchSortService, private modalService: ModalService, private inputsService:InputsService) {
		super();
	}

	ngOnInit() {
		this.inputsService.loadVarsToInput(this);
		this.url = environment.serverUrl + this.searchSortService.templateReplaceInObj(this.urlTemplate, this.actualObj) + `?t=${Math.random()}`;	//formats the url for preloaded image using the urlTemplate
		this.extentions = ["jpeg", "jpg", "png"];	//default extensions
		switch (this.fileType) {	//update extensions according to type
			case "file":
				this.extentions = [...this.extentions, "docx", "doc", "pdf", "xls", "xlsx"];
				break;
			case "xlsx-csv":
				this.extentions = ["xlsx", "csv"];
				break;
		}
		this.accept = this.extentions.map(ext => "." + ext).join(",");	//accept updated by extensions
	}

	async onchange(el: any) {	//file was uploaded using the input or reset. el is the input
		let name = "";	//name resets
		if (el.files && el.files[0]) {	//if found uploaded file
			let f = el.files[0];
			name = f.name;	//name gets the file's name
			const ext = name.split(".").pop().toLowerCase();	//find file extension
			if (!this.extentions.includes(ext)) {	//if extension is not allowed for this type - reset input, toast the error to the user and returns
				el.value = "";
				this.modalService.openToast(this.lang.getVal("wrong_file_format"));
				return;
			}



			if (this.fileType == "image") {	//file is image - upload a preview
				let reader = new FileReader();	//create reader
        reader.readAsDataURL(el.files[0]);	//the reader reads the file (when done will fire "onload")
				const err:any=await new Promise(resolve=>{
          reader.onload = (e) => {	//when reader loads - update the url dti display the "read" file

            if(this.maxDims?.width){
              const image:any = new Image();
              image.src = e.target.result;
              image.onload = ()=> {
                if(image.width>this.maxDims.width || image.height>this.maxDims.height){
                  resolve(this.lang.getVal("wrong_file_dimensions"));
                  return;
                }
                this.url = e.target.result;
                resolve(false);
              }
              return;
            }


            this.url = e.target.result;
            resolve(false);


          };

        });
        if(err){
          this.modalService.openToast(this.lang.getVal(err));
          return;
        }

			}
		}
		if (this.actualObj) {	//if and object and field are connected - update them with new (or empty) name
			this.actualObj[this.fieldName] = name;
		}
		this.changeEvent.emit(el.files);	//emit changes to a listening parent - the file's files list
		el.value = "";	//reset the actual input (make it ready for a new file)
	}
	empty() {	//reset the input
		let ne = this.elementEl.nativeElement;
		ne.value = null;	//reset the input
		this.onchange(ne);	//emit changes to parent - the file itself
	}


}
