import { Component, OnInit } from '@angular/core';
import { CcParamsGen } from '@app/cc-params-gen/cc-params-gen';

@Component({
  selector: 'app-cc-params-user-details',
  templateUrl: './cc-params-user-details.component.html',
  styleUrl: './cc-params-user-details.component.css'
})
export class CcParamsUserDetailsComponent extends CcParamsGen {
  saveObjName: string = "user";
  validationFields: any = {
		name:{'not_empty':null},
		email:{'not_empty':null},
	};

  clsDefaults:any={xs:6,md:3};
  tabIndexStart:number=51;
  rows:any[]=[
    [
      {name:"name",xs:12},
      {name:"title",lang:"user_title",xs:12},
      // {name:"name_on_invoice",lang:"name_on_invoice",xs:12},

    ],
    [
      {name:"profession",xs:12},
      {name:"license_number",xs:12},
    ],
    [
      {name:"street"},
      {name:"house",md:1},
      {name:"city"},
      {name:"zip",md:2},
    ],
    [
      {name:"phone"},
      {name:"fax"},
      {name:"email"},
    ],
  ];
  ngOnInit() {
    let curTabIndex=this.tabIndexStart;
    this.rows.forEach(rowFields=>{
      rowFields.forEach(field=>{
        field.tabIndex=curTabIndex;
        curTabIndex++;
      })
    })
  }

}
