import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
	name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {	//this pipe is used to enable an element to show HTML inside its innerHTML (as opposed to Angular's normal sanitizing)

	constructor(private sanitizer: DomSanitizer) { }

	transform(style) {


		return this.sanitizer.bypassSecurityTrustHtml(style);
		//return this.sanitizer.bypassSecurityTrustStyle(style);
		// return this.sanitizer.bypassSecurityTrustXxx(style); - see docs
	}

}
