<div>
  <h4  class="gen-title" titleLang="marked_holidays">{{"holidays" | langVal}}</h4>
</div>

<div class="params-holiday">
  @for(holidayGroupName of holidayGroupNames;track holidayGroupName){
    <div>
      <h4>{{holidayGroupName | langVal}}</h4>

      <table class="table table-hover table-striped pad-block ">
        <thead>
          <tr>
            <th style="width:5%;">
              <input type="checkbox" [checked]="holidayGroupsChecked[holidayGroupName]"
                (change)="checkAll(holidayGroupName,$event.target.checked)" titleLang="active" />
              {{"active" | langVal}}
            </th>
            <th style="width:25%;">{{"holiday" | langVal}}</th>
            <th style="width:20%;">{{"description" | langVal}}</th>
            <th style="width:10%;">{{"days" | langVal}}</th>
            <th style="width:15%;">{{"time_from" | langVal}}</th>
            <th style="width:15%;">{{"time_to" | langVal}}</th>
          </tr>
        </thead>
        <tbody>
          @for(holiday of holidayGroups[holidayGroupName];track holiday){
            <tr [title]="holiday.instancesFloat">

              <td>
                <input type="checkbox" [checked]="holiday.active=='yes'"
                  (change)="holiday.active=($event.target.checked?'yes':'no')" titleLang="active" />
              </td>
              <td>{{holiday.name}}</td>
              <td>{{holiday.description}}</td>
              <td>{{holiday.length}}</td>
              <td>
                <app-time-picker  [actualObj]="holiday" fieldName="time_from" [addonObject]="{after:{icon:'time'}}"></app-time-picker>
              </td>
              <td>
                <app-time-picker  [actualObj]="holiday" fieldName="time_to" [addonObject]="{after:{icon:'time'}}"></app-time-picker>
              </td>
            </tr>
          }
        </tbody>

      </table>
    </div>
  }

  <div class="btn-bottom">
    <app-btn-ext text="vacations_holiday_create" (clickEv)="save()" [isSpin]="isSaving" />
    {{"vacations_holiday_create_remarks_extra" | langVal}}
  </div>
  {{"vacations_holiday_create_remarks" | langVal}}



</div>
