@if(disabled){
  {{disabledValue}}
}
@else{
  <div class="flex-v-center">

    <select [tabindex]="tabindex" [title]="(title_ph_lang || fieldName) | langVal" [autofocus]="autofocus" class="form-control" [ngClass]="className" [disabled]="disabled" [(ngModel)]="actualObj[fieldName]" (change)="onchange()" [attr.name]="fieldName" #elementEl style="padding:0;"
      [class.validation-checked]="errors[fieldName]===null" [class.validation-error]="errors[fieldName]">
      @if(allChoice){
        <option value="-1">{{"all_options" | langVal}}</option>
      }
      @if(hasNone){
        <option value="0">{{placeholder || ( (title_ph_lang || fieldName) | langVal)}}</option>
      }
      @for(option of options;track option){
        <option [value]="option[optionFieldId]">
          {{(avoidLang || !option.lang)?option[optionFieldName]:option.lang}}
        </option>
      }
    </select>
    @if(hasPlus){
      <button [titleLang]="plusLang | langVal" class="btn btn-icon" (click)="plusClick.emit()" icon="plus"></button>
    }
  </div>
  @if(errors[fieldName]){
    <p class="error">{{errors[fieldName]}}</p>
  }
}
