import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService, pluck } from '@app/services/api.service';
import { LangService } from '@app/services/lang.service';
import { ModalService } from '@app/services/modal.service';
import { StoreService } from '@app/services/store.service';

@Component({
  selector: 'app-patient-test-fill',
  templateUrl: './patient-test-fill.component.html',
  styleUrl: './patient-test-fill.component.css'
})
export class PatientTestFillComponent implements OnInit {
  siteData:any=[];
  res:any=null;
  test_id:number=null;
  testDone:boolean=false;
  @Input() code:string="";
  constructor(private apiService: ApiService,protected route: ActivatedRoute,public modalService: ModalService,public lang: LangService,private store:StoreService) { }

  async ngOnInit(){

    await this.store.timeout(500);
    this.init();
  }
  async init(){
    const res=await this.apiService.post("patient_test_fill_init",this,"code");
    if(!res){return;}
    this.res=res;
    const test_id=res.test_id;
    this.siteData={tests:res.tests,testquestions:res.testquestions};

    sessionStorage.setItem('siteData', JSON.stringify(this.siteData));
    this.apiService.restartLogoutTimeout();
    this.apiService.postLoginPatient();


    if(!this.siteData?.tests?.length || !test_id){return;}
    const test=this.siteData.tests.find(it=>it.id==test_id);
    if(!test){return;}
    this.test_id=test_id;
  }
  async testFinish(test:any){
    if(!test){return;}
    this.testDone=true;
    const wantsDownload=await this.modalService.openMulti("confirm", {
      actionLang: this.lang.getVal("to_download")
    });
    const avoid_download=!wantsDownload;

    if(avoid_download){
      const resPdf:any=await this.apiService.download("patient_answer_test",{...test,code:this.code,avoid_download});
    }
    else{
      await this.store.downloadToastFunc(async ()=>{
        const resPdf:any=await this.apiService.download("patient_answer_test",{...test,code:this.code,avoid_download});
        if(!resPdf?.size){
          return this.modalService.openToast(this.lang.getVal("save_failed"));
        }
        this.apiService.downloadFile(resPdf, this.lang.getVal("test")+".pdf");
      });
    }

    this.modalService.openToast(this.lang.getVal("filled_test_sent"));
  }

}
