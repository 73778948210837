import { Component, OnInit } from '@angular/core';
import {Userdetail_line} from "@app/definitions/types";
import { ApiService } from '@app/services/api.service';
import { LangService } from '@app/services/lang.service';
import { ModalService } from '@app/services/modal.service';
import { StoreService } from '@app/services/store.service';
import { ValidatorService } from '@app/services/validator.service';

@Component({
  selector: 'app-userdetail-lines-manage',
  templateUrl: './userdetail-lines-manage.component.html',
  styleUrl: './userdetail-lines-manage.component.css'
})
export class UserdetailLinesManageComponent implements OnInit {
  newUserdetail_line:Userdetail_line;
  userdetail_lines:Userdetail_line[]=[];
  validationRules: any = {	//default - is new contact
    subject_web: { not_empty: true},
    description_web: { not_empty: true },
  };

  isNewSaving:boolean=false;

  constructor(public lang: LangService, public modalService: ModalService, protected apiService: ApiService, protected validator: ValidatorService,protected store:StoreService) { }

  async ngOnInit() {
    const res:any=await this.apiService.get_gen_items("userdetail_lines");
    this.userdetail_lines=res.userdetail_lines;
    this.userdetail_lines.forEach(row=>row.errors={});

    this.prepRows();
    this.createNewRow();
  }
  validateEntireForm(row:Userdetail_line) {	//validate all fields and detrmine if can be submitted (or has errors)
    row.errors = {};
    row.isFormSubmittable = this.validator.validateEntireForm(row, row.errors, this.validationRules,this.userdetail_lines);
  }
  validateField(row:Userdetail_line,fieldName:string,isNewRow:boolean=false){
    if(isNewRow){
      return this.validateEntireForm(row);
    }
    if(this.validationRules[fieldName]){
      this.validator.validateField(row, row.errors, fieldName, this.validationRules[fieldName], this.userdetail_lines);
    }
  }
  async saveField(row:Userdetail_line,fieldName:string){
    if(row.errors[fieldName]){return;}
    const res:any=await this.apiService.save_gen_field("userdetail_lines",row.id,fieldName,row[fieldName]);
    if(res?.success){
      this.modalService.openToast(this.lang.getVal("update_successfuly"));
    }
    else{
      this.modalService.openToast(this.lang.getVal("save_failed"));
    }
  }

  async deleteRow(row:Userdetail_line){
    const confirmDelete = await this.modalService.openMulti("confirm",{
      objectName: row.subject_web
    });
    if (!confirmDelete) { return; }	//if confirmation failed - return

    row.isDeleting=true;
    const res:any=await this.apiService.delete_gen_item("userdetail_lines", row.id);
    row.isDeleting=true;
    if (res.success) {
      this.modalService.openToast(this.lang.getVal("delete_successful"));
      this.userdetail_lines=this.userdetail_lines.filter(it=>it.id!=row.id);
      this.prepRows();
    }
    else{
      this.modalService.openToast(this.lang.getVal("delete_failed"));
    }
  }
  async saveNewRow(){
    if(!this.newUserdetail_line.isFormSubmittable){return;}
    const sendObj={...this.newUserdetail_line};
    delete sendObj.errors;
    this.isNewSaving=true;
    const res:any=await this.apiService.save_gen_item("userdetail_lines",sendObj);
    this.isNewSaving=false;
    if(res?.id){
      res.errors={};
      this.userdetail_lines=[...this.userdetail_lines,res];
      this.prepRows();
      this.createNewRow();
      this.modalService.openToast(this.lang.getVal("saved_successfully"));
    }
    else{
      this.modalService.openToast(this.lang.getVal("save_failed"));
    }
  }
  createNewRow(){
    this.newUserdetail_line=new Userdetail_line();
  }
  prepRows(){
    this.userdetail_lines.sort((a:Userdetail_line,b:Userdetail_line)=>a.order_num-b.order_num);
    this.userdetail_lines.forEach(row=>{
      row.is_highest_order=false;
      row.is_lowest_order=false;
    });
    if(this.userdetail_lines.length){
      this.userdetail_lines[0].is_lowest_order=true;
      this.userdetail_lines[this.userdetail_lines.length-1].is_highest_order=true;
    }
  }
  async moveOrderNum(row:Userdetail_line,toLowerOrderNum:boolean){
    const rowToFlip=this.store.findClosestOrderNum(this.userdetail_lines,row.order_num,toLowerOrderNum);
    if(rowToFlip){
      const flipOrderNum=rowToFlip.order_num;
      row.isFlipOrderSending=true;
      const res=await this.apiService.post("flip_order_num",{id1:row.id,id2:rowToFlip.id,tableName:"userdetail_lines"});
      row.isFlipOrderSending=false;
      if(res.success){
        this.modalService.openToast(this.lang.getVal("update_successfuly"));
        rowToFlip.order_num=row.order_num;
        row.order_num=flipOrderNum;
        this.prepRows();
      }
      else{
        this.modalService.openToast(this.lang.getVal("save_failed"));
      }
    }
  }



}
