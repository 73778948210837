import { Component, Input, OnInit } from '@angular/core';
import { CcParamsGen } from '@app/cc-params-gen/cc-params-gen';

const numberWithLeadingZero = num => (num < 10 ? "0" : "") + num;	//fills numbers to have leading zeros


@Component({
  selector: 'app-cc-params-automation',
  templateUrl: './cc-params-automation.component.html',
  styleUrl: './cc-params-automation.component.css'
})
export class CcParamsAutomationComponent extends CcParamsGen {	//inheriting most functions and fields!
  saveObjName: string = "automation";	//which part to save while updating


  openPatients_sms() {	//open the bank accounts modal (gen-table) filtered by this person's id
    this.modalService.openMulti("gen-table-display", {
      tableName: 'patients_sms',modalWidth:"modal-750"
    });
  }


  async openTerms() {	//open the error modal (in help mode, not error mode)
    const ret = await this.modalService.openMulti("terms-sms");
    if (ret) {
      this.configcliniq.consent_to_sms_contract = "yes";
      sessionStorage.setItem('cliniqData', JSON.stringify(this.configcliniq));
    }

  }

  validateEntireForm() {	//validate all fields and detrmine if can be submitted (or has errors)
    let validationFields={};
    if(this.configcliniq?.consent_to_sms_contract=='yes'){
      validationFields={
        name_on_message:{not_empty:true,regex:/^([a-zA-Z0-9- .]){1,11}$/}
      }

    }
    this.isFormSubmittable = this.validator.validateEntireForm(this.configcliniq, this.errors, validationFields);
  }

}

