<div class="main">
  <div>
    <button  (click)="mode='time-picker'">Time-Picker</button>
    <button  (click)="mode='date-picker'">Date-Picker</button>
    <button  (click)="mode='autocomplete'">Autocomplete</button>
  </div>
  <hr>
  @switch(mode){
    @case("time-picker"){
      <h3>Timepicker</h3>
      <div >
        <app-time-picker #time [actualObj]="timeTestObject" fieldName="time"  (changeEvent)="onTimeChange($event)">
        </app-time-picker>
        <span >{{timeTestObject.time}}</span>

        <button (click)="timeTestObject.time='22:14';">click</button>
      </div>

      <div >
        <app-time-picker >
        </app-time-picker>

      </div>
    }
    @case("date-picker"){
      <h3>Datepicker</h3>
      <div >
        <app-date-picker #date [actualObj]="dateTestObject" fieldName="date" (changeEvent)="onDateChange($event)" >
        </app-date-picker>
        <span >{{dateTestObject.date}}</span>

        <button (click)="dateTestObject.date='2021-01-12';date.callChange('ActualObject', 'CurrentDate')">click</button>
      </div>

      <div >
        <app-date-picker >
        </app-date-picker>

      </div>
    }
    @case("autocomplete"){
      <h3>Autocomplete</h3>
      <div >
        <app-gen-autocomplete placeholder="some_placeholder" #autocompleteInp [error]="autocompleteError"
          [searchStr]="autocompleteTestObject.name" [fuzzySearchableClasses]="['patients']"
          (chosenEvent)="onAutocompleteChange($event)" (keyUp)="autocompleteKeyUp($event)"
          [actualObj]="autocompleteTestObject" fieldName="patient_id"></app-gen-autocomplete>
        <span >{{dateTestObject.date}}</span>

        <button>click</button>
      </div>

      <div >
        <app-gen-autocomplete [fuzzySearchableClasses]="['patients']"></app-gen-autocomplete>

      </div>
    }
  }
</div>
