import { Component, Input, OnInit } from '@angular/core';
import { CcParamsGen } from '@app/cc-params-gen/cc-params-gen';

const numberWithLeadingZero = num => (num < 10 ? "0" : "") + num;	//fills numbers to have leading zeros


@Component({
	selector: 'app-cc-params-journal',
	templateUrl: './cc-params-journal.component.html',
	styleUrl: './cc-params-journal.component.css'
})
export class CcParamsJournalComponent extends CcParamsGen {	//inheriting most functions and fields!
  saveObjName: string = "journal";	//which part to save while updating
	hoursOPts: any[] = Array(24).fill(1).map((n, k) => ({ id: `${numberWithLeadingZero(k + 1)}:00`, name: `${numberWithLeadingZero(k + 1)}:00` }));	//hours from 01:00 to 23:00
	patienttypes: any[] = [];
  apiLink:string="";
  openPatients_sms() {	//open the bank accounts modal (gen-table) filtered by this person's id
    this.modalService.openMulti("gen-table-display", {
      tableName: 'patients_sms',modalWidth:"modal-750"
    });
  }

	ngOnInit() {
		this.patienttypes = this.store.getSiteDataTable("patienttypes");
    const timeFields = ["calendar_end_time", "time_on_holiday_evening", "calendar_start_time"];	//time fields to change the display to HH:MM if needed
    timeFields.forEach(timeField => {	//for each time field, if has seconds (HH:MM:SS) - rewrite without them (HH:MM)
      let split = this.configcliniq[timeField].split(":");
      if (split.length == 3) {	//has seconds
        this.configcliniq[timeField] = `${split[0]}:${split[1]}`;	//rewrite only with hours and minutes (first 2 elements)
      }
    })
    this.getApiKeys();
	}
	async openTerms() {	//open the error modal (in help mode, not error mode)
		const ret = await this.modalService.openMulti("terms-sms");
		if (ret) {
			this.configcliniq.consent_to_sms_contract = "yes";
			sessionStorage.setItem('cliniqData', JSON.stringify(this.configcliniq));
		}

	}
	openHelp() {	//open the error modal (in help mode, not error mode)
		this.modalService.openMulti("error");
	}
  validateEntireForm() {	//validate all fields and detrmine if can be submitted (or has errors)
    let validationFields={};
    if(this.configcliniq?.consent_to_sms_contract=='yes'){
      validationFields={
        name_on_message:{not_empty:true,regex:/^([a-zA-Z0-9- .]){1,11}$/}
      }

    }
		this.isFormSubmittable = this.validator.validateEntireForm(this.configcliniq, this.errors, validationFields);
	}
  async getApiKeys(){
    this.apiLink=(await this.store.getApiLink()).replace('sched','cal');
  }

  async copyApiLink(){
    navigator.clipboard.writeText(this.apiLink);
    this.modalService.openToast(this.lang.getVal("copy_link"));
    window.open(this.apiLink, '_blank');
  }

}

