import { LangService } from '@app/services/lang.service';
import { Component, OnInit, Input, EventEmitter, Output, ElementRef, ViewChild, booleanAttribute } from '@angular/core';
import { ExtendedInputFormClass } from '@app/definitions/types';
import { OptionsList } from '@app/definitions/opts';
import { InputsService } from '@app/services/inputs.service';

@Component({
	selector: 'app-gen-select',
	templateUrl: './gen-select.component.html',
	styleUrl: './gen-select.component.css'
})
export class GenSelectComponent extends ExtendedInputFormClass {
  OptionsList=OptionsList;
	@Input() optionsListName: string = "";	//an array of objects to display/choose, each looks like {value:4,name:"blah"} (both those field names can be changed)
	@Input() options: any[] = [];	//an array of objects to display/choose, each looks like {id:4,name:"blah"} (both those field names can be changed)
	@Input() optionFieldId: string = "id";	//the field name for the VALUE in options (default: "id")
	@Input() optionFieldName: string = "name";	//the field name for the DISPLAY in options (default: "name")
	@Input() allChoice: boolean = false;	//if true, has an "ALL" option above the rest (its calue is -1)
	@Input({transform:booleanAttribute}) hasNone: boolean = false;	//if true, has an "NONE" option above the rest (its calue is 0)
	@Input({transform:booleanAttribute}) avoidLang: boolean = false;
	@ViewChild("elementEl") elementEl: ElementRef;	//the select input. used for focus
	disabledValue: string = "";	//the value to display if the field is disabled

  @Input({transform:booleanAttribute}) hasPlus:boolean=false;
  @Input() plusLang:string="";
  @Output() plusClick = new EventEmitter();

	constructor(public lang: LangService, private inputsService:InputsService) { super(); }

	ngOnInit() {
    if(this.optionsListName){
      this.options=OptionsList[this.optionsListName];
    }
		this.inputsService.loadVarsToInput(this);
		if (this.disabled) {	//if disabled, search the options to find the DISPLAY
			let obj = this.options.find(it => it[this.optionFieldId] == this.actualObj[this.fieldName]);	//find an option with an id (or other VALUE field) identical to the connected object and field
			if (obj) {	//if found - set the disabled value
				this.disabledValue = obj[this.optionFieldName];
			}
		}
	}
	focus() {	//used by parent
		this.elementEl.nativeElement.focus();
	}


	onchange() {	//emit change to parent (nothing passed)
		this.changeEvent.emit();
	}


}
