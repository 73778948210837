import { Injectable } from '@angular/core';
import { MultiModalsComponent } from '@app/modals/multi-modals.component';

const baseZIndex: number = 1000;	//z index to start from for modals
const zIndexMult: number = 10;	//the z index steps for each modal


@Injectable({
	providedIn: 'root'
})
export class ModalService {
	stack: any[] = [];	//the stack of all modal names currently on screen
	modals: any = {};	//all modal components
	toastObject: any;	//the toast model
	constructor() { }

  getZIndex(){
    return baseZIndex + zIndexMult * (this.stack.length+this.multiComp.stack.length);
  }


	register(instance: any) {	//register this modal name and component model
		if (this.modals[instance.modalName]) {	//if already exists - must be wrong info in the modalName variable on one of them
			// return console.log(`Modal ${instance.modalName} already exists!`);
		}
		this.modals[instance.modalName] = instance;	//save in modals in its modalName key

		return Object.keys(this.modals).length;	//returns number of current modals registered
	}



	closeLatestModal() {	//close only the top most modal. returns if there was one (used by the ESC key handler)
    const modalName=this.stack[this.stack.length - 1];
    if(modalName=="multi"){
      this.multiComp.close();
      return true;
    }
		// if (this.stack.length) {	//has opened modals - close the topmost one
		// 	this.close(this.stack[this.stack.length - 1]);
		// 	return true;
		// }
		return false;	//no modals to close
	}

	registerToast(object: any) {	//register the toast component model
		this.toastObject = object;
	}
	openToast(message: string) {	//open toast with a message
		this.toastObject.show(message);
	}



	getScrollInfo() {	//gets scrol info for this modal
		if (!this.stack.length) { return { x: window.scrollX, y: window.scrollY } }	//no open modals - return window scrolls attributes
    const curModalName=this.stack[this.stack.length - 1];
    let instance:any;
    if(curModalName=="multi"){
      const curStackTop=this.multiComp.stack[this.multiComp.stack.length-1];
      instance=curStackTop?.instance;
    }
    else{
      instance=this.modals[curModalName];
    }

		const ne = instance.modalDialog.nativeElement.parentElement;	//get the top most modal's scroll (the DIV containing the dialog controls it)
		return { x: ne.scrollLeft, y: ne.scrollTop };
	}

  //////////
  multiComp:MultiModalsComponent;
  isOpening:any=null;
  registerMulti(object:any){
    this.multiComp=object;
  }

  openMulti(componentClass:any,data:any=null){
    if(this.isOpening===componentClass){return;}
    this.isOpening=componentClass;
    setTimeout(()=>{
      this.isOpening=null;
    },1000);
    this.stack.push("multi");
    return this.multiComp.open(componentClass,data);
  }
  changeMultiWidth(cls:string){
    const stack=this.multiComp?.stack;
    if(stack && stack.length){
      const inst=stack[stack.length-1];
      if(inst){
        inst.instance.modalWrapperClass=cls;
      }
    }
  }


}
