import { Component, OnInit } from '@angular/core';
import { ModalService } from '@app/services/modal.service';
import { StoreService } from '@app/services/store.service';

@Component({
	selector: 'app-toast',
	template: `<div class="snackbar" [class.doShow]="isShow" >{{message}}</div>`
})
export class ToastComponent implements OnInit {	//outputs the toast message
	message: string = "";	//actual written message
	isShow: boolean = false;	//is in show state flag
  inTimeout:boolean=false;
  timeout:any=null;
	constructor(public modalService: ModalService,private store:StoreService) { }

	ngOnInit() {	//register with the modal service
		this.modalService.registerToast(this);
	}

	async show(message: string) {	//show the message

    if(this.timeout){
      if(message==this.message){
        return;
      }
      this.isShow=false;
      this.timeout=null;
      await this.store.timeout(50);
      this.show(message);
      return;
    }

		this.message = message;	//update the message
		this.isShow = true;	//turn the show flag true
    this.setTimeout();
	}
  setTimeout(){
    this.timeout=setTimeout(() => {	//after 3 seconds - turn the show flag back to false

      this.isShow = false;
      this.timeout=null;
    }, 3000);

  }

}
