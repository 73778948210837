import { Pipe, PipeTransform } from '@angular/core';
import { LangService } from '@app/services/lang.service';

@Pipe({
	name: 'configVal'
})
export class ConfigValPipe implements PipeTransform {
	constructor(private lang: LangService) { }

	transform(value: string) {
		return this.lang.getConfigVal(value);
	}

}
