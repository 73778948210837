import { GenTableComponent } from '@app/gen-table/gen-table.component';
import { CcParamsGen } from './../cc-params-gen/cc-params-gen';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ConfigDefinitions, AccountantScheduledReport, FollowupserieSave } from '@app/definitions/types';
import { GenAutocompleteComponent } from "@app/gen-inputs/gen-autocomplete/gen-autocomplete.component";
import { OptionsList } from '@app/definitions/opts';
import { pluck } from '@app/services/api.service';

@Component({
	selector: 'app-cc-params-accountant',
	templateUrl: './cc-params-accountant.component.html',
	styleUrl: './cc-params-accountant.component.css'
})


export class CcParamsAccountantComponent extends CcParamsGen {	//inheriting most functions and fields!
	saveObjName: string = "accountant";	//which part to save while updating
	isFormSubmittable: boolean = false;
	contactNeedsEmail: boolean = false;
	isSaving: boolean = false;
	isSendingAll: boolean = false;
  checkboxes:string[] = OptionsList.report_name_opts.map(it=>it.value);

	scheduledReport: AccountantScheduledReport = new AccountantScheduledReport();
	@ViewChild('personFuzzyInput') personFuzzyInput: GenAutocompleteComponent;
	@ViewChild('scheduledReportsTbl') scheduledReportsTbl: GenTableComponent;

	updScheduledReports(fieldName: string, value: any) {
		this.scheduledReport[fieldName] = value;
		this.validateEntireForm();
	}
	async onContactChoose(obj: any = null) {
		this.contactNeedsEmail = false;
		this.validateEntireForm();
		if (obj?.id) {


      const res:any=await this.apiService.post("contact_has_email",obj,"id");
      this.contactNeedsEmail = !res?.email;
      this.validateEntireForm();
		}
	}

	onNewContact() {
		this.scheduledReport.contact_id = 0;
		if (this.scheduledReport.is_new_contact == 'yes' && !this.scheduledReport.name) {
			this.scheduledReport.name = this.personFuzzyInput.searchStr;
		}
		this.personFuzzyInput.searchStr = "";
		this.validateEntireForm();

	}
  flipCheck(fieldName:string){
    this.scheduledReport[fieldName]=!this.scheduledReport[fieldName];
    this.updateReportList();
  }


	validateEntireForm() {	//validate all fields and detrmine if can be submitted (or has errors)
		let fieldsValidationObjects: any = {	//default - is new contact
			name: { not_empty: true },
			email: { email: null, not_empty: true },
		};
		if (this.scheduledReport.is_new_contact != 'yes') {
			fieldsValidationObjects = {
				contact_id: { is_legit_fuzzy_value: true }
			};
			if (this.contactNeedsEmail) {
				fieldsValidationObjects.email = { email: null, not_empty: true };
			}
		}
		this.errors = {};

		this.isFormSubmittable = this.validator.validateEntireForm(this.scheduledReport, this.errors, fieldsValidationObjects);

		const hasAnyCheckbox = this.checkboxes.some(it => this.scheduledReport[it]);	//check for AT LEAST one checkbox name, that has a TRUE value in the scheduledReport obj (meaning its checkbox is checked)
		if (!hasAnyCheckbox) {
			this.errors.checkboxes = this.lang.getVal("no_report_was_chosen");
			this.isFormSubmittable = false;
		}

	}

	updateReportList() {
		this.validateEntireForm();
	}

	async save() {
		if (this.isSaving) { return; }
		this.isSaving = true;
		const res:any=await this.apiService.post("add_new_scheduled_reports",this.scheduledReport);
    this.isSaving = false;
    if (res.success) {
      this.modalService.openToast(this.lang.getVal("created_successfully"));

      if (res.newContact) {
        this.store.addGenItemRow("contacts", this.store.removeDecItem(res.newContact));
      }
      this.scheduledReportsTbl.getItems();

      this.scheduledReport = new AccountantScheduledReport();
      this.contactNeedsEmail = false;
      if (this.personFuzzyInput) {
        this.personFuzzyInput.emptySearchStr();
      }
    }
	}
	// this.isFormSubmittable = this.validator.validateEntireFormGen(this.newRow, this.collectionFields,this.objectCollection);

	async send_all_reports(){
		this.isSendingAll=true;
    await this.apiService.post("send_all_reports");
    this.isSendingAll=false;
    this.modalService.openToast(this.lang.getVal("resend_all_reports"));
	}
}

