@if(user?.id){
  @if((permsService?.owner_has_users && isOwner) || !permsService?.owner_has_users || permsService?.perms?.administrative){
    <div>
      <app-btn-ext [disabled]="!admininvoiceObjs.length" (clickEv)="print()" [isSpin]="isDownloading" text="print_multiple_invoices" />
      <button class="btn btn-primary" titleLang="payment" routerLink="/purchases/create">
        {{"payment" | langVal}}
      </button>
    </div>

    <div>
      <app-gen-table filterField="user_id" [filterFieldValue]="user?.id" tableName="admininvoices" isCheckItems
                      (checkedItemsChanged)="updateAdmininvoicesIds($event)" />
    </div>
    <div style="margin-top:50px;">
      <app-gen-table tableName="affiliated_users" />
    </div>
  }
  @else{
    <app-gen-table tableName="finResource_person_list" filterField="user_id" [filterFieldValue]="user?.id" />
  }
}
