<div style="padding: 40px 40px;background-color: white;">
  @if(res){
    @if(submitted){
      <p> {{"thank_you_for_filling" | langVal}} ,</p>
<!--      {{res.name}}-->
    }
    @else{

      <p> {{"you_are_invited_by" | langVal}}  {{res.user_name}} {{"to_patient_fill_form" | langVal}} </p>

      @if(res?.patient_details_fields_manage?.name?.show){
        <div class="row form-group">
          <div class="colstat colstat-big">
            <label>{{"patient_name" | langVal}}</label>
            <app-gen-input [tabindex]="1" autofocus="autofocus" [actualObj]="patient" [errors]="errors" fieldName="name" title_ph_lang="patient_name" (keyupEvent)="validateEntireForm()" />
          </div>
        </div>
      }
      @if(res?.patient_details_fields_manage?.id_number?.show){
        <div class="row form-group">
          <div class="colstat colstat-big">
            <label>{{"id_number" | langVal}}</label>
            <app-gen-input [tabindex]="2" [actualObj]="patient" [errors]="errors" fieldName="id_number" (keyupEvent)="validateEntireForm()" />
          </div>
        </div>
      }
      @if(res?.patient_details_fields_manage?.email?.show){
        <div class="row form-group">
          <div class="colstat colstat-big">
            <label>{{"email" | langVal}}</label>
            <app-gen-input [tabindex]="3" [actualObj]="patient" [errors]="errors" fieldName="email" (keyupEvent)="validateEntireForm()" />
          </div>
        </div>
      }
      @if(res?.patient_details_fields_manage?.phone?.show){
        <div class="row form-group">
          <div class="colstat colstat-big">
            <label>{{"phone" | langVal}}</label>
            <app-gen-input [tabindex]="4" [actualObj]="patient" [errors]="errors" fieldName="phone" (keyupEvent)="validateEntireForm()" />
          </div>
        </div>
      }

      @if(res?.patient_details_fields_manage?.marital_status?.show){
        <div class="row form-group">
          <div class="colstat colstat-small-triple">
            <label>{{"marital_status" | langVal}}</label>
            <app-gen-button-group [actualObj]="patient" fieldName="marital_status" optionsListName="marital_status_opts" (changeEvent)="validateEntireForm()" [errors]="errors" />
          </div>
        </div>
      }
      @if(res?.patient_details_fields_manage?.gender?.show){
        <div class="row form-group">
          <div class="colstat colstat-small">
            <label>{{"gender" | langVal}}</label>
            <app-gen-button-group [actualObj]="patient" fieldName="gender" optionsListName="gender_opts" (changeEvent)="validateEntireForm()" [errors]="errors" />
          </div>
        </div>
      }
      @if(res?.patient_details_fields_manage?.remarks?.show){
        <div class="row form-group">
          <div class="col-12">
            <label>{{"remarks" | langVal}}</label>
            <app-ta-expands [tabindex]="5" [actualObj]="patient" [errors]="errors" fieldName="remarks" (keyupEvent)="validateEntireForm()" />
          </div>
        </div>
      }
      <div class="modal-btns" >
        <app-btn-ext text="save" [disabled]="!isFormSubmittable" [tabindex]="10" (clickEv)="save()" [isSpin]="isSaving" />
      </div>
    }
  }

</div>
