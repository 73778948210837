import { Component, ElementRef, EventEmitter, HostListener, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { MouseOrTouchEvent } from '@app/definitions/types';
import {LangService} from "@app/services/lang.service";

@Component({
  selector: 'app-canvas-write',
  templateUrl: './canvas-write.component.html',
  styleUrl: './canvas-write.component.css'
})
export class CanvasWriteComponent implements OnInit {
  @ViewChild("canvas") canvas:ElementRef<HTMLCanvasElement>;
  ctx:CanvasRenderingContext2D;
  isMouseDown:boolean=false;
  mmFunc:any=null;
  width:number=300;
  height:number=150;
  strokeStyle:string="black";
  lineWidth:number=4;
  imgSrc:any=null;
  @Input() error:string=null;
  @Output() imageOutput=new EventEmitter();

  constructor(protected zone: NgZone,private lang:LangService) { }

  ngOnInit() {
    this.mmFunc=this.mousemove.bind(this);
  }

  ngAfterViewInit(){
    this.ctx=this.canvas.nativeElement.getContext("2d");
    this.ctx.strokeStyle=this.strokeStyle;
    this.ctx.lineWidth=this.lineWidth;
  }


  getXYMouseOrTouch(ev:MouseOrTouchEvent){
    if(!ev.touches){
      return [ev.offsetX,ev.offsetY];
    }
    const {left,top}=this.canvas.nativeElement.getBoundingClientRect();
    const {clientX,clientY}=ev.touches[0];
    const x=clientX-left;
    const y=clientY-top;
    return [x,y];
  }

  mousedown(ev:MouseOrTouchEvent){
    ev.preventDefault();
    ev.stopPropagation();
    ev.stopImmediatePropagation();

    const [x,y]=this.getXYMouseOrTouch(ev);
    this.isMouseDown=true;

    this.ctx.beginPath();
    this.ctx.moveTo(x,y);
    this.drawXY(x+2,y+2);
    this.ctx.moveTo(x,y);

    this.zone.runOutsideAngular(() => {	//register event listener OUTSIDE ANGULAR PROCESS, so that detection would be avoided (much better performance)
			window.document.addEventListener('mousemove', this.mmFunc);
			window.document.addEventListener('touchmove', this.mmFunc);
		});

  }

  @HostListener('document:touchend', ["$event"])
  @HostListener('document:mouseup', ["$event"])
  mu(ev: MouseOrTouchEvent) {



    this.ctx.closePath();
    if(this.isMouseDown){
      this.output();
    }

    this.isMouseDown=false;
    this.zone.runOutsideAngular(() => {
      window.document.removeEventListener('mousemove', this.mmFunc);
      window.document.removeEventListener('touchmove', this.mmFunc);
    });
	}


  mousemove(ev:MouseOrTouchEvent){
    ev.preventDefault();
    ev.stopPropagation();
    ev.stopImmediatePropagation();
    const [x,y]=this.getXYMouseOrTouch(ev);
    if(x<0 || y<0 || x>this.width || y>this.height){return;}
    this.drawXY(x,y);
  }
  drawXY(x:number,y:number){
    this.ctx.lineTo(x,y);
    this.ctx.stroke();

  }
  reset(){
    this.ctx.clearRect(0,0,this.width,this.height);
    this.imageOutput.emit(null);
  }
  output(){
    const imgBlob=this.canvas.nativeElement.toDataURL();
    this.imageOutput.emit(imgBlob)

    // this.imgSrc=imgBlob;
  }

}
