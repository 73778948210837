import { Component, OnInit } from '@angular/core';
import { Autotext, Test } from '@app/definitions/types';
import { Modalable } from '@app/Modalable';

@Component({
  selector: 'app-autotext-menu',
  templateUrl: './autotext-menu.component.html',
  styleUrl: './autotext-menu.component.css'
})
export class AutotextMenuComponent extends Modalable {
  modalTitleLang: string="autotexts";
  modalWidth: string="modal-small-width";

  autotexts: Autotext[] = [];	//the list of current autoTexts
  patient_id: number = 0;
  showAll: boolean = false;

  ngOnInit() {	//when called to open get the autoTexts from DB
    this.getAutotexts();
  }
  async getAutotexts(obj: any = null) {	//on open OR return from "add-item" autoTexts modal
    if (obj) {	//if returned with new row - close this modal and deliver the next html_autotext to the component who OPENED this modal (home-meeting)
      this.close(obj.html_autotext);
      return;
    }
    this.autotexts = [];
    const res:any=await this.apiService.get_gen_items("autotexts");
    this.autotexts = res.autotexts;
  }

  flipShowAll(){
    this.showAll=!this.showAll;
    this.autotexts.forEach(it=>it.showText=this.showAll);
  }

  async openAutotextModal() {	//open the autotexts gen-table modal

    const obj = await this.modalService.openMulti("gen-table-add-item",{
      tableName: 'autotexts',
    });
    this.getAutotexts(obj);	//when add-item modal is closed, will pass the value (either new row or null) to this function
  }
  async openinsertAutotest(){
    await this.modalService.openMulti("insert-autotext",{});
    this.getAutotexts();
  }
  async openStatusModal() {	//open the status editing modal
    const response = await this.modalService.openMulti("status")
    this.close(response);
  }
  async openUpdateAutoTextTable(autotext:any){
    this.modalService.openMulti("gen-table-display",{
      tableName: 'autotexts',
    });
    this.getAutotexts();
  }
  async openUpdateAutotext(autotext:any){
    await this.modalService.openMulti("insert-autotext",{autotext});
    this.getAutotexts();
  }
  async saveField(row:Autotext){
    const res = await this.apiService.save_gen_field("autotexts", row.id, "html_autotext", row.html_autotext);
    if (res.success) {
      this.store.updGenItemRowField("autotexts", row.id, "html_autotext", row.html_autotext);
      this.modalService.openToast(this.lang.getVal("update_successfuly"));
    }
  }

}
