<div >
  <h4 class="gen-title">{{"calendar_config" | langVal}}</h4>
  <div class="row">
    <div class="col-6   col-md-2 col-lg-1">
      <ng-container *ngTemplateOutlet="inp;context:{fieldName:'break_length',tabindex:13}" />
    </div>
    <div class="col-6   col-md-2 ">
      <label>{{"patient_type" | langVal}}</label>

      <app-gen-select [tabindex]="14"  [actualObj]="configcliniq"
        [errors]="errors" fieldName="defaultpatienttype" title_ph_lang="patienttype"
        (changeEvent)="validateAndSave()" [options]="patienttypes">

      </app-gen-select>


    </div>
  </div>
  <div class="row">
    <div class="col-4  col-md-2 ">
      <label>{{"calendar_start_time" | langVal}}</label>

      <app-gen-select [tabindex]="14"  [actualObj]="configcliniq"
        [errors]="errors" fieldName="calendar_start_time" (changeEvent)="validateAndSave()" [options]="hoursOPts"></app-gen-select>


    </div>
    <div class="col-4 col-md-2 ">
      <label>{{"calendar_end_time" | langVal}}</label>

      <app-gen-select [tabindex]="14"  [actualObj]="configcliniq"
        [errors]="errors" fieldName="calendar_end_time" (changeEvent)="validateAndSave()" [options]="hoursOPts"></app-gen-select>


    </div>
    <div class="col-4 col-md-2 ">
      <label>{{"time_on_holiday_evening" | langVal}}</label>

      <app-gen-select [tabindex]="14"  [actualObj]="configcliniq"
        [errors]="errors" fieldName="time_on_holiday_evening" (changeEvent)="validateAndSave()" [options]="hoursOPts"></app-gen-select>
    </div>

  </div>
  <hr>

  <h4 titleLang="smses">
    <span>{{ "smses" | langVal}}</span>
  </h4>

  @if(permsService?.perms?.administrative){
    <div class="row">
      <div class="col-6">
        @if(configcliniq?.consent_to_sms_contract=='no'){
          <span>
            {{"to_sms_terms_description" | langVal}}:
            <button titleLang="to_sms_terms" class="btn btn-primary" (click)="openTerms()">{{"to_sms_terms" |
              langVal}}</button>
          </span>
        }
        @else{
          <p>{{"sms_approved_cancel" | langVal}}</p>
        }
      </div>
    </div>

    @if(configcliniq?.consent_to_sms_contract=='yes'){
      <div style="background-color: #e5f6df; padding:10px;">

        <div class="row">
          <div class="col-6  col-md-3 ">
            <ng-container *ngTemplateOutlet="inp;context:{fieldName:'name_on_message'}" />
          </div>
          <div class="col-3  col-md-2 ">
            <ng-container *ngTemplateOutlet="btnGroup;context:{fieldName:'time_of_sms',optionsListName:'morning_evening'}" />
          </div>
          <div class="col-3   ">
            <ng-container *ngTemplateOutlet="inp;context:{fieldName:'days_before_meeting_message',title:'days_to_sms'}" />
          </div>
        </div>
        <div class="row">
          <div class="col-6  col-md-3 ">{{'name_should_contain' | langVal}}</div>
          <div class="col-6  col-md-3 ">{{'time_of_sms_description' | langVal}}</div>
        </div>
        <div class="row">
          <div class="col-6  col-md-2 ">
            <button titleLang="patients_sms" tabindex="-1" class="btn btn-primary" (click)="openPatients_sms()">{{"patients_sms" | langVal}}</button>
          </div>
        </div>
      </div>
    }
  }

  @if(permsService?.perms?.administrative && permsService.owner_has_users){
    <div class="row">
      <div class="col-12 col-md-3 ">
        <ng-container *ngTemplateOutlet="btnGroupYesNo;context:{fieldName:'time_room_allocation'}" />
      </div>
      <div class="col-12 col-md-3 ">
        <ng-container *ngTemplateOutlet="btnGroupYesNo;context:{fieldName:'show_is_assoc'}" />
      </div>
    </div>
  }
<!-- @if(apiLink){-->
    <div class="row">
      <div class="col-12">  <!--@if(!permsService?.owner_has_users){}-->
        <button class="btn btn-primary" (click)="copyApiLink()" titleLang='copy_external_calendar_link'>
          {{"copy_external_calendar_link" | langVal}}</button>
      </div>
    </div>
<!--  }-->
  <hr class="separation-line">
</div>


<app-cc-sections title="calendar_config" section="journal" [configcliniq]="configcliniq" ></app-cc-sections>

<ng-template #inp let-fieldName="fieldName" let-title="title" let-tabindex="tabindex">
  <label>{{(title || fieldName) | langVal}}</label>
  <app-gen-input [tabindex]="tabindex || -1"  [actualObj]="configcliniq" [errors]="errors" [fieldName]="fieldName"
  [title_ph_lang]="title || fieldName" (keyupEvent)="validateAndSave()"  />
</ng-template>
<ng-template #btnGroup let-fieldName="fieldName" let-optionsListName="optionsListName">
  <label>{{fieldName | langVal}}</label>
  <app-gen-button-group [actualObj]="configcliniq" [fieldName]="fieldName" [optionsListName]="optionsListName" (changeEvent)="validateAndSave()" />
</ng-template>
<ng-template #btnGroupYesNo let-fieldName="fieldName" >
  <ng-container *ngTemplateOutlet="btnGroup;context:{fieldName:fieldName,optionsListName:'yes_no_opts'}" />
</ng-template>
