import { GenAutocompleteComponent } from '@app/gen-inputs/gen-autocomplete/gen-autocomplete.component';
import { Component, OnInit, ViewChild } from '@angular/core';

type modeOpts = null | "time-picker" | "date-picker" | "autocomplete";

@Component({
	selector: 'app-playground',
	templateUrl: './playground.component.html',
	styleUrl: './playground.component.css'
})
export class PlaygroundComponent implements OnInit {
	dateTestObject: any = { date: "2021-02-08" }
	timeTestObject: any = { time: "11:14" }
	autocompleteTestObject: any = { patient_id: 1, name: "first patient" };
	autocompleteError: string = "";
	@ViewChild("autocompleteInp") autocompleteInp: GenAutocompleteComponent;
	mode: modeOpts = null;
	constructor() { }

	ngOnInit() {
	}

	onDateChange(obj: any) {
	}

	onTimeChange(obj: any) {
	}
	onAutocompleteChange(obj: any) {
	}
	autocompleteKeyUp(obj: any) {
	}

}
