@if(isStatic){
  {{actualObj[fieldName]}}
}
@else{
  <div class="time-picker" style="width:100px;"  (click)="clickTop($event)">
    <!-- display: inline-block;  -->

    <div [class.input-group]="addonObject">
      @if(addonObject?.before){
        <span class="input-group-addon" [preIcon]="addonObject.before.icon">{{addonObject?.before?.text}}</span>
      }
<!--      type="number" pattern="[0-9]*" inputmode="numeric"-->
      <input #inpEl [disabled]="disabled" (keydown)="onKeyDown($event)" [tabIndex]="tabindex" inputmode="none"
        [(ngModel)]="actualObj[fieldName]" (click)="openPopup($event)" [placeholder]="placeholder || ( (title_ph_lang || fieldName) | langVal)" [title]="(title_ph_lang || fieldName) | langVal"
        class="form-control" [ngClass]="className" />


      @if(addonObject?.after){
        <span class="input-group-addon" [preIcon]="addonObject.after.icon">{{addonObject?.after?.text}}</span>
      }

    </div>
<!--   [style.width]="popupWidth+'px'" [style.height]="popupHeight+'px'"-->
    @if(isPopupOpen){
      <div class="popup" [style.transform]="'translate(0px,'+popupProps.bottom+'px)'"
        [style.right]="(popupProps.right?popupProps.right+'px':'')"
        [style.left]="(popupProps.left?popupProps.left+'px':'')">
        <div class="cal-container">
          @if(mode){
            <div class="vals">
              @for(val of vals;track val){
                <div (click)="chVal(val)">{{val}}</div>
              }
            </div>
          }
          @else{
            <div class="cal-col">
              <div (click)="move('hour',true)" icon="arrow_up"></div>
              <div (click)="chMode('h')">{{hour<10?"0"+hour:hour}}</div>
              <div (click)="move('hour',false)" icon="arrow_down"></div>
            </div>
            <div class="cal-col">
              <div (click)="move('minute',true)" icon="arrow_up"></div>
              <div (click)="chMode('m')">{{minute<10?"0"+minute:minute}}</div>
              <div (click)="move('minute',false)" icon="arrow_down"></div>
            </div>
          }

        </div>
      </div>
    }
  </div>
  @if(errors[fieldName]){
    <p class="error">{{errors[fieldName]}}</p>
  }
}
