<div style="padding:20px;">
  <div class="row">
    <div class="col-12">
      <app-gen-button-group [actualObj]="this" fieldName="period" optionsListName="activity_periods"
      title_ph_lang="activity_period" />

      <button class="btn btn-icon" titleLang="expand" (click)="isClosed=!isClosed" [icon]="(isClosed?'expand':'narrow')"></button>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <app-activity-log-table [isClosed]="isClosed" [period]="period" />
    </div>
  </div>

  <div class="row">
    <div class="col-4">
      <app-table-wrap fieldsStr="status,count"  >
        @for(row of userNums;track row){
          <tr>
            @for(field of ['status','co']; track field){
              <td>{{row[field]}}</td>
            }
          </tr>
        }
      </app-table-wrap>
    </div>

  </div>

</div>
