import { Component, OnInit, Input, Output, EventEmitter, booleanAttribute } from '@angular/core';
import { OptionsList } from '@app/definitions/opts';
import { ExtendedInputFormClass } from '@app/definitions/types';
import { InputsService } from '@app/services/inputs.service';
import { LangService } from '@app/services/lang.service';

@Component({
	selector: 'app-gen-button-group',
	templateUrl: './gen-button-group.component.html',
	styleUrl: './gen-button-group.component.css'
})
export class GenButtonGroupComponent extends ExtendedInputFormClass {
  OptionsList=OptionsList;
	@Input() optionsListName: string = "";	//an array of objects to display/choose, each looks like {value:4,name:"blah"} (both those field names can be changed)
	@Input() options: any[] = [];	//an array of objects to display/choose, each looks like {value:4,name:"blah"} (both those field names can be changed)
	@Input() optionFieldValue: string = "value";	//the field name for the VALUE in options (default: "value")
	@Input() optionFieldName: string = "name";	//the field name for the DISPLAY in options (default: "name")
	@Input({transform:booleanAttribute}) isArray: boolean = false;
	@Input() className: string = "btn btn-default";	//class names to add to each button
	@Input({transform:booleanAttribute}) isTab: boolean = false;

	constructor(private inputsService:InputsService,protected lang:LangService) { super() }


	ngOnInit() {
    if(this.optionsListName){
      this.options=OptionsList[this.optionsListName];
      if(!OptionsList[this.optionsListName]){
        throw new Error("no OptionsList[this.optionsListName] "+this.optionsListName);
      }
    }
    if(this.isArray){
      this.options=this.options.map(it=>{
        const langVal=this.lang.getVal(it);
        return {[this.optionFieldName]:langVal,[this.optionFieldValue]:it};
      });
    }
		this.inputsService.loadVarsToInput(this);
	}

	onclick(value: string) {	//set the connected object and field to new value, and emit the change to a parent (passes the new value)
		this.actualObj[this.fieldName] = value;
		this.changeEvent.emit(value);
	}


}
