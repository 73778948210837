import { ActivatedRoute, Router } from '@angular/router';
import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { ValidatorService } from '@app/services/validator.service';
import { LangService } from '@app/services/lang.service';
import { ApiService } from '@app/services/api.service';
import { User } from '@app/definitions/types';
import { StoreService } from '@app/services/store.service';
import {PermsService} from "@app/services/perms.service";

@Component({
	selector: 'app-user',
	templateUrl: './user.component.html',
	styleUrl: './user.component.css'
})
export class UserComponent implements OnInit {

	user: User;
	admininvoiceObjs: any[] = [];
	isDownloading: boolean = false;
  cliniqData: any = null;
  isOwner: boolean = false;

	constructor(private apiService: ApiService, public lang: LangService,public permsService: PermsService, public validator: ValidatorService, private route: ActivatedRoute,private store:StoreService) { }

	async ngOnInit() {

		const res=await this.apiService.post("get_user_data");
    this.user = res;
    this.cliniqData = this.store.getCliniqDataOrExit();
    this.isOwner = this.cliniqData.has_users;
	}

	updateAdmininvoicesIds(arrItems: any[]) {
		this.admininvoiceObjs = arrItems;
	}

	async print() {
		if (!this.admininvoiceObjs.length) { return }
		this.isDownloading = true;
    for( let obj of this.admininvoiceObjs){
      const sendObj:any = { ...obj,doctype:"admininvoice" }; //,headture_id:this.headtureRow.id
      await this.store.downloadToastFunc(async ()=>{
        const res=await this.apiService.download("print_make_finresource",sendObj);
        if(!res?.size){return this.store.downloadFailedModal();}
        let name = `admininvoice_${obj.id}_copy.pdf`;
        this.apiService.downloadFile(res, name);
      });

    }

	}


}


