<div  >
  <h3>{{"patient_unification" | langVal}}</h3>
<div class="row">
  <div class="col-12">
      <div class="row">
        <div class="col-12">
          <label>
            {{"patient_source" | langVal}}
            @if(source){
              ({{source}})
            }
          </label>
          <app-gen-autocomplete title_ph_lang="patient_source" [error]="errors?.source" [fuzzySearchableClasses]="['patients']" (chosenEvent)="choosePatient($event,'source')" (keyUp)="keyUpPatient($event,'source')"></app-gen-autocomplete>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label>
            {{"patient_target" | langVal}}
            @if(target){
              ({{target}})
            }
          </label>
          <app-gen-autocomplete title_ph_lang="patient_target" [error]="errors?.target" [fuzzySearchableClasses]="['patients']" (chosenEvent)="choosePatient($event,'target')" (keyUp)="keyUpPatient($event,'target')"></app-gen-autocomplete>
        </div>
      </div>
      <div>
        <app-btn-ext text="unify" (clickEv)="save()" [disabled]="!isFormSubmittable" [isSpin]="isSaving" />
      </div>


    </div>
</div>
</div>
