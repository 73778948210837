<div class="container-fluid footer-bottom-mobile"  >
  <footer class="text-center d-none d-lg-block">
    <hr>
    <div >

      <span (click)="openHelp()" class="pointer">{{"contact_us" | langVal}} </span>
      &nbsp;&nbsp;|&nbsp;&nbsp;
      &nbsp;
      <span><a href="http://my-cliniq.com/" tabindex="-1" target="_blank">2016-2024 © My-Cliniq.com</a> </span>
      &nbsp;&nbsp;|&nbsp;&nbsp;
      <span> {{"version" | langVal}}  {{store.VERSION}}</span>

    </div>

  </footer>
</div>
