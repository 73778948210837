<div >
  <h4 class="gen-title">{{"zones" | langVal}}</h4>
  <div [style.height]="disp.length*totalHEach+'px'" style="position:relative;" >
    @for(zone of disp;track zone){
      <div #dragItems [attr.id]="zone.id" class="zone"  [style.top]="$index*totalHEach+'px'" (mousedown)="zoneClick(zone,$event)" [style.background-color]="'#90ee90'+(zone.show?'':'80')"  style="z-index:4;" >
        <app-icon [icon]="zone.show?'zone-eye':'zone-eye-off'" (click)="show(zone,$event)"  (mousedown)="$event.stopImmediatePropagation()" (mouseup)="$event.stopImmediatePropagation()" />
        {{zone.name+'s' | langVal}}
      </div>
    }
  </div>

</div>
