<div class="row">
  <div class="col-12 gen-tbl-header-top">
    <div class="gen-title" titleLang="followups">
      {{ "mixed_list" | langVal }}
    </div>

  </div>
<div class="d-flex">
  <div>
    <app-gen-button-group optionsListName="mixedList_desc_opts" title_ph_lang="followups" [actualObj]="this"
                          fieldName="withDescription"/>
  </div>
  <div>
    <app-gen-button-group optionsListName="mixedList_pm_opts" title_ph_lang="followups" [actualObj]="this"
                          fieldName="withPersonalMemorandum" (changeEvent)="saveCc()"    />
  </div>
</div>
</div>
  @if (showFutureMeetings == 'yes') {
    <div class="text-end">
      <button class="btn btn-primary " (click)="openFuses()">{{ "future_meetings" | langVal }}</button>
    </div>
  }


<hr>

<div class="row flex-inline-start">


    <div style="width: 350px">
      <label>{{ 'period' | langVal }}</label>
      <br>
      <app-gen-button-group [actualObj]="this" fieldName="currentPeriod" [options]="periodInputs" title_ph_lang="period" (changeEvent)="chPeriod()" />
    </div>

  <div class="d-flex"  style="width: 310px">
    @if (currentPeriod != 'all_options') {

      <div class="colstat colstat-price">
        <label>{{ 'from_date' | langVal }}</label>
        <app-date-picker #fromDate [actualObj]="this" fieldName="date_from"
                         (changeEvent)="currentPeriod='date_range';search()"
                         [addonObject]="{after:{icon:'calendar1'}}"/>
      </div>

      <div class="colstat colstat-price">
        <label>{{ 'to_date' | langVal }}</label>
        <app-date-picker #toDate [actualObj]="this" fieldName="date_to"
                         (changeEvent)="currentPeriod='date_range';search()"
                         [addonObject]="{after:{icon:'calendar1'}}"/>
      </div>
    }
  </div>
</div>

<div class="row form-group">

  <div class="col-6">
    <div class="gen-tbl-search" style="margin-inline: 0;">
      <app-gen-input [actualObj]="this" fieldName="searchStr" (keyupEvent)="search()"
                     title_ph_lang="search" [addonObject]="{after:{icon:'search'}}" [tabindex]="-1">
      </app-gen-input>
    </div>
  </div>
  <div class="col-6 no-mob text-end">
    <button class="btn btn-primary" (click)="print()">{{ 'print' | langVal }}</button>
    <button class="btn btn-primary" (click)="xlsxDownload()">{{ 'create_excel' | langVal }}</button>

  </div>
</div>

<div id="section-to-print-mixed-list" class="table-responsive">
  <table class="table">
    <thead>
    <tr>
      <th style="width:20%;" (click)="sort('date')" sorter fieldName="date" [displayName]="'date' | langVal"
          [sortField]="sortField" [sortIsAsc]="sortIsAsc"></th>
      <th style="width:10%;">{{ "arrived" | langVal }}</th>
      <th style="width:10%;">{{ "meetingtype" | langVal }}</th>
      <th style="width:10%;">{{ "price" | langVal }}</th>

      @if (permsService.owner_has_users) {
        <th style="width:10%;">{{ "assoc" | langVal }}</th>
      }
      <th style="width:10%;"></th>
    </tr>
    </thead>
      @for (row of objectCollectionShown; track row) {
        <tbody>
          <tr [class.grey]="$odd" (click)="openRow(row)" [class.pointer]="row?.type=='followup' && row.id!=followup_id">
            <td style="width:20%" [spinner]="row?.isFetching">
              <b>
                {{ row.date | dateFormat }}
                {{ row.date_to ? " - " + (row.date_to | dateFormat) : "" }}
              </b>
              <span>{{ store.timeNoSeconds(row.time) }}</span>
            </td>
            <!-- {{row?.assoc_names || ""}} -->
            <td style="width:10%"><b>{{ row.arrived=="yes" ? "✓" : ""  }}</b></td>
            <td style="width:10%"><b>{{ row.meetingtype_name }}</b></td>
            <td style="width:10%">{{ row?.price ? (row.price | finNumber) : "" }}</td>
            @if (permsService.owner_has_users) {
              <td style="width:10%">{{ row.assoc_names }}</td>
            }
            <td style="width:10%">{{ row.status == 'deleted' ? (row.status | langVal) : "" }}</td>
          </tr>
          @if (row.description && withDescription == 'yes') {
            <tr [class.grey]="$odd" (click)="openRow(row)" [class.pointer]="row?.type=='followup' && row.id!=followup_id">
              <td colspan="99" class="description-text">
                @switch (row.type) {
                  @case ("testfill") {

                    @if (row?.test) {
                      @if (row?.test?.html_text) {
                        <div><span style="font-weight: 700">{{ 'test' | langVal }}: </span> <span [innerHTML]=" row.test.html_text | safeHtml"></span></div>
                      } @else {
                        <app-test-display [test]="row.test"/>
                      }
                    }
                  }
                  @case ("labtestpatient") {
                    <b>{{ 'recommended' | langVal }}: </b>
                  }
                }
                @if(row.type !== "testfill"){
                  <span [innerHTML]="row.description | safeHtml"></span>
                }
              </td>
            </tr>
          }
          @if (withPersonalMemorandum == 'yes') {
            @if (row.personal_memorandum) {
              <tr [class.grey]="$odd" (click)="openRow(row)">
                <td [innerHTML]="row.personal_memorandum | safeHtml" colspan="99" style="white-space: normal;"></td>
              </tr>
            }
            @if (row.remarks) {
              <tr [class.grey]="$odd" (click)="openRow(row)">
                <td colspan="99"
                    style="white-space: normal;padding-left: 70px; padding-right: 70px;">
                <span><b>{{ 'personal_memorandum' | langVal }}: </b> <span
                  [innerHTML]="row.remarks  | safeHtml"></span></span></td>
              </tr>
            }
          }
          @if (row?.images?.length) {
            <tr [class.grey]="$odd">
              <td colspan="999">
                <div class="flex">
                  @for (imgObj of row.images; track imgObj) {
                    <img [src]="imgObj.url" class="fp-image" loading="lazy" (click)="openGallery(imgObj)"/>
                  }
                </div>
              </td>
            </tr>
          }
          <tr [class.grey]="$odd"  (click)="openRow(row)" [class.pointer]="row?.type=='followup' && row.id!=followup_id">
            <td style="height:20px;" colspan="999"></td>
          </tr>
        </tbody>
      }

  </table>
</div>
