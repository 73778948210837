import { Pipe, PipeTransform } from '@angular/core';
import { LangService } from '@app/services/lang.service';

@Pipe({
	name: 'langVal'
})
export class LangValPipe implements PipeTransform {
	constructor(private lang: LangService) { }

	transform(value: string) {
		// return this.lang.translation[value] || value;
		return this.lang.getVal(value);
	}

}
