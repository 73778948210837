<div>
  <div style="margin-bottom: 0;">
      <app-gen-input  [actualObj]="this" fieldName="currentSearch" (keyupEvent)="onKeyUp()" (click)="onClick($event)"
        title_ph_lang="contactway_search" [addonObject]="{after:{icon:'mobile'}}">
      </app-gen-input>
  </div>

  @if(doShow){
    <div class="items-container">
      <app-spin-or-content [isSpin]="isLoading">
        @for(patient of patients;track patient){
          <div class="patient-row"  (click)="openPatient(patient.id)">{{patient.str}}</div>
        }
        @empty{
          @if(hasFirstSearched){
            <div>{{'not_found' | langVal}}</div>
          }
        }
      </app-spin-or-content>
    </div>
  }

</div>
