import { EventsService } from '@app/services/events.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { ConfigDefinitions } from '@app/definitions/types';
import { ValidatorService } from '@app/services/validator.service';
import { LangService } from '@app/services/lang.service';

import { ApiService } from '@app/services/api.service';
import { Configcliniq } from '@app/definitions/types';
import { PermsService } from '@app/services/perms.service';
import { StoreService } from '@app/services/store.service';



@Component({
	selector: 'app-config-cliniq',
	templateUrl: './config-cliniq.component.html',
	styleUrl: './config-cliniq.component.css'
})
export class ConfigCliniqComponent implements OnInit {
	@Input() innerSectionName: string = "";	//current inner section
	configcliniq: Configcliniq;	//the config object from the DB
	showMenu: boolean = false;	//show the menu or hide (relevant only in mobile display)

	sideItems: any[] = [	//each item has a sectionName that can also be used by the url, icon, lang for display
		{ sectionName: "user", icon: "sub_user", lang: "userdetails" },
    { sectionName: "journal", icon: "calendar", lang: "calendar_config" },
		{ sectionName: "patients", icon: "patient", lang: "patients" },
		{ sectionName: "contacts", icon: "contact", lang: "contacts" },
    { sectionName: "headtures", icon: "header", lang: "headtures" },
		/*{ sectionName: "holiday", icon: "holiday", lang: "holidays" },*/
		/*{ sectionName: "patienttypes", icon: "patienttype", lang: "patienttypes" },*/
		{ sectionName: "taxes", icon: "tax", lang: "financial" },
		{ sectionName: "tests", icon: "documenttemplate", lang: "tests" },
		// { sectionName: "zones", icon: "list1", lang: "zones"},
    { sectionName: "user_payments", icon: "payment", lang: "user_payments" },
		{ sectionName: "info-tables", icon: "info", lang: "info_tables"},
		{ sectionName: "switches", icon: "sliders", lang: "preferences"},
    { sectionName: "security", icon: "security", lang: "security"},
    { sectionName: "self-coordination", icon: "calendar-check", lang: "self_coordination"},
    { sectionName: "automation", icon: "automation", lang: "automation" },
    { sectionName: "products", icon: "header", lang: "products" },
		/*{ sectionName: "payors", icon: "payor", lang: "payors" },*/
    /*{ sectionName: "ownerbankaccounts", icon: "bankaccount", lang: "bankaccounts" },*/
     /*{ sectionName: "accountant", icon: "accountant", lang: "accountant" },*/
   /* { sectionName: "hashav", icon: "hashav", lang: "hashav" },*/



  ];

  constructor(private apiService: ApiService, public lang: LangService, public validator: ValidatorService, private route: ActivatedRoute, public eventsService: EventsService, public permsService: PermsService, public router: Router,private store:StoreService) { }

  async ngOnInit() {
    this.showMenu = !this.eventsService.isMobile;	//reset to false if mobile

    this.route.queryParams.subscribe(params=>{
      if(params["showMenu"]){
        if(this.eventsService.isMobile){
          this.showMenu=true;
        }
      }
    })

    if(!this.innerSectionName){
      this.innerSectionName = this.permsService.owner_has_users ? "multiuser" : "user";
    }

    if (!this.permsService?.perms?.administrative) {
      this.router.navigateByUrl("/", { replaceUrl: true });
    }

    if (this.permsService.owner_has_users && !this.sideItems.find(it => it.sectionName == "multiuser")) {
      this.sideItems.unshift({ sectionName: "multiuser", icon: "users", lang: "multiuser" });
    }
    const cc=this.store.getCliniqDataOrExit();

    if (cc?.meeting_request_pay!=="yes" && this.sideItems.find(it => it.sectionName == "products")) {
      this.sideItems=this.sideItems.filter(it=>it.sectionName !== "products")
    }

    this.eventsService.isMobileSubj.subscribe(() => {	//when isMobile changes, make sure to reset again (mobile is false)
      this.showMenu = !this.eventsService.isMobile;
    })

    const res=await this.apiService.post("get_configcliniq_data");	//get config object from server
    this.configcliniq = res;

    Object.keys(cc).forEach(key=>{
      if(!(key in res)){
        console.log(key,"not in get_configcliniq_data");

      }
    })
    //if(cc?)

    Object.keys(res).forEach(key=>{
      if(!(key in cc)){
        console.log(key,"not in cc");

      }
    })


  }

  sideMenuClick() {	//on clicking any side menu item, if mobile - closes the menu
    window.scroll(0,0);
    if (this.eventsService.isMobile) {
      this.showMenu = false;
    }

  }



}


