@if(permsService?.perms?.administrative){
  <div class="calendar-wrap time-allocation">

    <div  class="calendar-above-row cal-vers"  >
      <div class="flex-center" style="flex-grow: 1;" >
        <h2 class="cal-title">{{"time_allocation" | langVal}}</h2>
      </div>


      @if(permsService?.owner_has_users){
        @if(permsService?.perms?.all_patients_all_fus){
          <div style="width:200px;">
            <app-autocomplete-with-tags (chosenEvent)="chooseTags($event)" title_ph_lang="filter_sub_users" hasPlus />
          </div>
          <div class="flex-v-center" style="margin-bottom: 1px;">
            <button class="cal-btn" (click)="subUserMove(-1)">&lt;</button>
            <app-gen-autocomplete #subUsersFuzzy title_ph_lang="sub_user" [fuzzySearchableClasses]="['sub_users']"
              [approvedIds]="subUserIds" (chosenEvent)="subUsersClick($event)" />
            <button class="cal-btn" (click)="subUserMove(1)">&gt;</button>
          </div>
        }
        <div class="flex">
          <button class="cal-btn" routerLink="/calendar/">{{"calendar" | langVal}}</button>

          @if(currentView=='listWeek'){
            <button class="cal-btn btn-primary" (click)="changeView('timeGridWeek')">{{"calendar_view" | langVal}}</button>
          }
          @else{
            <button class="cal-btn btn-primary" (click)="changeView('listWeek')">{{"list_view" | langVal}}</button>
          }
        </div>
      }
      @else{
        <div>
          <button class="cal-btn" titleLang="meetingtypes" (click)="openMeetingtypes()">{{"meetingtypes" | langVal}}</button>
        </div>
        <div>
          <button class="cal-btn" titleLang="time_allocations" (click)="openTimeAllocationSolo()">{{"time_allocations" | langVal}}</button>
        </div>
      }


    </div>
    <full-calendar #calendar deepChangeDetection="true" [options]="calendarOptions"></full-calendar>
  </div>
}
