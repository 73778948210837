import { Pipe, PipeTransform } from '@angular/core';
import { daysOfWeek } from '@app/definitions/types';
import * as moment from 'moment';
import { LangService } from '@app/services/lang.service';
import { StoreService } from '@app/services/store.service';

@Pipe({
	name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
	constructor(private store: StoreService,private lang:LangService) { }

	transform(date: any,isDayOfWeek:boolean=false) {
		if (!date) { return "" }
    if(isDayOfWeek){
      return this.lang.getVal(daysOfWeek[moment.utc(date).weekday()]);
    }
		return this.store.getMomentDisplay(date);

	}

}
