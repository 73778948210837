<div class="upper-row">
  <div>
    <a title="My-cliniq">
      <img [src]="environment.serverUrl+'/images/newdesign/main-logo.png'" alt="My-cliniq" class="LOGO"></a>
  </div>
</div>
<div class="flex" style="justify-content: right;padding-top:50px;padding-right:50px;"></div>

@if (dataObj) {
  <div class="guest-sched-container">
    <div class="user-details scheduler">
      <app-guest-user-details [dataObj]="dataObj" [saveObj]="saveObj" [fdsewrfsdfds]="fdsewrfsdfds"
                              [hgfe5ty6hjtgft454tr]="hgfe5ty6hjtgft454tr"/>
    </div>

    @if (!eventsService.isMobile) {
      <div>
        <div class="scheduler">
          <div style="padding-inline-start: 20px;">
            {{ (dataObj?.meeting_request_with_calendar ? "to_meeting_request" : "to_request_meeting_request") | langVal }}
            <!--      {{dataObj.name}}-->
          </div>
          <!--        <div style="padding-top:20px;">-->
          <div [hidden]="!meeting_request_with_calendar">
            <app-guest-hours-sched (chosenHourOut)="backFromHours($event)" [dataObj]="dataObj"/>
          </div>
        </div>
        <div class="scheduler">
          <div style="margin-top:30px">
            <div [hidden]="!meeting_request_with_calendar || hideNoCalendarTitle" class="scheduler-content"
                 style="height:40px;">
              <h4
                style="font-size: 28px; font-weight: 700;  color: var(--green);">{{ "to_meeting-request_no_calendar" | langVal }}</h4>
            </div>
            <app-guest-add-meeting-form [saveObj]="saveObj" [dataObj]="dataObj"
                                        (backEv)="meeting_request_with_calendar=true"
                                        (saveOut)="hideNoCalendarTitle=true"/>
          </div>
        </div>
      </div>

    }
  </div>

}
