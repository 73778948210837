import { Component, Input, ViewChild, booleanAttribute } from '@angular/core';
import { ModalService } from './services/modal.service';
import { EventsService } from '@app/services/events.service';
import { ValidatorService } from '@app/services/validator.service';
import { LangService } from '@app/services/lang.service';
import { ApiService } from '@app/services/api.service';
import { StoreService } from '@app/services/store.service';
import { ActivatedRoute, Router } from '@angular/router';

import { PermsService } from '@app/services/perms.service';
import { OverlapService } from '@app/services/overlap.service';
import { SearchSortService } from './services/search-sort.service';


@Component({ template: '' })
export class Modalable {
  @ViewChild("header") header;
  @ViewChild("footer") footer;
  modalWidth: string = "modal-default-width";
  @Input({transform:booleanAttribute}) isModalMode: boolean = false;
  hasExit: boolean = false;
  modalTitleLang: string = "";

  constructor(public lang: LangService, public apiService: ApiService, public validator: ValidatorService, public store: StoreService, public modalService: ModalService, public route: ActivatedRoute, public permsService: PermsService, public eventsService: EventsService, protected router: Router, public overlapService: OverlapService, protected searchSortService:SearchSortService) { }

  close(obj: any = null) { }
}
